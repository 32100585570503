import React from 'react';

const CompTempStudList = ({ persondata }) => {
  return (
    <>
      
      {persondata?.[0] && (
        <>
          {persondata[0].vorname} {persondata[0].nachname}
        </>
      )}
      {persondata?.slice(1, -1).map((person, index) => (
        <span key={index}>
          , {person.vorname} {person.nachname}
        </span>
      ))}
      {persondata?.length > 1 && (
        <span> und {persondata[persondata.length - 1].vorname} {persondata[persondata.length - 1].nachname}</span>
      )}
    </>
  );
};

export default CompTempStudList;
