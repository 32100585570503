export const tags = [
    "Typografie",
    "Visuelle Identität",
    "Corporate Design",
    "Buchillustration",
    "Illustration",
    "Zeichnung",
    "Malerei",
    "Visuelle Kunst",
    "Mediengestaltung",
    "Kunst und Design"
].map(tag => ({ value: tag, label: tag }));
