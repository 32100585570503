import { stroke } from "pdf-lib";

const Icon_folder = ({width, height,paddingBottom,marginBottom, marginLeft,marginRight}) => {
  
    return (
     <div style={{float:'left', marginBottom:`${marginBottom}`, paddingBottom:`${paddingBottom}`}}>
      <svg xmlns="http://www.w3.org/2000/svg" 
      width={width}
      height={height}
      className="Icon_svg"

      
      
      viewBox="0 -960 960 960">
   <path fill="currentColor" 
         stroke="red" 
         stroke-width="2"
         d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h240l80 80h320q33 0 56.5 23.5T880-640v400q0 33-23.5 56.5T800-160H160Zm0-80h640v-400H447l-80-80H160v480Zm0 0v-480 480Z"
     ></path>
</svg>
</div>)
  };
  
  export default Icon_folder;
  
  
  
  