import React from "react";
import { FastField } from "formik";
import { FormGroup, FormLabel, Row, Col } from "react-bootstrap";
import FormCompCheckbox from "./../formik/form-checkbox";
import { useUrlValidation } from '../../functions/formik/use-url-val'; // adjust the path as necessary

const FormTextAllow = ({ name, labelText,icon: Icon, onChange, textTrue, textFalse, urlval }) => {
  const validateUrl = useUrlValidation(urlval);

  return (
    <FastField name={name} validate={validateUrl}>
      {({ field, meta, form }) => {
        const additionalClass = meta.touched && field.value ? "form-text-selected" : "";
        const additionalClass_label = meta.touched && field.value ? "formgroup-selected" : "";

        return (
          <FormGroup className={`formgroup ${additionalClass_label}`}>
            <Row>
              <div><FormLabel htmlFor={name}>
              {Icon && <Icon style={{ marginRight: "5px" }} />} {/* Render icon if provided */}
              {labelText}</FormLabel></div>
              <Col lg="6" md="12" sm="12">
                <input
                  type="text"
                  {...field}
                  className={`form-text ${additionalClass}`}
                  value={field.value}  // This is controlled by Formik
                  onChange={(e) => {
                    form.setFieldValue(name, e.target.value);  // Update Formik's state
                    if (onChange) onChange(e);  // Custom handler if provided
                  }}
                />

                {meta.touched && meta.error ? (
                  <div className="url_val">
                    <div className="error">{meta.error}</div>
                  </div>
                ) : null}
              </Col>

              <Col lg="6" md="12" sm="12">
                <FormCompCheckbox
                  textTrue={textTrue}
                  textFalse={textFalse}
                  name={`${name}_allowance`}
                  labelText="Erlaube Veröffentlichung"
                />
              </Col>
            </Row>
          </FormGroup>
        );
      }}
    </FastField>
  );
};

export default FormTextAllow;
