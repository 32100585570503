// src/functions/formik/processfiles/processVarFiles.js

export async function processVarFiles(fileKeys, jsonContent, content, setFieldValue, varfileDispatch) {
    for (const key of fileKeys) {
      console.log(`Processing Var File: ${key}`);
  
      if (!key.match(/\.(pdf|jpg|jpeg|png|svg|json)$/)) {
        for (const field in jsonContent) {
          if (jsonContent[field] === key) {
            console.log(`Matching JSON field: ${field}`);
  
            try {
              const blob = await content.files[key].async("blob");
              const fileUrl = URL.createObjectURL(blob);
              const formikField = field.replace("_filename", "");
  
              const fileType = key.split(".").pop() || "octet-stream";
              const varFile = new File([blob], jsonContent[field], {
                type: blob.type || `application/${fileType}`,
              });
  
              const completeFileName = key;
              setFieldValue(`${formikField}_filename`, completeFileName);
              console.log(`Set Formik field: ${formikField}_filename to ${completeFileName}`);
  
              varfileDispatch({
                type: "SET_VAR_FILE_URL",
                fieldName: formikField,
                varUrl: fileUrl,
              });
              varfileDispatch({
                type: "SET_VAR_FILE_NAME",
                fieldName: formikField,
                fileName: completeFileName,
              });
              console.log("Dispatched SET_VAR_FILE_URL and SET_VAR_FILE_NAME actions:", {
                fieldName: formikField,
                varUrl: fileUrl,
                fileName: completeFileName,
              });
  
              setFieldValue(formikField, varFile);
              setFieldValue(`${formikField}_url`, fileUrl);
              console.log(`Set Formik field: ${formikField} to file and URL: ${fileUrl}`);
            } catch (error) {
              console.error(`Error processing file ${key}:`, error);
            }
          }
        }
      }
    }
  }
  