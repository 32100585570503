
  //Projektart
  export const projektart = [
    { value: 'FW', label: 'Abschlussarbeit', valuefull: 'Abschlussarbeit' },
    { value: 'SW', label: 'Semesterarbeit', valuefull: 'Semesterarbeit' },
    { value: 'WW', label: 'Workshop', valuefull: 'Workshop' },
    { value: 'EW', label: 'Externe Arbeit', valuefull: 'Externe Arbeit' },
];


//   //Erasmus
  export const difficulty = [
    { value: '1', label: 'Schwierigkeitsgrad: einfach' },
    { value: '2', label: 'Schwierigkeitsgrad: mittel' },
    { value: '3', label: 'Schwierigkeitsgrad: schwer' },
  ];


//   //Module
  export const moduldauer = [
    { value: 'moddurat.one', label: '1 Semester' },
    { value: 'moddurat.two', label: '2 Semester' },
    { value: 'moddurat.var', label: 'eine Woche' },
    { value: 'moddurat.one-week', label: 'variabel' },
  ];


  