export const eng_function = [
  { value: "Professor", label: "ProfessorIn" },
  { value: "Employee", label: "MitarbeiterIn" },
  { value: "Lecturer", label: "Lehrbeauftragte" },
  { value: "Student Assistant", label: "Studentische Hilfskraft" },
];

export const gebauede = [
  { value: "S", label: "S" },
  { value: "Q", label: "Q" },
  { value: "R", label: "R" },
  { value: "D", label: "D" },
];

export const titel = [
  { value: "Prof.", label: "Prof." },
  { value: "Prof. Dr. ", label: "Prof. Dr." },
  { value: "Prof. Dr. habil.", label: "Prof. Dr. habil." },
];

export const standort = [
  { value: "Irminenfreihof", label: "Irminenfreihof" },
  { value: "Paulusplatz", label: "Paulusplatz" },
  { value: "Idar-Oberstein", label: "Idar-Oberstein" },
  { value: "Schneidershof", label: "Schneidershof" },
  { value: "Umweltcampus Birkenfeld", label: "Umweltcampus Birkenfeld" },
];

export const selecStgType = [
  { value: "bachelor", label: "BA", customValue: "b" },
  { value: "master", label: "MA", customValue: "m" },
];

export const selecStg = [
  { value: "AR", label: "AR", customValue: "AR", valuefull: 'Architektur' },
  { value: "MD", label: "MD", customValue: "MD", valuefull: 'Modedesign' },
  { value: "KD", label: "KD", customValue: "KD", valuefull: 'Kommunikationsdesign' },
  { value: "IA", label: "IA", customValue: "IA", valuefull: 'Innenarchitektur' },
  { value: "ID", label: "ID", customValue: "ID", valuefull: 'Intermedia Design' },
  { value: "ES", label: "ES", customValue: "ES", valuefull: 'Edelstein Schmuck' },
  { value: "TD", label: "TD", customValue: "TD", valuefull: 'Transdisziplinäre Lehre' },
];

export const selecStgSem = [
  { value: "1", label: "1 Sem" },
  { value: "2", label: "2 Sem" },
  { value: "3", label: "3 Sem" },
  { value: "4", label: "4 Sem" },
  { value: "6", label: "6 Sem" },
  { value: "7", label: "7 Sem" },
  { value: "8", label: "8 Sem" },
];

export const selecSemType = [
  { value: "Wintersemester", label: "Wintersemester", customValue: "WiSe" },
  { value: "Sommersemester", label: "Sommersemester", customValue: "SoSe" }
];

// helpers.js
// Retrieve customValue of SelecSeType
export const getCustomValueofOptionList = (value, options) => {
  const selectedOption = options.find(option => option.value === value);
  return selectedOption ? selectedOption.customValue : '';
};
