import React, { useEffect, useState } from 'react';
import { Button, FormGroup, FormLabel, Row, Col } from "react-bootstrap";
import { useFileHandler } from '../../functions/formik/useFileHandler';
import { useFormikContext } from 'formik';
import useUpdateFilenames from '../../functions/hooks/react-useupdateFilenames';

const FormFileSelectVAR = ({ amount_input_field, countarrayState, label, name, dispatch, varfileState, onChange, imgnaming, cropmark, desiredWidth, desiredHeight }) => {
  const { handleFileChange, fileInfo, errorMessage } = useFileHandler(name, cropmark, desiredWidth, desiredHeight, dispatch);
  const [inputCount, setInputCount] = useState(amount_input_field);
  const { values, setFieldValue } = useFormikContext();
  const [fileExtension, setFileExtension] = useState('');

  const addInputField = () => {
    setInputCount(currentCount => currentCount + 1);
  };

  const removeInputField = () => {
    setInputCount(currentCount => Math.max(currentCount - 1, 1));
  };

  useEffect(() => {
    // Set input count from countarrayState if available
    const key = `${name}_inputCount`;
    const newCount = countarrayState?.[key];
    if (newCount !== undefined) {
      setFieldValue(`${name}_inputCount`, newCount);
      setInputCount(newCount);
    }
  }, [countarrayState, name, setFieldValue]);

  // Updated useUpdateFilenames hook usage to avoid issues with MIME type interference
  useUpdateFilenames({ name, inputCount: 1, state: varfileState, setFieldValue, imgnaming, fileExtension });

  const handleFileInputChangeFile = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      console.log("Selected file:", file.name);
      console.log("File type:", file.type);

      // Extract the last segment after the last dot as the file extension
      const fileExt = file.name.split('.').pop();
      console.log("Extracted file extension:", fileExt);

      // Construct file URL and dispatch the URL to the reducer
      const fileUrl = URL.createObjectURL(file);
      dispatch({ type: 'SET_VAR_FILE_URL', fieldName: name, varUrl: fileUrl });
      console.log("Dispatched SET_VAR_FILE_URL with URL:", fileUrl);

      // Update the file extension in state
      setFileExtension(fileExt);

      handleFileChange(event);  // Process additional file handling
      
      // Generate the filename with the correct extension
      const newFilename = `${imgnaming}_${name}.${fileExt}`;
      setFieldValue(`${name}_filename`, newFilename);
      console.log("Constructed new filename:", newFilename);
      
      setFieldValue(name, file);  // Save the file to Formik values
      console.log("Stored file in Formik values:", file);
    }
  };

  return (
    <Row style={{ marginBottom: '20px' }}>
      <Col md='8'>
        <div style={{ minWidth: '100%', backgroundColor: '#ededed', padding: '10px', border: "1px solid grey", }}>
          <FormGroup className="formgroup">
            {label && <FormLabel htmlFor={name}>
              <div>
                <div className="btn_fileselect_inf btn_fileselect_info_first">{label}</div>
                <div className='btn_fileselect_inf' style={{ fontWeight: 'normal' }}>Dateiname: {fileInfo.originalFilename}</div>
              </div>
            </FormLabel>}
            <input
              id={`${name}`}
              name={`${name}`}
              type="file"
              accept="*/*"
              onChange={handleFileInputChangeFile}
              style={{ display: "none" }}
            />

            <Button as="label" htmlFor={`${name}`} className='btn_fileselect_select_pdf'>
              Datei auswählen
            </Button>

            <Row>
              <Col md="auto" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h4>Dateibenennung</h4>
              </Col>

              <Col>
                <input
                  type="text"
                  name={`${name}_filename`}
                  className={`form-text-info`}
                  value={varfileState[name]?.isFileSelected ? values[`${name}_filename`] : ''}
                  readOnly
                  style={{ userSelect: "none", cursor: "default" }}
                />
              </Col>
            </Row>
          </FormGroup>
        </div>
        {errorMessage && <div className="warn-no-crop">{errorMessage}</div>}

        {fileInfo.metadata?.pages && fileInfo.metadata.pages.length > 0 && (
          <>
            <Row style={{ marginTop: "20px" }}>
              <Col xs={1}>Seite:</Col>
              <Col>Größe:</Col>
              <Col>Annotationen:</Col>
            </Row>
            {fileInfo.metadata.pages.map((info, index) => (
              <div key={`${name}_${index}`} style={{ borderBottom: '1px solid grey' }}>
                <Row>
                  <Col xs={1}><p>{info.pageIndex}</p></Col>
                  <Col><p>{info.widthCm} cm x {info.heightCm} cm</p></Col>
                  <Col><p>{info.annotations.join(", ")}</p></Col>
                </Row>
              </div>
            ))}
          </>
        )}
      </Col>
      <Col>
        {varfileState[name]?.url ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <img src='./img/icons/icon_file.svg' style={{ height: '150px', width: '150px' }} alt="File Icon" />
          </div>
        ) : (
          <div className='form_file_pdf_noPDF'>Noch keine Datei ausgewählt</div>
        )}
      </Col>
      <div style={{ borderBottom: '1px solid black', marginBottom: "20px", marginTop: "20px" }}></div>
    </Row>
  );
};

export default FormFileSelectVAR;
