// // const.js
// export const initialValues = {
//     selecStg: '',
//     selecStgType: '',
//     selecStgSem: '',
//     selectedOption: '',
//     items: ['Lehrveranstaltungen'],
//     for_exchange: true,
//     notforexch: '',
//     gewichtung: 'Anhand der Fach-PO',
//     ger_voraussetzungen_ects: 'keine',
//     ger_haeufigkeit: 'jedes Semester',
//     yourTextareaField: '',
//     plainTextContent: '',
//     message: '',
//     testfield: '',
//     filename: '',
//   };

  

//   export const ger_inhalte = [
//     {
//       title: "Selbstständige Entwicklung einer Strategie bezüglich der Recherchen und Methodenwahl zum\nvorgegebenen Thema",
//       subgoals: [],
//     },
//     {
//       title: "Aneignung fachspezifischer Gestaltungsgrundlagen und Arbeitsweisen",
//       subgoals: [
//         {
//           title: "sdafsad;lkfjsadl;kfj",
//           subgoals: [],
//         },
//       ],
//     },
//   ];
  
//   export const typeOptions = [
//     { value: 'bachelor', label: 'Bachelor' },
//     { value: 'master', label: 'Master' },
//   ];

//   export const stgTypeMap = {
//     bachelor: { value1: 'b', value2: 'ba', value3: 'bachelor' },
//     master: { value1: 'm', value2: 'ma', value3: 'master' },
//   };

//   export const selecStg = [
//     { value: 'MD', label: 'Modedesign' },
//     { value: 'KD', label: 'Kommunikationsdesign' },
//     { value: 'IA', label: 'Innenarchitektur' },
//     { value: 'AR', label: 'Architektur' },
//     { value: 'ID', label: 'Intermedia Design' },
//     { value: 'ES', label: 'Edelstein Schmuck' },
//   ];

//   export const selecStgMap = {
//     MD: { value1: 'MD', value2: 'Modedesign'},
//     KD: { value1: 'KD', value2: 'Kommunikationsdesign'},
//     IA: { value1: 'IA', value2: 'Innenarchitektur'},
//     AR: { value1: 'AR', value2: 'Architektur'},
//     ID: { value1: 'ID', value2: 'Intermedia Design'},
//     ES: { value1: 'ES', value2: 'Edelstein Schmuck'},
 
//   };
  
//   export const semesterOptions = [2, 3, 6, 7, 8];



//   //Erasmus
  export const difficulty = [
    { value: '1', label: 'Schwierigkeitsgrad: einfach' },
    { value: '2', label: 'Schwierigkeitsgrad: mittel' },
    { value: '3', label: 'Schwierigkeitsgrad: schwer' },
  ];


//   //Module
  export const moduldauer = [
    { value: 'moddurat.one', label: '1 Semester' },
    { value: 'moddurat.two', label: '2 Semester' },
    { value: 'moddurat.var', label: 'eine Woche' },
    { value: 'moddurat.one-week', label: 'variabel' },
  ];



//   //Studentische Arbeiten / Projekte
  
  //Projektart
  export const projektart = [
    { value: 'BA-Arbeit', label: 'Abschlussarbeit Bachelor' },
    { value: 'MA-Arbeit', label: 'Abschlussarbeit Master' },
    { value: 'Sem-Arbeit', label: 'Semesterarbeit' },
    { value: 'Workshop-Arbeit', label: 'Workshop' },
    { value: 'Frei-Arbeit', label: 'Freie Arbeit' },
  ];
  
  

  