import React from 'react';
import { useField } from 'formik';
import { FormGroup, FormLabel } from 'react-bootstrap';

const FormSelect = ({
  name,
  labelText,
  options,
  onChange,
  showLabel = true,
  storeFullObject = true,
  showIcon = false, // Toggle icon display
  placeholderIcon = null, // Accepts React components or JSX
}) => {
  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  const handleChange = (e) => {
    const { value } = e.target;
    const selectedOption = options.find((option) => option.value === value);
    setValue(storeFullObject ? selectedOption : value);
    setTouched(true);

    if (onChange) {
      onChange(e);
    }
  };

  const isSelected = field.value && (typeof field.value === 'object' ? field.value.value : field.value);
  const additionalClass = isSelected ? 'form-selec-selected' : '';
  const additionalClass_label = isSelected ? 'formgroup-selected' : '';

  return (
    <FormGroup className={`formgroup ${additionalClass_label}`} style={{ margin: 0, padding: 0 }}>
      {showLabel && <FormLabel htmlFor={name}>{labelText}</FormLabel>}
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        {showIcon && placeholderIcon && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '8px', // Add spacing between the icon and select field
            }}
          >
            {placeholderIcon}
          </div>
        )}
        <select
          id={name}
          className={`form-selec ${additionalClass}`}
          onChange={handleChange}
          value={typeof field.value === 'object' ? field.value.value : field.value || ''}
          style={{
            height: '50px',
            width: showIcon ? 'calc(100% - 50px)' : '100%', // Reduce width if icon is active
          }}
        >
          <option value="">{labelText}</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </FormGroup>
  );
};

export default FormSelect;
