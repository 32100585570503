import React, { useEffect, useState } from "react";
import { Button, FormGroup, FormLabel, Row, Col } from "react-bootstrap";
import { useFormikContext } from "formik";
// import { useFileHandler } from "../../functions/formik/useFileHandler";
import { useFileHandler, extractImageMetadata } from "../../functions/formik/useFileHandler";

import FormCompButtonAddRemove from "../formik/form-button-add";
import useUpdateFilenames from '../../functions/hooks/react-useupdateFilenames';

const FormFileSelectIMG = ({
  amount_input_field,
  label,
  name,
  dispatch,
  imgState,
  enableAddButton,
  desiredWidth,
  desiredHeight,
  cropmark,
  unit,
  onChange,
  imgnaming,
  countarrayState,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [inputCount, setInputCount] = useState(amount_input_field);
  const { handleFileChange, fileInfo, errorMessage } = useFileHandler(
    name,
    cropmark,
    desiredWidth,
    desiredHeight,
    dispatch
  );

  useUpdateFilenames({ name, inputCount, state: imgState, setFieldValue, imgnaming });
  const [dimensionWarning, setDimensionWarning] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);

  // useEffect(() => {
  //   const key = `${name}_inputCount`;
  //   const newCount = countarrayState?.[key];

  //   if (newCount !== undefined) {
  //     setFieldValue(`${name}_inputCount`, newCount);
  //     setInputCount(newCount);
  //   }
  // }, [countarrayState, name, setFieldValue]);


  useEffect(() => {
    const key = `${name}_inputCount`;
    const newCount = countarrayState?.[key];
  
    if (newCount !== undefined) {
      setFieldValue(`${name}_inputCount`, newCount);
      setInputCount(newCount); // Update the state for rendering input fields
    }
  }, [countarrayState, name, setFieldValue]);

  
  const addInputField = () => {
    setInputCount((currentCount) => currentCount + 1);
  };

  const removeInputField = () => {
    setInputCount((currentCount) => currentCount - 1);
  };


  const inputFields = () => {
    let fields = [];
    for (let i = 1; i <= inputCount; i++) {
      const currentName = `${name}_${i}`;
      const imgUrl = imgState[currentName]?.url || "";
      const imgMetadata = imgState[currentName]?.metadata || {};
      const imageResolution = imgMetadata.width && imgMetadata.height
        ? `${imgMetadata.width}${unit} x ${imgMetadata.height}${unit}`
        : "Resolution not available";
      const originalFilename = imgState[currentName]?.filename || "Noch kein Dateiname";

      // Check if the image meets the minimum size requirements
      let feedbackMessage = null;
      if (imgMetadata.width >= desiredWidth && imgMetadata.height >= desiredHeight) {
        feedbackMessage = `Good job! The uploaded image meets the minimum required dimensions of ${desiredWidth}${unit} x ${desiredHeight}${unit}.`;
      } else {
        feedbackMessage = `Warning: The image does not meet the minimum size requirements of ${desiredWidth}${unit} x ${desiredHeight}${unit}. Current size is ${imgMetadata.width}${unit} x ${imgMetadata.height}${unit}.`;
      }

      fields.push(
        <>        <Row key={currentName} style={{ marginBottom: "20px" }}>
          <Col md="8">
            <div
              style={{
                minWidth: "100%",
                backgroundColor: "rgb(247, 247, 247)",
                padding: "10px",
                border: "1px solid grey",
              }}
            >
              <FormGroup className="formgroup">
                {label && (
                  <FormLabel htmlFor={currentName}>
                    <div className="btn_fileselect_inf btn_fileselect_info_first">{`${label} ${i}`}</div>
                    <div className="btn_fileselect_inf">{`Vorgegebenes Format: ${desiredWidth}${unit} x ${desiredHeight}${unit}`}</div>
                    {/* <div className="btn_fileselect_inf">{`Dateiname: ${fileInfo.originalFilename}`}</div> */}
                  </FormLabel>
                )}

                <input
                  id={currentName}
                  name={currentName}
                  type="file"
                  accept="image/jpeg, image/png" // Allow only JPEG and PNG
                  onChange={(event) => handleFileInputChange(event, currentName)}
                  style={{ display: "none" }}
                />

                <Button as="label" htmlFor={currentName} className="img-button">
                  Import
                </Button>
              </FormGroup>

              <input
                type="text"
                label="Filename"
                name={`${currentName}_filename`}
                value={
                  imgState[currentName]?.isFileSelected
                    ? values[`${currentName}_filename`]
                    : "" // Show empty if no file is selected
                }
                readOnly
                className="form-text-info flex-grow-1"
                style={{ userSelect: "none", cursor: "default", height: '100%' }}
              />

              <Row className="d-flex align-items-stretch" style={{ height: '', minHeight: '8', paddingLeft: '15px' }}>
                <Col md="auto" className="d-flex align-items-center" style={{ fontSize: '1rem', backgroundColor: '#ccc', padding: '0.8rem' }}>
                  Alter Dateiname:
                </Col>
                <Col className="d-flex form-text-info flex-grow-1" style={{ height: '100%' }}>
                  {originalFilename}
                </Col>
              </Row>
            </div>
            <Row>
              <Col>
                <div
                  className="form-file-img-feedback"
                  style={{
                    marginTop: '10px',
                    minWidth: "100%",
                    backgroundColor: "#FAFAFA",
                    padding: "10px",
                    border: "1px solid lightgrey",
                  }}
                >
                  <div>Auflösung: {imageResolution}</div>
                  {imgMetadata.dpiX && imgMetadata.dpiY && (
                    <div>DPI: {imgMetadata.dpiX} x {imgMetadata.dpiY}</div>
                  )}
                  {imgState[currentName]?.feedbackMessage && isFileSelected && (
                    <div className={
                      imgMetadata.width >= desiredWidth &&
                        imgMetadata.height >= desiredHeight ?
                        "positive-feedback" : "warning"
                    }>
                      {imgState[currentName].feedbackMessage}
                    </div>
                  )}

                  {imgState[currentName]?.errorMessage && isFileSelected && (
                    <div className="error">{imgState[currentName].errorMessage}</div>
                  )}
                </div>
              </Col>
            </Row>

          </Col>
          <Col>
            {imgUrl ? (
              <img
                src={imgUrl}
                alt={`Preview ${i}`}
                style={{ width: "100%", height: "auto", border: '1px solid grey' }}
              />
            ) : (
              <div className="form-file-img-prev">
                Noch kein Bild ausgewählt
              </div>
            )}
          </Col>
        </Row>

        </>

      );
    }
    return fields;
  };

  const handleFileInputChange = async (event, currentName) => {
    const file = event.currentTarget.files[0];
    if (file) {
      // Set the file selection flag and handle the file change
      handleFileChange(event); // Only call handleFileChange once to avoid duplication

      // Capture the file extension from the selected file
      const fileExtension = file.name.split(".").pop();
      const newImgUrl = URL.createObjectURL(file);

      // Dispatch URL and selection status immediately
      dispatch({
        type: "SET_IMG_URL",
        fieldName: currentName,
        imgUrl: newImgUrl,
      });

      dispatch({
        type: "SET_IMG_FILENAME",
        fieldName: currentName,
        filename: file.name, // Ensure the full filename with extension is set
        isFileSelected: true // Set file selected flag
      });

      // Wait for metadata extraction
      const metadata = await extractImageMetadata(file);

      // Dispatch metadata
      dispatch({
        type: "SET_IMG_METADATA",
        fieldName: currentName,
        metadata,
        feedbackMessage: metadata.width >= desiredWidth && metadata.height >= desiredHeight
          ? `Good job! The uploaded image meets the minimum required dimensions of ${desiredWidth}${unit} x ${desiredHeight}${unit}.`
          : `The image does not meet the minimum size requirements of ${desiredWidth}${unit} x ${desiredHeight}${unit}. Current size is ${metadata.width}${unit} x ${metadata.height}${unit}.`
      });

      // Update Formik fields with new filename, avoiding duplicate extensions
      const existingName = values[`${currentName}_filename`] || "";
      const newFilename = existingName.endsWith(fileExtension)
        ? `${imgnaming}_${currentName}`
        : `${imgnaming}_${currentName}.${fileExtension}`;

      setFieldValue(currentName, file);
      setFieldValue(`${currentName}_filename`, newFilename);
      setFieldValue(`${name}_inputCount`, inputCount);

      // Call the onChange callback if provided
      if (onChange) {
        onChange({ filename: newFilename });
      }
    }
  };


  return (
    <>
      <div>
        {inputFields()}
        {enableAddButton && ( // Only show add/remove buttons if enableAddButton is true
          <FormCompButtonAddRemove
            onAdd={addInputField}
            onRemove={removeInputField}
            addStyle={{ marginRight: "10px" }}
            removeStyle={{ marginLeft: "5px" }}
          />
        )}
      </div>
      <input
        type="text"
        name={`${name}_inputCount`}
        value={`${inputCount}`}
        readOnly
        style={{ display: "none" }}
      />
    </>
  );
};

export default FormFileSelectIMG;
