import React from "react";

const BtnHelp = ({ children, url, IconComponent }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    
    style={{
      textDecoration: 'none',
      cursor: "pointer",
      fontSize: "1.2rem",
    }}
  >
    <>
    <div className="btn-help">
    {IconComponent && <IconComponent style={{ marginRight: "10px", marginLeft: '10px', height: "20px", width: "20px" }} />}
    <span>{children}</span>
    </div>
    </>
  </a>
);

export default BtnHelp;
