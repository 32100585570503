import React, { useState, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Extend Quill Clipboard to paste plain text only
const Clipboard = Quill.import('modules/clipboard');

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const plainText = (e.clipboardData || window.clipboardData).getData('text/plain');
    const range = this.quill.getSelection();
    this.quill.insertText(range.index, plainText);
    this.quill.setSelection(range.index + plainText.length);
  }
}

Quill.register('modules/clipboard', PlainClipboard, true);

const QuillEditorStaff = ({ value, onChange, fieldname, maxChars }) => {
  const [editorHtml, setEditorHtml] = useState(value);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setEditorHtml(value);
    setCharCount(value ? value.replace(/<(.|\n)*?>/g, '').length : 0); // Update initial character count
  }, [value]);

  const handleChange = (content, delta, source, editor) => {
    // Filter content for visible characters only (ignoring breaks)
    const visibleText = content.replace(/<(.|\n)*?>/g, '').trim(); 
    const visibleCharCount = visibleText.length;

    if (visibleCharCount <= maxChars || (delta.ops.some(op => op.insert === '\n' && visibleCharCount <= maxChars))) {
      setEditorHtml(content);
      setCharCount(visibleCharCount);
      onChange(content);
    } else {
      const truncatedContent = content.substring(0, maxChars);
      setEditorHtml(truncatedContent);
      setCharCount(maxChars);
      onChange(truncatedContent);
      console.log("Character limit reached");
    }
  };

  const charCountClass = charCount > maxChars ? 'quillcount limit-reached' : 'quillcount';

  return (
    <div>
      <ReactQuill
        className="quillEditor"
        value={editorHtml}
        onChange={handleChange}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
        formats={['bold', 'italic', 'underline', 'strike', 'link']}
        placeholder="Start typing here..."
      />
      <div className={charCountClass}>
        Anzahl Zeichen: {charCount} / {maxChars}
      </div>
    </div>
  );
};

export default QuillEditorStaff;
