import React, { useState, useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Extend the Quill Clipboard to paste only plain text
const Clipboard = Quill.import('modules/clipboard');

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();

    // Get plain text from the clipboard (without formatting)
    const plainText = (e.clipboardData || window.clipboardData).getData('text/plain');

    // Get current selection range in the editor
    const range = this.quill.getSelection();

    // Insert the plain text at the current selection
    this.quill.insertText(range.index, plainText);

    // Move cursor after the pasted content
    this.quill.setSelection(range.index + plainText.length);
  }
}

// Register the custom clipboard
Quill.register('modules/clipboard', PlainClipboard, true);

const QuillEditor = ({ value, onChange }) => {
  const [editorHtml, setEditorHtml] = useState(value || '');
  const quillRef = useRef(null);

  useEffect(() => {
    setEditorHtml(value || '');
  }, [value]);

  useEffect(() => {
    const editor = quillRef.current.getEditor();

    // Listen for changes in the Quill editor directly
    editor.on('text-change', () => {
      const html = editor.root.innerHTML; // Get the HTML content of the editor
      const cleanedHtml = cleanHtml(html);

      // Log the actual HTML for debugging purposes
      console.log('Editor HTML:', html);

      // Check if the editor content is empty or only has <p><br></p>
      if (cleanedHtml.length === 0 || html === '<p><br></p>') {
        setEditorHtml(''); // Reset editor to empty state
        console.log('Empty content detected via text-change');
        onChange(''); // Notify parent that the content is empty
      } else {
        setEditorHtml(html); // Keep the current content
        onChange(html); // Pass the HTML content to the parent
      }
    });
  }, [onChange]);

  // Function to clean up the HTML content and remove empty paragraphs like <p><br></p>
  const cleanHtml = (html) => {
    // Remove <p><br></p> or other empty content structures
    return html.replace(/<p><br><\/p>/g, '').trim();
  };

  return (
    <div>
      <ReactQuill
        ref={quillRef} // Use ref to access Quill instance
        className="quillEditor"
        value={editorHtml}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
          ],
          clipboard: {
            matchVisual: false,  // Don't add formatting when pasting
          },
        }}
      />
    </div>
  );
};

export default QuillEditor;
