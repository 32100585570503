import React from 'react';

const CompTempListLehr = ({ assignedLehrende }) => {
  return (
    <>
      {assignedLehrende?.[0] && (
        <>
          {assignedLehrende[0].lehrende}
        </>
      )}
      
      {assignedLehrende?.slice(1, -1).map((lehr, index) => (
        <span key={index}>
          , {lehr.lehrende}
        </span>
      ))}
      
      {assignedLehrende?.length > 1 && (
        <span> und {assignedLehrende[assignedLehrende.length - 1].lehrende}</span>
      )}
    </>
  );
};


export default CompTempListLehr;
