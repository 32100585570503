// Refactored function with precise alignment to the original
export async function processImageFiles(fileKeys, jsonContent, content, setFieldValue, imgDispatch) {
  for (const key of fileKeys) {
    if (key.match(/\.(jpg|jpeg|png|svg)$/)) {
      console.log(`Processing Image File: ${key}`);
      
      for (const field in jsonContent) {
        if (jsonContent[field] === key) {
          console.log(`Matching JSON field: ${field}`);
          
          // Load the image file as a blob
          const blob = await content.files[key].async("blob");
          const fileType = key.split(".").pop();
          
          // Create image URL from blob
          const imgUrl = URL.createObjectURL(blob);

          // Define Formik field and create a File object for Formik update
          const formikField = field.replace("_filename", "");
          const imgFile = new File([blob], jsonContent[field], { type: `image/${fileType}` });
          
          // Update Formik fields (original assignments)
          setFieldValue(formikField, imgFile);
          setFieldValue(`${formikField}_url`, imgUrl);

          // Dispatch image URL to imgReducer for component state update
          imgDispatch({
            type: "SET_IMG_URL",
            fieldName: formikField,
            imgUrl,
          });

          console.log(`Set Formik field ${formikField} with image URL: ${imgUrl}`);
        }
      }
    }
  }
}
