// PDFAccordion.js
import React from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import FormFileSelectPDF from '../form-file-pdf';

const AccDateienPDF = ({ imgnaming, pdfDispatch, pdfState }) => {
  return (
    <Accordion flush>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          <h2>PDF Dateien</h2>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col>
              <FormFileSelectPDF
                amount_input_field={1}
                label="PDF Plakat"
                name="pdf_plakat"
                imgnaming={imgnaming}
                desiredWidth={70}
                desiredHeight={100}
                unit="cm"
                dispatch={pdfDispatch}
                pdfState={pdfState}
                cropmark={true}
                enableAddButton={false}
              />

              <FormFileSelectPDF
                amount_input_field={1}
                label="Präsentation"
                name="pdf_presentation"
                imgnaming={imgnaming}
                desiredWidth={1920}
                desiredHeight={1080}
                unit="px"
                dispatch={pdfDispatch}
                pdfState={pdfState}
                cropmark={false}
                enableAddButton={false}
              />

              <FormFileSelectPDF
                amount_input_field={1}
                label="Weiteres PDF"
                name="pdf_additional"
                imgnaming={imgnaming}
                desiredWidth={1920}
                desiredHeight={1080}
                unit="px"
                dispatch={pdfDispatch}
                pdfState={pdfState}
                cropmark={false}
                enableAddButton={true}
              />
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccDateienPDF;
