// pdfReducer.js

// pdfReducer.js

export const pdfReducer = (state, action) => {
  console.log('Action received in pdfReducer:', action);
  switch (action.type) {
    case 'SET_PDF_URL':
      if (!action.pdfUrl) {
        console.error('PDF URL is undefined or null:', action);
        return state; // Return the current state if the URL is undefined to avoid wiping existing data
      }
      return {
        ...state,
        [action.fieldName]: {
          url: action.pdfUrl,
          isFileSelected: true // Track that a file has been selected
        }
      };

    default:
      console.log('Unhandled action type in pdfReducer:', action.type);
      return state;
  }
};
  

// imgReducer.js
export const imgReducer = (state, action) => {
  console.log('Action received in imgReducer:', action);
  switch (action.type) {
    case 'SET_IMG_URL':
      if (!action.imgUrl) {
        console.error('Image URL is undefined or null:', action);
        return state;
      }
      return {
        ...state,
        [action.fieldName]: { 
          ...state[action.fieldName], 
          url: action.imgUrl, 
          isFileSelected: true  // Mark as selected
        }
      };

    case 'SET_IMG_METADATA':
      if (!action.metadata) {
        console.error('Image metadata is undefined or null:', action);
        return state;
      }
      return {
        ...state,
        [action.fieldName]: { 
          ...state[action.fieldName], 
          metadata: action.metadata,
          feedbackMessage: action.feedbackMessage,
          errorMessage: action.errorMessage
        }
      };
    
    case 'SET_IMG_FILENAME':
      if (!action.filename) {
        console.error('Image filename is undefined or null:', action);
        return state;
      }
      return {
        ...state,
        [action.fieldName]: { 
          ...state[action.fieldName], 
          filename: action.filename
        }
      };

    default:
      console.log('Unhandled action type in imgReducer:', action.type);
      return state;
  }
};


// varfileReducer.js
export const varfileReducer = (state, action) => {
  console.log('Action received in varfileReducer:', action);
  switch (action.type) {
    case 'SET_VAR_FILE_URL':
      if (!action.varUrl) {
        console.error('Var File URL is undefined or null:', action);
        return state; // Return current state if URL is undefined
      }
      const newVarFileState = {
        ...state,
        [action.fieldName]: {
          url: action.varUrl,
          isFileSelected: true // Track that a file has been selected
        }
      };
      console.log('New state after SET_VAR_FILE_URL:', newVarFileState);
      return newVarFileState;

    case 'SET_VAR_FILE_NAME':
      if (!action.fileName) {
        console.error('Var File Name is undefined or null:', action);
        return state;
      }
      // Log before setting to verify no MIME type is appended
      console.log("Setting filename in state (without MIME type):", action.fileName);
      const newFileNameState = {
        ...state,
        [`${action.fieldName}_filename`]: action.fileName
      };
      console.log('New state after SET_VAR_FILE_NAME:', newFileNameState);
      return newFileNameState;

    default:
      console.log('Unhandled action type in varfileReducer:', action.type);
      return state;
  }
};







// countReducer.js
export const countarrayReducer = (state, action) => {
  console.log('Action received in countReducer:', action);
  switch (action.type) {
    case 'SET_MAX_INPUT_COUNT':
      if (typeof action.count !== 'number') {
        console.error('Invalid count provided:', action);
        return state; // Return current state if the count is invalid
      }
      const fieldName = action.fieldName;
      const currentCount = state[fieldName] || 0;
      const newCountState = {
        ...state,
        [fieldName]: Math.max(currentCount, action.count)
      };
      console.log('New state after max input count update:', newCountState);
      return newCountState;

    default:
      console.log('Unhandled action type in countReducer:', action.type);
      return state;
  }
};

  