import React from 'react';

const CatTitle = ({ IconComponent, titleText }) => (
  <div className="cat-title" style={{marginTop:'50px'}}>
    <h1>
      {IconComponent && <IconComponent width="55px" />}
      {titleText}
    </h1>
  </div>
);

export default CatTitle;
