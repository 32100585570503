// AccProjektDetails.js
import React from 'react';
import { Accordion, Row, Col, FormGroup, FormLabel } from 'react-bootstrap';
import classNames from 'classnames';

import FormCompCheckbox from "../form-checkbox";
import FormCompText from "../form-text";

import BtnHelp from "../../basic-elements/comp-btn-help";
import { BsHeartPulseFill } from 'react-icons/bs';

import FormCompSelectFlex from '../form-select-flex';
import QuillEditorStaff from "../../quill-staff"; // Import the QuillEditor component
import ElementInfo from "../../comp-ele-info";

const AccProjektangaben = ({ isValid, tags, values, setFieldValue, handleInputChange }) => {
  return (
    <Accordion
      flush
      className={classNames("accordion-flush", {
        "accordion-filled": isValid.accordion2,
      })}
    >
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <h2>Angaben zu deinem Projekt</h2>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col md="6" xs="12">
              <Row>
                <Col>
                  <FormCompCheckbox
                    name="eng_transcr"
                    labelText="Englische Übersetzung hinterlegen"
                  />
                </Col>
                <Col lg="6" md="12" sm="12">
                  <BtnHelp
                    url="https://olat.vcrp.de/auth/RepositoryEntry/4362993777/CourseNode/108701612779648"
                    IconComponent={BsHeartPulseFill}
                  >
                    Hier Hilfe erhalten!
                  </BtnHelp>
                </Col>
              </Row>
              <Col md="6" xs="12">
                <FormCompText
                  name="ger_project_titel"
                  labelText="Projekttitel:"
                  onChange={(e) => {
                    const { value } = e.target;
                    handleInputChange("accordion2", "ger_project_titel", value);
                  }}
                />
                <FormCompText
                  name="ger_project_subtitel"
                  labelText="Untertitel / Claim:"
                  onChange={(e) => {
                    handleInputChange("accordion2", "ger_project_subtitel", e.target.value);
                  }}
                />
              </Col>
              <Col md="6" xs="12">
                {values.eng_transcr && (
                  <>
                    <FormCompText name="eng_project_titel" labelText="Englischer Projekttitel:" />
                    <FormCompText name="eng_project_subtitel" labelText="Englischer Untertitel / Claim:" />
                  </>
                )}
              </Col>
            </Col>

            <Col md="6" xs="12">
              <FormLabel htmlFor="ger_project_tags" className="formgroup">
                Schlagwörter / Tags
              </FormLabel>
              <FormCompSelectFlex
                name="ger_project_tags"
                labelText=""
                options={tags}
                isCreatable={true}
                isMulti={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md="6" xs="12">
              <FormGroup className="formgroup">
                <FormLabel>Kurzbeschreibung</FormLabel>
                <QuillEditorStaff
                  maxChars={600}
                  value={values.ger_descr_projekt_short}
                  onChange={(html) => {
                    setFieldValue("ger_descr_projekt_short", html);
                    handleInputChange("accordion2", "ger_descr_projekt_short", html);
                  }}
                  fieldname="ger_descr_projekt_short"
                />
              </FormGroup>

              {values.eng_transcr && (
                <FormGroup className="formgroup">
                  <FormLabel>Kurzbeschreibung (eng)</FormLabel>
                  <QuillEditorStaff
                    maxChars={600}
                    value={values.eng_descr_projekt_short}
                    onChange={(html) => setFieldValue("eng_descr_projekt_short", html)}
                    fieldname="eng_descr_projekt_short"
                  />
                </FormGroup>
              )}
            </Col>
            <Col md="6" xs="12">
              <FormCompCheckbox name="descr_long" labelText="Ausführliche Beschreibung hinterlegen" />
              {values.descr_long && (
                <>
                  <FormGroup className="formgroup">
                    <FormLabel>Ausführliche Beschreibung</FormLabel>
                    <QuillEditorStaff
                      maxChars={3000}
                      value={values.ger_descr_projekt_long}
                      onChange={(html) => setFieldValue("ger_descr_projekt_long", html)}
                      fieldname="ger_descr_projekt_long"
                    />
                  </FormGroup>
                  <ElementInfo
                    Infotitle="Die ausführliche Beschreibung wird z.B. bei Printpublikationen verwendet. Der Umfang orientiert sich ungefähr an einer A4 Seite."
                  />
                </>
              )}

              {values.eng_transcr && values.descr_long && (
                <FormGroup className="formgroup">
                  <FormLabel>Ausführliche Beschreibung (eng)</FormLabel>
                  <QuillEditorStaff
                    maxChars={3000}
                    value={values.eng_descr_projekt_long}
                    onChange={(html) => setFieldValue("eng_descr_projekt_long", html)}
                    fieldname="eng_descr_projekt_long"
                  />
                </FormGroup>
              )}
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccProjektangaben;
