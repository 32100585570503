// Feedback.js
import React from 'react';
import { Accordion } from 'react-bootstrap';
import ContDescr from "../../basic-elements/comp-cont-descr";
import QuillEditorStaff from "../../quill-staff";

const AccFeedback = ({ values, setFieldValue, handleInputChange }) => {
  return (
    <Accordion>
      <Accordion.Item eventKey="6">
        <Accordion.Header>
          <h2>Hinterlasse uns hier dein Feedback</h2>
        </Accordion.Header>
        <Accordion.Body>
          <ContDescr>
            Hinterlasse uns hier eine Rückmeldung zum Abgabepaket. Wir versuchen
            das Programm konstant zu verbessern.
          </ContDescr>
          <QuillEditorStaff
            maxChars={600}
            value={values.ger_feedback}
            onChange={(html) => {
              setFieldValue("ger_feedback", html);
              handleInputChange("ger_feedback", html);
            }}
            fieldname="ger_feedback"
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccFeedback;
