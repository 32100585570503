export const selecStaffMap = {
  "AR/staff-AR-Prof-Andrea-Wandel-.json": {
    "filename": "AR/staff-AR-Prof-Andrea-Wandel-.json",
    "fullname": "Prof. Andrea Wandel",
    "email": "wandel@ar.hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR",
        "selecStgType": "bachelor",
        "selecStgSem": "6"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "AR",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor"
  },
  "AR/staff-AR-Prof-Christoph-Klanten-.json": {
    "filename": "AR/staff-AR-Prof-Christoph-Klanten-.json",
    "fullname": "Prof. Christoph Klanten",
    "email": "C.Klanten@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "AR/staff-AR-Prof-Daniel-Berger-.json": {
    "filename": "AR/staff-AR-Prof-Daniel-Berger-.json",
    "fullname": "Prof. Daniel Berger",
    "email": "D.Berger@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "AR/staff-AR-Prof-Jan-Henrik-Hafke-.json": {
    "filename": "AR/staff-AR-Prof-Jan-Henrik-Hafke-.json",
    "fullname": "Prof. Jan-Henrik Hafke",
    "email": "J.Hafke@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "AR/staff-AR-Prof-Matthias-Sieveke-.json": {
    "filename": "AR/staff-AR-Prof-Matthias-Sieveke-.json",
    "fullname": "Prof. Matthias Sieveke",
    "email": "M.Sieveke@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "AR/staff-AR-Prof-Michael-Schwarz-.json": {
    "filename": "AR/staff-AR-Prof-Michael-Schwarz-.json",
    "fullname": "Prof. Michael Schwarz",
    "email": "N/A",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "AR/staff-AR-Prof-Petra-Riegler-Floors-.json": {
    "filename": "AR/staff-AR-Prof-Petra-Riegler-Floors-.json",
    "fullname": "Prof. Petra Riegler-Floors",
    "email": "P.Riegler-Floors@ar.hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "AR/staff-AR-Prof-Robert-Thum-.json": {
    "filename": "AR/staff-AR-Prof-Robert-Thum-.json",
    "fullname": "Prof. Robert Thum",
    "email": "R.Thum@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "AR",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "ES/staff-ES-Prof-Eva-Maria-Kollischan.json": {
    "filename": "ES/staff-ES-Prof-Eva-Maria-Kollischan.json",
    "fullname": "Prof. Eva-Maria Kollischan",
    "email": " E.Kollischan(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ES",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "ES",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "ES/staff-ES-Prof-Theo-Smeets-.json": {
    "filename": "ES/staff-ES-Prof-Theo-Smeets-.json",
    "fullname": "Prof. Theo Smeets",
    "email": " smeets(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ES",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "ES",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "ES/staff-ES-Prof-Ute-Eitzenhöfer-.json": {
    "filename": "ES/staff-ES-Prof-Ute-Eitzenhöfer-.json",
    "fullname": "Prof. Ute Eitzenhöfer",
    "email": "U.Eitzenhoefer(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ES",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "ES",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "IA/staff-IA-Prof-Dirk Miguel-Schluppkotten-.json": {
    "filename": "IA/staff-IA-Prof-Dirk Miguel-Schluppkotten-.json",
    "fullname": "Prof. Dirk Miguel Schluppkotten",
    "email": "schluppk(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor",
        "selecStgSem": "8"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master",
        "selecStgSem": "2"
      }
    ],
    "eng_function": "Professor"
  },
  "IA/staff-IA-Prof-Ingo-Krapf-.json": {
    "filename": "IA/staff-IA-Prof-Ingo-Krapf-.json",
    "fullname": "Prof. Ingo Krapf",
    "email": " krapf(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "IA/staff-IA-Prof-Kirsi Susanna-Leppäkoski-.json": {
    "filename": "IA/staff-IA-Prof-Kirsi Susanna-Leppäkoski-.json",
    "fullname": "Prof. Kirsi Susanna Leppäkoski",
    "email": "K.Leppaekoski(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "IA/staff-IA-Prof-Marcus-Kaiser-.json": {
    "filename": "IA/staff-IA-Prof-Marcus-Kaiser-.json",
    "fullname": "Prof. Marcus Kaiser",
    "email": " M.Kaiser(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "IA/staff-IA-Prof-Martin-Schroth-.json": {
    "filename": "IA/staff-IA-Prof-Martin-Schroth-.json",
    "fullname": "Prof. Martin Schroth",
    "email": " M.Schroth(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "IA/staff-IA-Prof-Stefan-Dornbusch-.json": {
    "filename": "IA/staff-IA-Prof-Stefan-Dornbusch-.json",
    "fullname": "Prof. Stefan Dornbusch",
    "email": " S.Dornbusch(at)hochschule-trier.de ",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "ID/staff-ID-Prof-Daniel-Gilgen-.json": {
    "filename": "ID/staff-ID-Prof-Daniel-Gilgen-.json",
    "fullname": "Prof. Daniel Gilgen",
    "email": " D.Gilgen(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ID",
        "selecStgType": "bachelor",
        "selecStgSem": "6"
      },
      {
        "roleItem": "",
        "selecStg": "ID",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor"
  },
  "ID/staff-ID-Prof-Gregor-Kuschmirz-.json": {
    "filename": "ID/staff-ID-Prof-Gregor-Kuschmirz-.json",
    "fullname": "Prof. Gregor Kuschmirz",
    "email": "G.Kuschmirz(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ID",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "ID",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor"
  },
  "ID/staff-ID-Prof-Linda-Breitlauch-.json": {
    "filename": "ID/staff-ID-Prof-Linda-Breitlauch-.json",
    "fullname": "Prof. Linda Breitlauch",
    "email": " L.Breitlauch(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ID",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "ID",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor"
  },
  "ID/staff-ID-Prof-Servet-Golbol-.json": {
    "filename": "ID/staff-ID-Prof-Servet-Golbol-.json",
    "fullname": "Prof. Servet Golbol",
    "email": " S.Golbol(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "ID",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "ID",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor"
  },
  "MD/staff-IA-MD-Prof-Christina-Threuter-.json": {
    "filename": "MD/staff-IA-MD-Prof-Christina-Threuter-.json",
    "fullname": "Prof. Christina Threuter",
    "email": "C.Threuter(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "IA",
        "selecStgType": "bachelor",
        "selecStgSem": "8"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "master",
        "selecStgSem": "2"
      },
      {
        "roleItem": "",
        "selecStg": "MD",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStg": "MD",
        "selecStgType": "master",
        "selecStgSem": "3"
      }
    ],
    "eng_function": "Professor"
  },
  "MD/staff-MD-Prof-Bettina-Maiburg-.json": {
    "filename": "MD/staff-MD-Prof-Bettina-Maiburg-.json",
    "fullname": "Prof. Bettina Maiburg",
    "email": ".Maiburg(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "MD",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStg": "MD",
        "selecStgType": "master",
        "selecStgSem": "3"
      }
    ],
    "eng_function": "Professor"
  },
  "MD/staff-MD-Prof-Christian-Bruns-.json": {
    "filename": "MD/staff-MD-Prof-Christian-Bruns-.json",
    "fullname": "Prof. Christian Bruns",
    "email": " bruns(at)hochschule-trier.de ",
    "sdef_studiengang_sV": [
      {
        "selecStg": "MD",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "MD",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "MD/staff-MD-Prof-Dirk-Wolfes-.json": {
    "filename": "MD/staff-MD-Prof-Dirk-Wolfes-.json",
    "fullname": "Prof. Dirk Wolfes",
    "email": " D.Wolfes(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStg": "MD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "MD",
        "selecStgSem": "3"
      }
    ],
    "eng_function": "Professor"
  },
  "MD/staff-MD-Prof-Sarah-Weisman-.json": {
    "filename": "MD/staff-MD-Prof-Sarah-Weisman-.json",
    "fullname": "Prof. Sarah Weisman",
    "email": "weismans(at)hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "MD",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStg": "MD",
        "selecStgType": "master"
      }
    ],
    "eng_function": "Professor"
  },
  "TD/staff-TD-Prof-Jörg-Obergfell-.json": {
    "filename": "TD/staff-TD-Prof-Jörg-Obergfell-.json",
    "fullname": "Prof. Jörg Obergfell",
    "email": "J.Obergfell@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "TD",
        "selecStgType": "bachelor"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "TD"
      }
    ],
    "eng_function": "Professor"
  },
  "TD/staff-TD-Prof-Simon-Maris-.json": {
    "filename": "TD/staff-TD-Prof-Simon-Maris-.json",
    "fullname": "Prof. Simon Maris",
    "email": "maris@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStg": "TD"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "TD"
      }
    ],
    "eng_function": "Professor"
  },
  "TD/staff-TD-Prof-Susanne-Bennewitz-.json": {
    "filename": "TD/staff-TD-Prof-Susanne-Bennewitz-.json",
    "fullname": "Prof. Susanne Bennewitz",
    "email": "S.Bennewitz@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStgSem": "7",
        "selecStg": "TD"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "TD"
      }
    ],
    "eng_function": "Professor"
  },
  "staff_kd/staff-Empl-KD-Achim-Andres_1716292523.json": {
    "filename": "staff_kd/staff-Empl-KD-Achim-Andres_1716292523.json",
    "fullname": "Achim Andres",
    "email": "andres@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Employee"
  },
  "staff_kd/staff-Empl-KD-Frank-Thieltges_1716294851.json": {
    "filename": "staff_kd/staff-Empl-KD-Frank-Thieltges_1716294851.json",
    "fullname": "Frank Thieltges",
    "email": "f.thieltges@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Employee"
  },
  "staff_kd/staff-Lect-KD-Michael-Zirlewagen_1716294114.json": {
    "filename": "staff_kd/staff-Lect-KD-Michael-Zirlewagen_1716294114.json",
    "fullname": "Michael Zirlewagen",
    "email": "M.Zirlewagen@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      }
    ],
    "eng_function": "Lecturer"
  },
  "staff_kd/staff-Lect-KD-Nicholas-Tänzer_1716292829.json": {
    "filename": "staff_kd/staff-Lect-KD-Nicholas-Tänzer_1716292829.json",
    "fullname": "Nicholas Tänzer",
    "email": "n.taenzer@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      }
    ],
    "eng_function": "Lecturer"
  },
  "staff_kd/staff-Prof-KD-Babak Mossa-Asbagholmodjahedin_1716292918.json": {
    "filename": "staff_kd/staff-Prof-KD-Babak Mossa-Asbagholmodjahedin_1716292918.json",
    "fullname": "Prof. Babak Mossa Asbagholmodjahedin",
    "email": "boback@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor"
  },
  "staff_kd/staff-Prof-KD-Christopher-Ledwig_1716292973.json": {
    "filename": "staff_kd/staff-Prof-KD-Christopher-Ledwig_1716292973.json",
    "fullname": "Prof. Christopher Ledwig",
    "email": "ledwig@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor"
  },
  "staff_kd/staff-Prof-KD-Dirk-Wachowiak_1716293050.json": {
    "filename": "staff_kd/staff-Prof-KD-Dirk-Wachowiak_1716293050.json",
    "fullname": "Prof. Dirk Wachowiak",
    "email": "d.wachowiak@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "KD",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor"
  },
  "staff_kd/staff-Prof-KD-Harald-Steber_1716293207.json": {
    "filename": "staff_kd/staff-Prof-KD-Harald-Steber_1716293207.json",
    "fullname": "Prof. Harald Steber",
    "email": "steber@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor"
  },
  "staff_kd/staff-Prof-KD-Henriette-Sauvant_1716304595.json": {
    "filename": "staff_kd/staff-Prof-KD-Henriette-Sauvant_1716304595.json",
    "fullname": "Prof. Henriette Sauvant",
    "email": "h.sauvant@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "KD",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor"
  },
  "staff_kd/staff-Prof-KD-IA-Gerald-Schröder_1716292668.json": {
    "filename": "staff_kd/staff-Prof-KD-IA-Gerald-Schröder_1716292668.json",
    "fullname": "Prof. Dr. habil. Gerald Schröder",
    "email": "G.Schroeder@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "KD",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStg": "IA",
        "selecStgType": "bachelor",
        "selecStgSem": "8"
      },
      {
        "roleItem": "",
        "selecStg": "KD",
        "selecStgType": "master",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStg": "KD",
        "selecStgType": "master",
        "selecStgSem": "4"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "IA",
        "selecStgSem": "2"
      }
    ],
    "eng_function": "Professor"
  },
  "staff_kd/staff-Prof-KD-Tim-Romanowsky_1716292395.json": {
    "filename": "staff_kd/staff-Prof-KD-Tim-Romanowsky_1716292395.json",
    "fullname": "Prof. Tim Romanowsky",
    "email": "T.Romanowsky@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "KD",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor"
  },
  "staff_kd/staff-Prof-KD-Valerie-Schmidt_1716293503.json": {
    "filename": "staff_kd/staff-Prof-KD-Valerie-Schmidt_1716293503.json",
    "fullname": "Prof. Valerie Schmidt",
    "email": "v.schmidt@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStg": "KD",
        "selecStgType": "bachelor",
        "selecStgSem": "7"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor"
  },
  "staff_kd/staff-Prof-TD-ID-MD-AR-KD-Susanne-Bennewitz_1716293651.json": {
    "filename": "staff_kd/staff-Prof-TD-ID-MD-AR-KD-Susanne-Bennewitz_1716293651.json",
    "fullname": "Prof. Dr.  Susanne Bennewitz",
    "email": "S.Bennewitz@hochschule-trier.de",
    "sdef_studiengang_sV": [
      {
        "selecStgType": "bachelor",
        "selecStgSem": "7",
        "selecStg": "TD"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStgSem": "4",
        "selecStg": "TD"
      },
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "ID",
        "selecStgSem": "2"
      },
      {
        "roleItem": "",
        "selecStgType": "bachelor",
        "selecStg": "MD",
        "selecStgSem": "6"
      },
      {
        "roleItem": "",
        "selecStg": "AR",
        "selecStgType": "bachelor",
        "selecStgSem": "6"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "AR",
        "selecStgSem": "4"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "KD",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "3"
      },
      {
        "roleItem": "",
        "selecStg": "KD",
        "selecStgSem": "4",
        "selecStgType": "master"
      },
      {
        "roleItem": "",
        "selecStgType": "master",
        "selecStg": "ID",
        "selecStgSem": "4"
      }
    ],
    "eng_function": "Professor"
  }
};