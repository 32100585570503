// src/functions/processCountArray.js

export async function processCountArray(jsonContent, countarrayDispatch, setFieldValue) {
    const maxCounts = {};
  
    // First, accumulate the maximum counts for each field
    for (const field in jsonContent) {
      if (field.endsWith("_inputCount")) {
        const baseFieldName = field.replace("_inputCount", "");
        const count = parseInt(jsonContent[field], 10);
        if (!isNaN(count)) {
          if (!maxCounts[baseFieldName] || count > maxCounts[baseFieldName]) {
            maxCounts[baseFieldName] = count;
          }
        }
      }
    }
  
    // Dispatch the maximum counts and update Formik values for each field
    for (const baseFieldName in maxCounts) {
      const count = maxCounts[baseFieldName];
      const fieldName = `${baseFieldName}_inputCount`; // Adjust if your naming convention differs
      countarrayDispatch({
        type: "SET_MAX_INPUT_COUNT",
        fieldName: baseFieldName,
        count: count,
      });
      setFieldValue(fieldName, count); // Set the value in Formik's state
      console.log(`Set Formik field: ${fieldName} to value: ${count}`);
    }
  
    console.log("highest number of inputs:", maxCounts);
    console.log("Processed maximum input counts and updated state with the highest values.");
  }
  