import { PDFDocument } from 'pdf-lib';

export const utilsPDF = async (file, setPdfInfo, setFieldValue, name, setErrorMessage, cropmark, desiredWidth, desiredHeight) => {
  const reader = new FileReader();
  reader.onload = async (e) => {
    try {
      const pdfBuffer = e.target.result;
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      const pages = pdfDoc.getPages();
      const pageInfo = await Promise.all(pages.map((page, index) => extractPageInfo(page, index)));

      setPdfInfo(pageInfo);
      setFieldValue(name, file);  // Optionally update the Formik field
      handleCropMarks(pageInfo, setErrorMessage, cropmark, desiredWidth, desiredHeight);
    } catch (error) {
      console.error("Error processing PDF file:", error);
      setErrorMessage("Failed to load or process PDF file.");
    }
  };
  reader.readAsArrayBuffer(file);
};

const extractPageInfo = async (page, index) => {
  const { width, height } = page.getSize();
  const dpi = 72;  // Standard DPI for PDFs in points per inch
  const widthInches = width / dpi;
  const heightInches = height / dpi;

  // Convert inches to centimeters
  const widthCm = (widthInches * 2.54).toFixed(2);
  const heightCm = (heightInches * 2.54).toFixed(2);

  const annotations = await extractAnnotations(page);

  return {
    pageIndex: index + 1,
    widthPx: width,
    heightPx: height,
    widthCm,
    heightCm,
    dpiWidth: dpi,
    dpiHeight: dpi,
    annotations
  };
};

const extractAnnotations = async (page) => {
  const annotations = [];
  const annotsRefs = page.node.Annots();
  if (annotsRefs) {
    const annotsArray = await page.doc.context.lookup(annotsRefs);
    if (Array.isArray(annotsArray)) {
      for (const ref of annotsArray) {
        const annotationDict = await page.doc.context.lookup(ref);
        annotations.push(annotationDict.toString());  // Adjust this line as needed
      }
    }
  }
  return annotations;
};

const handleCropMarks = (pageInfo, setErrorMessage, cropmark, desiredWidth, desiredHeight) => {
  if (cropmark) {
    const errors = pageInfo.filter(info =>
      parseFloat(info.widthCm) < desiredWidth || parseFloat(info.heightCm) < desiredHeight
    );
    setErrorMessage(errors.length > 0 ? "Überprüfe ob das Dokument über Schnittmarken verfügt" : "");
  }
};
