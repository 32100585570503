// src/functions/formik/processfiles/func-processPDFFiles.js

export async function processPDFFiles(fileKeys, jsonContent, content, setFieldValue, pdfDispatch) {
    for (const key of fileKeys) {
      if (key.endsWith(".pdf")) {
        for (const field in jsonContent) {
          if (jsonContent[field] === key) {
            const blob = await content.files[key].async("blob");
            const pdfUrl = URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));
            const formikField = field.replace("_filename", "");
  
            const pdfFile = new File([blob], jsonContent[field], {
              type: "application/pdf",
            });
            setFieldValue(formikField, pdfFile);
            setFieldValue(`${formikField}_url`, pdfUrl);
            console.log(`Set Formik field: ${formikField} to PDF file and URL: ${pdfUrl}`);
  
            pdfDispatch({
              type: "SET_PDF_URL",
              fieldName: formikField,
              pdfUrl,
            });
            console.log("Dispatched SET_PDF_URL action:", {
              fieldName: formikField,
              pdfUrl,
            });
          }
        }
      }
    }
  }
  