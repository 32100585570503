import React from 'react';
import pkg from '../../../package.json'; // Import the whole package.json file
import Changelog from '../basic-elements/comp-changelog';
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  FormSelect,
  Accordion,
  Card,
  Tab,
  Tabs,
  Row,
  Nav,
  Col,
} from "react-bootstrap";

const StudWFooter = ({ Title }) => (
  <div
    className='studw-footer'
  >
    <Row>
      <Col md='10'>
      <p>
      Abgabepaket entwickelt von Alois C. Kaufmann, Marie Christin Kajewski im Rahmen des Projekts Digistart. Das Abgabepaket befindet sich aktuell noch in Entwicklung.
      </p><hr></hr>
      <p style={{ fontStyle: 'italic', fontSize:'1.2rem' }}>Aktuelle Version Nr. {pkg.version}, zuletzt aktualisiert am {pkg.buildDate}</p>
      </Col>
      <Col>
      <img style={{ height: '70px', margin: '20px', float:'right'  }} src='../../../img/icons/SVG/Logo_stiftung.svg' />
      </Col>
    </Row>

    <Row>
      <Col>
        <Changelog></Changelog>
      </Col>
      
    </Row>
    
  </div>
);

export default StudWFooter;
