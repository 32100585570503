// HeaderAbgabepaket.js
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Icon_export from '../icons/icon-export';
import Icon_import from '../icons/icon-import';
import Rivepackageanimation from './Rive_packageanimation';

const HeaderAbgabepaket = ({
  acc_Seafile_setShowAccordion,
  acc_Seafile_setActiveKey,
  handleFileInputChange,
  setFieldValue,
  setTouched,
  setManualTrigger,
  abgahandleSubmit,
  setFormValues,
  values,
  setValues,
  imgDispatch,
  fileInputRef,
  pdfDispatch,
  varfileDispatch,
  countarrayDispatch,
  processImageFiles,
  processPDFFiles,
  processVarFiles,
  processCountArray,
}) => {
  return (
    <div style={{ backgroundColor: "rgb(236, 236, 236)" }}>
      <Row>
        <Col xs="auto" md="auto" className="d-flex justify-content-center">

        <Rivepackageanimation
            src="/abgabepaket.riv" // Make sure this matches your file path
            stateMachine="abgabepaket" // Matches the state machine name in Rive
            hoverState="Hover" // Matches the input name in Rive
            style={{ height: '160px', width: '160px' }}
    
          />

          {/* <img
            src="./img/icons/icon_abgabepaket.svg"
            style={{ width: "150px", padding: "15px" }}
            className="icon_up"
            alt="File Icon"
          /> */}
        </Col>
        <Col className="d-flex align-items-center">
          <h1 style={{ color: "#A50034" }}>Abgabepaket</h1>
        </Col>
        <Col className="d-flex align-items-center" md="auto" xs="6">
          <Button
            variant="primary"
            type="button"
            className="custom-button"
            style={{
              height: "60%",
              minWidth: "150px",
              width: "100%",
              minHeight: "50px",
            }}
            onClick={() => {
              acc_Seafile_setShowAccordion(true);
              acc_Seafile_setActiveKey("0");
              abgahandleSubmit(values, setValues);
            }}
          >
            <Icon_export width="18px" /> Export .zip
          </Button>
        </Col>
        <Col className="d-flex align-items-center" md="auto" xs="6">
          <Button
            variant="primary"
            onClick={() => fileInputRef.current.click()}
            className="custom-button"
            style={{
              height: "60%",
              minWidth: "150px",
              width: "100%",
              minHeight: "50px",
            }}
          >
            <Icon_import width="18px" /> Import .zip
          </Button>
          <div style={{ marginBottom: "0px" }}>
            <input
              type="file"
              accept=".json,.zip"
              onChange={(event) =>
                handleFileInputChange(
                  event,
                  setFieldValue,
                  setTouched,
                  setManualTrigger,
                  setFormValues,
                  values,
                  imgDispatch,
                  processImageFiles,
                  processPDFFiles,
                  processVarFiles,
                  processCountArray,
                  pdfDispatch,
                  varfileDispatch,
                  countarrayDispatch
                )
              }
              style={{ display: "none" }}
              ref={fileInputRef}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HeaderAbgabepaket;
