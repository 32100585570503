// AccTemplateVorschau.js
import React from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import ContDescr from "../../basic-elements/comp-cont-descr";

import TemplatePreview from "../../comp-templatepreview";
import TemplatePreviewReact from "../../templates/comp-temp/comp-temp-preview";
import TemplateInsta from '../../templates/temp-insta.js'; // Import the new template component
import TemplateYoutube from '../../templates/temp-youtube.js'; // Import the new template component
import TemplatePreviewYT from "../../comp-templatepreview-yt";

const AccTemplateVorschau = ({ values }) => {
  return (
    <Accordion>
      <Accordion.Item eventKey="6">
        <Accordion.Header>
          <h2>Template Vorschau</h2>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <ContDescr>
              Hier kannst du die Vorschau der unterschiedlichen Templates
              einsehen. Wenn du deine Angaben exportierst werden die
              Templatedateien automatisiert in der ZIP Datei hinterlegt.
            </ContDescr>
            {values.url_insta_allowance && (
              <Col xl="4" lg="12" md="12" sm="12">
                <TemplatePreviewReact
                  templateComponent={TemplateInsta}
                  displayName="Instagram Template"
                  values={values}
                />
              </Col>
            )}

            {values.check_youtube_allowance === true && (
              <Col xl="4" lg="12" md="12" sm="12">
                 <TemplatePreviewReact
                  templateComponent={TemplateYoutube}
                  displayName="Youtube Template"
                  values={values}
                />
               
              </Col>
            )}

            <Col xl="4" lg="12" md="12" sm="12">
              <TemplatePreview
                templateName="metadata-template.hbs"
                displayName="Metadata Template"
                values={values}
              />
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccTemplateVorschau;
