import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

const Changelog = () => {
  const [changelog, setChangelog] = useState('');
  const [showChangelog, setShowChangelog] = useState(false);

  useEffect(() => {
    fetch('/CHANGELOG.md')
      .then((response) => response.text())
      .then((text) => setChangelog(text));
  }, []);

  const toggleChangelog = () => {
    setShowChangelog((prevShow) => !prevShow);
  };

  return (
    <div>
      <p onClick={toggleChangelog} style={{ cursor: 'pointer' }}>
        Changelog
      </p>
      {showChangelog && (
        <div>
          <ReactMarkdown>{changelog}</ReactMarkdown>
        </div>
      )}
    </div>
  );
};

export default Changelog;
