// select-generate-year.js

// Function to generate the list of years
export const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 2;
    const endYear = currentYear + 1;
    const years = [];
  
    for (let year = startYear; year <= endYear; year++) {
      years.push({ value: year, label: year });
    }
  
    return years;
  };
  
  // Function to get the current year
  export const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  