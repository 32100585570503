import React, { useEffect } from 'react';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';

function Rivepackageanimation({ src, stateMachine, hoverState, style, externalHover }) {
  const { RiveComponent, rive } = useRive({
    src,
    stateMachines: stateMachine,
    autoplay: true,
  });

  // Get the Hover input from the state machine
  const hoverInput = useStateMachineInput(rive, stateMachine, hoverState);

  useEffect(() => {
    if (hoverInput) {
      hoverInput.value = externalHover; // Set the Hover input value based on externalHover
      console.log(`Hover input updated to: ${hoverInput.value}`);
    } else {
      console.error('Hover input not found in the state machine.');
    }
  }, [externalHover, hoverInput]);

  return <RiveComponent style={style} />;
}

export default Rivepackageanimation;
