// useUpdateFilenames.js
import { useEffect } from "react";

const useUpdateFilenames = ({ name, inputCount, state, setFieldValue, imgnaming, fileExtension = "pdf" }) => {
  useEffect(() => {
    const updateFilenames = () => {
      for (let i = 1; i <= inputCount; i++) {
        const currentName = inputCount > 1 ? `${name}_${i}` : name;
        const fileData = state[currentName];

        if (fileData && fileData.isFileSelected) {
          const extension = fileData.filename?.split('.').pop() || fileExtension;
          const newFilename = `${imgnaming}_${currentName}.${extension}`;
          console.log("Updating filename in useUpdateFilenames (without MIME type):", newFilename);
          setFieldValue(`${currentName}_filename`, newFilename);
        }
      }
    };

    if (Object.values(state).some(file => file && file.isFileSelected)) {
      updateFilenames();
    }
  }, [imgnaming, inputCount, setFieldValue, name, state, fileExtension]);
};

export default useUpdateFilenames;
