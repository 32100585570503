// comp-acc-perKontakt.js
import React from 'react';
import { Accordion, Row, Col, Button } from 'react-bootstrap';
import { useFormikContext, FieldArray } from 'formik';
import FormCompText from "../form-text";
import FormCompTextAllow from "../form-text-allow";
import FormCompSelect from "../form-select";
import FormCompCheckbox from "../form-checkbox";



import ElementInfo from "../../comp-ele-info";

import AccordionSub from "../../comp-acc-sub";
import ContDescr from "../../basic-elements/comp-cont-descr";
import QuillEditorStaff from "../../quill-staff"; // Import the QuillEditor component
import { AiFillBehanceSquare } from 'react-icons/ai';
import { FaLinkedin, FaXingSquare } from 'react-icons/fa';

const AccPersKont = ({ selecStgSem, handleInputChange, setFieldValue, alumni }) => {
    const { values } = useFormikContext(); // Access Formik values directly
     return (
    <Accordion flush>
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <h2>persönliche Kontaktdaten</h2>
        </Accordion.Header>
        <Accordion.Body>
          <FieldArray name="persondata">
            {({ insert, remove, push, move }) => (
              <>
                {values.persondata &&
                  values.persondata.length > 0 &&
                  values.persondata.map((person, index) => {
                    const isFirst = index === 0;
                    const isLast = index === values.persondata.length - 1;

                    return (
                      <Row key={index}>
                        <Col>
                          <Row className="d-flex align-items-stretch">
                            <Col xs="auto" md="auto" style={{ minWidth: "130px" }}>
                              <div style={{ position: "relative", minHeight: "50px" }}>
                                <Button
                                  variant="danger"
                                  className="btn-remove"
                                  onClick={() => remove(index)}
                                  disabled={values.persondata.length === 1}
                                  style={{ marginRight: "5px" }}
                                >
                                  -
                                </Button>
                                <Button
                                  variant="primary"
                                  className="btn-add"
                                  onClick={() => push({ vorname: "", nachname: "" })}
                                  style={{ marginRight: "5px" }}
                                >
                                  +
                                </Button>
                                <Button
                                  variant="secondary"
                                  className="btn-move"
                                  onClick={() => move(index, index - 1)}
                                  disabled={isFirst}
                                  style={{
                                    marginRight: "5px",
                                    minHeight: "100%",
                                  }}
                                >
                                  ↑
                                </Button>
                                <Button
                                  variant="secondary"
                                  className="btn-move"
                                  style={{ minHeight: "100%" }}
                                  onClick={() => move(index, index + 1)}
                                  disabled={isLast}
                                >
                                  ↓
                                </Button>
                              </div>
                            </Col>
                            <Col>
                              <AccordionSub
                                Hx="h2"
                                Infotitle={
                                  person.vorname && person.nachname
                                    ? `${person.vorname} ${person.nachname}`
                                    : `StudentIn ${index + 1}`
                                }
                              >
                                <Row>
                                  <Col md="6">
                                    <FormCompText name={`persondata[${index}].vorname`} labelText="Vorname:" />
                                    <FormCompText name={`persondata[${index}].nachname`} labelText="Nachname:" />
                                    <FormCompTextAllow
                                      name={`persondata[${index}].email_hochschule`}
                                      urlval={false}
                                      textTrue="Veröffentlichung erlaubt"
                                      textFalse="Veröffentlichung nicht erlaubt"
                                      labelText="Hochschul-Email:"
                                    />
                                    <FormCompSelect
                                      name={`persondata[${index}].selecStgSem`}
                                      labelText="Semester:"
                                      options={selecStgSem}
                                      onChange={(e) => handleInputChange("selecStgSem", e.target.value)}
                                    />
                                    <ElementInfo Infotitle="Wähle das Semester aus in welchem du dich befindest." />
                                  </Col>
                                  <Col md="6">
                                    <FormCompTextAllow
                                      name={`persondata[${index}].url_homepage`}
                                      urlval={true}
                                      textTrue="Veröffentlichung erlaubt"
                                      textFalse="Veröffentlichung nicht erlaubt"
                                      labelText="Persönliche Webseite (link):"
                                    />
                                    <FormCompTextAllow
                                      name={`persondata[${index}].url_insta`}
                                      urlval={true}
                                      textTrue="Veröffentlichung erlaubt"
                                      textFalse="Veröffentlichung nicht erlaubt"
                                      labelText="Instagram (link):"
                                    />
                                    <ElementInfo Infotitle="Bitte vollständige URL bei den Links hinterlegen" />
                                  </Col>
                                </Row>
                                {alumni && (
                                  <AccordionSub Hx="h4" Infotitle="Alumni Angaben">
                                    <FormCompCheckbox
                                      name={`persondata[${index}].check_youtube_allowance`}
                                      textTrue="Veröffentlichung des Videos auf Youtube erlaubt"
                                      textFalse="Veröffentlichung des Videos auf Youtube nicht erlaubt"
                                    />
                                    {!values.persondata[index].check_youtube_allowance && (
                                      <>
                                        <ContDescr>
                                          Bitte hinterlege eine kurze Begründung.
                                        </ContDescr>
                                        <QuillEditorStaff
                                          maxChars={300}
                                          value={values.persondata[index].ger_descr_check_youtube_allowance}
                                          onChange={(html) => {
                                            setFieldValue(
                                              `persondata[${index}].ger_descr_check_youtube_allowance`,
                                              html
                                            );
                                            handleInputChange(
                                              "ger_descr_check_youtube_allowance",
                                              html
                                            );
                                          }}
                                        />
                                      </>
                                    )}
                                    <FormCompTextAllow
                                      icon={AiFillBehanceSquare}
                                      name={`persondata[${index}].url_behance`}
                                      urlval={true}
                                      labelText="Behance (link):"
                                    />
                                    <FormCompTextAllow
                                      icon={FaLinkedin}
                                      name={`persondata[${index}].url_linkedin`}
                                      labelText="LinkedIn (link):"
                                      urlval={true}
                                    />
                                    <FormCompTextAllow
                                      icon={FaXingSquare}
                                      name={`persondata[${index}].url_xing`}
                                      labelText="Xing (link):"
                                      urlval={true}
                                    />
                                  </AccordionSub>
                                )}
                              </AccordionSub>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
              </>
            )}
          </FieldArray>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccPersKont;
