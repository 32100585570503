import axios from 'axios';


export const CONFIG = {
    proxyUrl: "http://localhost:8080",
    server: "https://seafile.rlp.net",
    repoId: "8a476014-0353-4aa9-b04f-f38b4b83349f",
    repoID_import: "059b0483-aa89-4a31-9e60-7439c99785be",//import Ordner
    authToken: "4d5a081f606d9a337ee9c8e5d6bd79a7ef05ab4b"
};



export const CONFIGSeafileDirApi = {	
    proxyUrl: "http://localhost:8080",
    server: "https://seafile.rlp.net",
    // authToken: 'bfa68fe3f82ed4730d614e6603dd187eb46e3880'
};

// Now set finalUrl using the defined proxyUrl and server
CONFIG.finalUrl = `${CONFIG.proxyUrl}/${CONFIG.server}`;


export const handleFileChange = (setFile) => (event) => {
    const selectedFile = event.target.files[0];
    selectedFile ? console.log("File selected:", selectedFile.name) : console.log("No file selected.");
    setFile(selectedFile);
};





export const getTimestampedFilename = (filename) => {
    const timestamp = Math.floor(Date.now() / 1000);
    const [name, extension] = filename.split('.').reduce((acc, part, index, array) => index === array.length - 1 ? [acc[0], part] : [`${acc[0]}.${part}`, acc[1]], ['', '']);
    return `${name}_${timestamp}.${extension}`;
};


export const parseTimestampAndFormat = (filename) => {
    // Regex to find a sequence of digits that represents a Unix timestamp
    const timestampRegex = /_(\d{10})\./; // Assumes timestamp is just before the file extension and prefixed by an underscore
    const match = filename.match(timestampRegex);

    if (match) {
        const timestamp = parseInt(match[1], 10);
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
        return `${date.getFullYear()}.${String(date.getMonth() + 1).padStart(2, '0')}.${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
    } else {
        return "*no timestamp included";
    }
};

export const parseTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    return `${date.getFullYear()}.${String(date.getMonth() + 1).padStart(2, '0')}.${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
};


export const parseTimestampFolder = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    // Generate the folder name
    const folderName = `${year}${month}${day}_${hours}${minutes}${seconds}`;
    
    // Remove any problematic characters
    return folderName.replace(/[<>:"/\\|?*\u0000-\u001F]/g, '');
  };
  

// Extract and parse the timestamp from the filename
export const getTimestampFromFilename = (filename) => {
    const timestampRegex = /_(\d{10})\./;
    const match = filename.match(timestampRegex);
    return match ? parseInt(match[1], 10) : null;
};



export const uploadFile = async (file, uploadUrl) => {
    if (!file) {
        console.log("No file is set for upload.");
        return null;
    }
    const formData = new FormData();
    formData.append('file', file, getTimestampedFilename(file.name));
    formData.append('parent_dir', '/');
    try {
        await axios.post(uploadUrl, formData, {
            headers: {
                'Authorization': `Token ${CONFIG.authToken}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return formData.get('file').name; // Modified filename from FormData
    } catch (error) {
        console.error('Error uploading file:', error);
        return null;
    }
};





// Filter function to select only JSON files
export const filterJsonFiles = (files) => {
    if (Array.isArray(files)) {
        

        return files.filter(file => file.type === 'file' && file.name.endsWith('.json'));
    } else {
        console.error('Provided data is not an array:', files);
        return []; // Return an empty array to avoid further errors
    }
};

// Generate a filelist based on the selected Repository/Directory on Seafile
// add new const for api based on component
export const fetchFilesList = async (setFilesList) => {
    const url = `${process.env.REACT_APP_SF_proxyUrl}${process.env.REACT_APP_SF_Server}${process.env.REACT_APP_SF_Imp_Staff_dirpath}`
    console.log("Request URL:", url); // Debugging output

    try {
        const response = await axios.get(url, {
            headers: {
                'Authorization': `Token ${process.env.REACT_APP_SF_Imp_Staff_authToken}`, //use token based on component
                'Accept': 'application/json; indent=4'
            }
        });
        console.log("Response Data:", response.data); // Detailed debugging output

        if (response.data && response.data.dirent_list && Array.isArray(response.data.dirent_list)) {
            const jsonFiles = filterJsonFiles(response.data.dirent_list);
            setFilesList(jsonFiles);
        } else {
            console.error('Unexpected data format or no data:', response.data);
            setFilesList([]); // Handle gracefully by setting an empty list
        }
    } catch (error) {
        console.error('Error fetching files list:', error);
    }
};



// ----------------------------
// TESTING AREA
// ----------------------------
//Testing Area i should exclude those parts long term from the final script



// Getting a feedback if the file is uploaded correctly
export const getFileDetail = async (filename, setUploadStatus, setFileDetails) => {
    const url = `${CONFIG.proxyUrl}/${CONFIG.server}/api2/repos/${CONFIG.repoID}/file/detail/?p=/${filename}`;
    try {
        const { data } = await axios.get(url, { headers: { 'Authorization': `Token ${CONFIG.authToken}` } });
        console.log("API Response for File Detail:", data);
        setUploadStatus(data && data.name === filename ? 'successful' : 'failed');
        if (data && data.name === filename) {
            setFileDetails(data);
        }
    } catch (error) {
        setUploadStatus('failed');
        console.error('Error fetching file detail:', error);
    }
};



// Generate Downloadlink from Seafile and the download the file
// Used for test purposes
export const downloadFile = (filename) => {

    const targetUrl = `${CONFIG.server}/api2/repos/${CONFIG.repoId}/file/?p=/${encodeURIComponent(filename)}`;
    const finalUrl = `${CONFIG.proxyUrl}/${targetUrl}`;

    console.log("Attempting to fetch download URL:", finalUrl);

    const fetchAndDownloadFile = () => {
        const options = {
            method: 'GET',
            headers: {
                accept: 'application/json',
                authorization: `Token ${CONFIG.authToken}`
            }
        };

        fetch(finalUrl, options)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                console.log("Initial fetch response OK, fetching download URL...");
                return response.text();
            })
            .then(dlUrl => {
                console.log("Received download URL:", dlUrl);
                dlUrl = dlUrl.replace(/"/g, ''); // Remove double quotes
                console.log("Cleaned download URL:", dlUrl);
                return fetch(dlUrl, { method: "GET" });
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('File download response was not ok');
                }
                console.log("File response OK, converting to blob...");
                return response.blob(); // Convert to blob for download
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                console.log("Blob URL created:", url);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename; // Use the actual filename
                document.body.appendChild(a);
                a.click();
                console.log("Download triggered for:", filename);
                a.remove();
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.error('Error downloading file:', err);
            });
    };

    fetchAndDownloadFile(); // Call the function to perform the download
};


// This function is no longer used
// export const handleUpload = (file, setUploadStatus, setFileDetails) => async () => {
//     if (!file) {
//         alert('Please select a file first!');
//         return;
//     }
//     setUploadStatus('uploading');
//     const uploadUrl = await getUploadLink();
//     if (uploadUrl) {
//         const filenameWithTimestamp = await uploadFile(file, uploadUrl);
//         if (filenameWithTimestamp) {
//             getFileDetail(filenameWithTimestamp, setUploadStatus, setFileDetails);
//         } else {
//             setUploadStatus('failed');
//         }
//     } else {
//         console.log("Failed to get upload link, cannot proceed with upload.");
//         setUploadStatus('failed');
//     }
// };

export const getUploadLink = async () => {
    const url = `http://localhost:8080/https://seafile.rlp.net/api/v2.1/via-repo-token/upload-link/?path=/Modulhandbuch/Staff`;
    try {
        const { data } = await axios.get(url, { headers: { 'Authorization': `Token ${CONFIG.authToken}` } });
        return data;
    } catch (error) {
        console.error('Error fetching upload link:', error);
        return null;
    }
};