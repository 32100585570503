import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import '../css/template-preview.css'; // Import the CSS file for styling
import Handlebars from 'handlebars';
import { formatHtml } from "../functions/handlebar/handlebarTemplateUtils";

const TemplatePreview = ({ templateName, displayName, values }) => {
  const [templateContent, setTemplateContent] = useState(null);
  const [renderedTemplate, setRenderedTemplate] = useState("");
  const [textCopied, setTextCopied] = useState(false); // Track if text has been copied
  const [htmlCopied, setHtmlCopied] = useState(false); // Track if HTML has been copied

  // Fetch the template based on the templateName prop
  useEffect(() => {
    if (templateName) {
      const templateFile = `/templates/${templateName.toLowerCase()}`; // Path to the template file
      fetch(templateFile)
        .then((response) => response.text())
        .then((template) => {
          setTemplateContent(template); // Store the raw template content
        });
    }
  }, [templateName]);

  // Compile and render the template when content and values are available
  useEffect(() => {
    if (templateContent && values) {
      const compiledTemplate = Handlebars.compile(templateContent); // Compile the Handlebars template
      const rendered = compiledTemplate(values); // Render with form values
      setRenderedTemplate(rendered); // Set the rendered output
    }
  }, [templateContent, values]);

  // Copy the plain text version to the clipboard
  const copyTextToClipboard = () => {
    const plainText = formatHtml(renderedTemplate); // Use externalized formatHtml function
    navigator.clipboard.writeText(plainText)
      .then(() => {
        setTextCopied(true); // Change the button text and color
        setTimeout(() => setTextCopied(false), 3000); // Reset after 3 seconds
      })
      .catch(err => {
        console.error('Text konnte nicht kopiert werden: ', err);
      });
  };

  Handlebars.registerHelper('removeSpaces', function(value) {
    return value.replace(/\s+/g, '');
  });

  Handlebars.registerHelper('capitalizeFirst', function(value) {
    if (value && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  });

  // Copy the rendered HTML version to the clipboard
  const copyHtmlToClipboard = () => {
    navigator.clipboard.writeText(renderedTemplate)
      .then(() => {
        setHtmlCopied(true); // Change the HTML button text and color
        setTimeout(() => setHtmlCopied(false), 3000); // Reset after 3 seconds
      })
      .catch(err => {
        console.error('HTML konnte nicht kopiert werden: ', err);
      });
  };

  return (
    <div className=''>
      <Row>
        <Col>
          <div className='template-preview-wrapper'>
            <div className='template-preview-header'>
              <Button
                onClick={copyHtmlToClipboard}
                style={{ float: 'right', marginLeft: '10px', backgroundColor: htmlCopied ? 'green' : '' }}
              >
                {htmlCopied ? 'HTML kopiert!' : 'HTML kopieren'}
              </Button>
              <Button
                onClick={copyTextToClipboard}
                style={{ float: 'right', backgroundColor: textCopied ? 'green' : '' }}
              >
                {textCopied ? 'Text kopiert!' : 'Text kopieren'}
              </Button>
              <h2>{displayName}</h2>
            </div>
            <div className='template-preview-content'>
              {/* Render the template dynamically */}
              <div dangerouslySetInnerHTML={{ __html: renderedTemplate }} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TemplatePreview;
