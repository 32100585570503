import React from 'react';

const CompTempPersondata = ({ persondata }) => {
  return (
    <>
    <p><strong>Kontaktdaten der Studierenden:</strong></p><br></br>
      {persondata?.map((person, index) => (
        <div key={index}>
          <p>{person.vorname} {person.nachname}</p>
          {person.email_hochschule_allowance && person.email_hochschule && <p>{person.email_hochschule}</p>}
          {person.url_linkedin_allowance && person.url_linkedin && <p>LinkedIn: {person.url_linkedin}</p>}
          {person.url_homepage_allowance && person.url_homepage && <p>Webseite: {person.url_homepage}</p>}
          {person.url_behance_allowance && person.url_behance && <p>Behance: {person.url_behance}</p>}
          {person.url_insta_allowance && person.url_insta && <p>Instagram: {person.url_insta}</p>}
          {person.check_youtube_allowance && person.url_youtube && <p>YouTube: {person.url_youtube}</p>}
          <hr />
        </div>
      ))}
    </>
  );
};

export default CompTempPersondata;
