import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import StudentWork from '../student-work';
import './StudentWorkIntro.css'; // Import the CSS file
import Rivepackageanimation from '../abgabepaket/Rive_packageanimation'; // Import the new component

function StudentWorkIntro() {
  const [isOpen, setIsOpen] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [wiggle, setWiggle] = useState(false);
  const [showLogos, setShowLogos] = useState(true);

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
    console.log('Hover activated.');
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    console.log('Hover deactivated.');
  };


  // Define the spring for content visibility
  const animationStyles = useSpring({
    to: {
      opacity: isOpen && !showButton ? 1 : 0,
      transform: isOpen && !showButton ? 'scaleY(1)' : 'scaleY(0)',
    },
    from: {
      opacity: 0,
      transform: 'scaleY(0)',
    },
    display: isOpen && !showButton ? 'block' : 'none',
    config: { friction: 10, tension: 210, mass: 0.5 }, // Smooth animation
  });

  // Define the spring for the wiggle effect
  const wiggleAnimation = useSpring({
    to: [
      { rotateZ: 10 },
      { rotateZ: -10 },
      { rotateZ: 10 },
      { rotateZ: -10 },
      { rotateZ: 10 },
      { rotateZ: 0 },
    ],
    from: { rotateZ: 0 },
    reset: wiggle,
    onRest: () => {
      if (wiggle) {
        setWiggle(false);
        if (isOpen) {
          setShowButton(false);
          setShowLogos(false);
        }
      }
    },
    config: { duration: 50 },
    loop: wiggle,
  });

  const handleRiveHoverChange = (isHovering) => {
    console.log('Hover state changed:', isHovering);
  };

  return (
    <>
      {showLogos && (
        <>
          <div className="logo_left">
            <img style={{ height: '70px', margin: '20px' }} src="/img/icons/SVG/Logo_stiftung.svg" />
          </div>
          <div className="logo_right">
            <img style={{ height: '50px', margin: '20px' }} src="/img/icons/SVG/Logo_HT_CG.svg" />
          </div>
          <div className="logo_bottoml">Entwickelt von Alois C. Kaufmann</div>
        </>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
     
        {showButton && (
          <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
            className="btn_abgabepaket"
            onClick={() => {
              setIsOpen(true);
              setWiggle(true);
              setShowLogos(false);
            }}
            role="button"
            tabIndex={0}
          >
            <Rivepackageanimation
              src="/abgabepaket.riv" // Make sure this matches your file path
              stateMachine="abgabepaket" // Matches the state machine name in Rive
              hoverState="Hover" // Matches the input name in Rive
              style={{ width: '150px', height: '150px' }}
              externalHover={isHovering}
            />
            <p>
              Abgabepaket
              <br />
              starten
            </p>
          </div>
        )}
      </div>
      {isOpen && !showButton && (
        <animated.div className="animatedContent" style={animationStyles}>
          <StudentWork />
        </animated.div>
      )}
    </>
  );
}

export default StudentWorkIntro;
