import * as Yup from 'yup';

export const useUrlValidation = (urlval) => {
    return async (value) => {
        // Define the base schema
        let schema = Yup.string().required('Ungültiges URL-Format. Bitte überprüfe die Eingabe');

        // Conditionally enhance the schema based on `urlval`
        if (urlval) {
            schema = schema.url('Ungültiges URL-Format. Bitte überprüfe die Eingabe');
        }

        try {
            // Validate the value against the schema
            await schema.validate(value);
            return undefined; // Return undefined on success
        } catch (error) {
            return error.message; // Return the error message on failure
        }
    };
};
