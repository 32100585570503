import React, { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { formatHtml } from '../../../functions/handlebar/handlebarTemplateUtils';
import ReactDOMServer from 'react-dom/server';

const TemplatePreviewReact = ({ displayName, values, templateComponent: TemplateComponent }) => {
  const [textCopied, setTextCopied] = useState(false);
  const [htmlCopied, setHtmlCopied] = useState(false);

  const copyTextToClipboard = () => {
    // Render the dynamic template to a static HTML string
    const htmlContent = ReactDOMServer.renderToStaticMarkup(<TemplateComponent values={values} />);
    // Convert to plain text by stripping HTML tags
    const plainText = formatHtml(htmlContent);
    
    navigator.clipboard.writeText(plainText)
      .then(() => {
        setTextCopied(true);
        setTimeout(() => setTextCopied(false), 3000);
      })
      .catch(err => {
        console.error('Text konnte nicht kopiert werden: ', err);
      });
  };

  const copyHtmlToClipboard = () => {
    const htmlContent = ReactDOMServer.renderToStaticMarkup(<TemplateComponent values={values} />);
    
    navigator.clipboard.writeText(htmlContent)
      .then(() => {
        setHtmlCopied(true);
        setTimeout(() => setHtmlCopied(false), 3000);
      })
      .catch(err => {
        console.error('HTML konnte nicht kopiert werden: ', err);
      });
  };

  return (
    <div className=''>
      <Row>
        <Col>
          <div className='template-preview-wrapper'>
            <div className='template-preview-header'>
              <Button
                onClick={copyHtmlToClipboard}
                style={{ float: 'right', marginLeft: '10px', backgroundColor: htmlCopied ? 'green' : '' }}
              >
                {htmlCopied ? 'HTML kopiert!' : 'HTML kopieren'}
              </Button>
              <Button
                onClick={copyTextToClipboard}
                style={{ float: 'right', backgroundColor: textCopied ? 'green' : '' }}
              >
                {textCopied ? 'Text kopiert!' : 'Text kopieren'}
              </Button>
              <h2>{displayName}</h2>
            </div>
            <div className='template-preview-content'>
              <TemplateComponent values={values} /> {/* Render the dynamic template */}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TemplatePreviewReact;
