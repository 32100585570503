import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/seafile-upload.css'
import App from './App';
import './App.scss';

const rootElement = document.getElementById('root');


  // Use render otherwise
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );



