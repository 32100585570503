import React from 'react';
import { useField } from 'formik';
import { FormLabel } from 'react-bootstrap';
import { redirect } from 'react-router-dom';

const FormCompCheckbox = ({ name, labelText, className, textTrue, textFalse }) => {
  const [field, meta, helpers] = useField({ name, type: 'checkbox' });

  const displayText = field.checked
    ? (textTrue || labelText)
    : (textFalse || labelText);

  return (
    
      <div className="btn-descr-enc">
        <label className="btn-checkb-checkbox">
          <input
            {...field}
            type="checkbox"
            className="hidden-checkbox"
            id={name}
          />
          <div className="btn-checkb">
            {displayText}
          </div>
        </label>
      </div>
   
  );
};

export default FormCompCheckbox;
