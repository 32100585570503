import React, { useRef, useState } from "react";
import {Formik,Form,Field,FieldArray,useField,} from "formik";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import QuillEditor from "./components/quillEditor"; // Import the QuillEditor component
import * as Yup from "yup";
import {Button, FormGroup, FormControl,FormLabel,FormSelect,Accordion,Card,Tab,Tabs,Row,Col,} from "react-bootstrap";
import { difficulty, moduldauer } from "./const";

const selecStgType = [
  { value: "bachelor", label: "Bachelor", customValue: "b" },
  { value: "master", label: "Master", customValue: "m" },];

const haeufigkeit = [
  { value: "jedes Semester", label: "jedes Semester" },
  { value: "jedes Jahr", label: "jedes Jahr" },
  { value: "bei Bedarf", label: "bei Bedarf" },];

const modus = [
  { value: "Pflichtleistung", label: "Pflichtleistung" },
  { value: "Studienleistung", label: "Studienleistung" },
  { value: "", label: "keine Angabe" },];

const selecStg = [
  { value: "AR", label: "Architektur", customValue: "AR" },
  { value: "MD", label: "Modedesign", customValue: "MD" },
  { value: "KD", label: "Kommunikationsdesign", customValue: "KD" },
  { value: "IA", label: "Innenarchitektur", customValue: "IA" },
  { value: "ID", label: "Intermedia Design", customValue: "ID" },
  { value: "ES", label: "Edelstein Schmuck", customValue: "ES" },];

const selecStgSem = [
  { value: "2", label: "2 Semester" },
  { value: "3", label: "3 Semester" },
  { value: "4", label: "4 Semester" },
  { value: "6", label: "6 Semester" },
  { value: "7", label: "7 Semester" },
  { value: "8", label: "8 Semester" },
];

const calcKatNr = (values) => {
  return `${
    values.selecStgType && typeof values.selecStgType === "string"
      ? values.selecStgType.charAt(0).toUpperCase()
      : ""
  }${values.kat}`;
};

const calcsubkat_nr = (values) => {
  return `${
    values.selecStgType && typeof values.selecStgType === "string"
      ? values.selecStgType.charAt(0).toUpperCase()
      : ""
  }${values.kat}${values.subkat ? `.${values.subkat}` : ""}`;
};

const calcfilename = (values) => {
  return `${
    values.selecStgType && typeof values.selecStgType === "string"
      ? values.selecStgType.charAt(0).toUpperCase()
      : ""
  }${
    values.selecStg && typeof values.selecStg === "string"
      ? values.selecStg.substring(0, 2).toUpperCase()
      : ""
  }${values.kat}${values.subkat ? `-${values.subkat}` : ""}${
    values.s_subkat ? `-${values.s_subkat}` : ""
  }`;
};

const calcLehrende = (values) => {
  // Check if lehrveranstaltungen array exists and is an array
  if (Array.isArray(values.lehrveranstaltungen)) {
    // Use map to extract the 'lehrende' values and return them as an array
    return values.lehrveranstaltungen.map((item) => item.lehrende);
  }
  return []; // Return an empty array if lehrveranstaltungen is not an array
};

const formSchema = Yup.object().shape({
  lehrveranstaltungen: Yup.object().shape({
    eng_inhalte: Yup.string().when("eng_transcr", {
      is: true,
      then: Yup.string().required("English content is required"),
      otherwise: Yup.string().notRequired(),
    }),
    eng_kompetenzziele: Yup.string().when("eng_transcr", {
      is: true,
      then: Yup.string().required("English competence goals are required"),
      otherwise: Yup.string().notRequired(),
    }),
  }),
  eng_transcr: Yup.boolean(),
});

const MDInput = () => {
  const fileInputRef = useRef();
  const [dirtyFields, setDirtyFields] = useState([]);
  const [initialImportDone, setInitialImportDone] = useState(false);
  const [importedQmData, setImportedQmData] = useState(null);

  const handleFieldChange = (e, handleChange) => {
    handleChange(e);
    // Track modified fields
    setDirtyFields((prevFields) => {
      if (!prevFields.includes(e.target.name)) {
        return [...prevFields, e.target.name];
      }
      return prevFields;
    });
  };
  

  const lehrform = [
    { label: "Vorlesung", value: "lehrf.lecture" },
    { label: "Seminar", value: "lehrf.seminar" },
    { label: "Übung", value: "lehrf.uebung" },
    { label: "Tutorium", value: "lehrf.tutorium" },
    { label: "Projekt(-arbeit)", value: "lehrf.project" },
    { label: "Praktikum", value: "lehrf.practica" },
    { label: "Präsentation", value: "lehrf.present" },
    { label: "E-Learning", value: "lehrf.elearning" },
    { label: "Einzel- und Gruppengespräche", value: "lehrf.singlgrouptalk" },
    { label: "Einzel- und Gruppenkorrektur", value: "lehrf.singlgroupcorr" },
    { label: "Einzelkorrektur", value: "lehrf.singlcorr" },
    { label: "Variabel", value: "lehrf.variable" },
    { label: "Kolloquium", value: "lehrf.kolloq" },
    { label: "Exkursion", value: "lehrf.excur" },
    { label: 'Workshop', value: 'lehrf.worksh' },
    { label: 'Betreuung', value: 'lehrf.betr' },
    { label: 'Projekt bezogen', value: 'lehrf.projbez' },
    { label: 'Atelier(-arbeit)', value: 'lehrf.atelier' },
    { label: 'Labor(-arbeit)', value: 'lehrf.labor' },
  ];

  const pruefungsform = [
    { label: "Portfolio", value: "mod.portfolio" },
    { label: "Projektpräsentation", value: "mod.projpres" },
    { label: "mündliche Prüfung", value: "mod.orexam" },
    { label: "Praktikums- Laborleistung", value: "mod.labperf" },
    { label: "Dokumentation, Bericht", value: "mod.docurep" },
    { label: "Kolloquium", value: "mod.kolloq" },
    { label: "Klausur", value: "mod.exam" },
    { label: "Seminar- Hausarbeit", value: "mod.seminarandpaper" },
    { label: "Hausarbeit", value: "mod.termpaper" },
    { label: "praktische Prüfung", value: "mod.pracexam" },
    { label: "Praktikumsleistung", value: "mod.internsperf" },
    { label: "Nachweis absolviertes Praktikum", value: "mod.proofcompint" },
  ];

  const leftlehrform = lehrform.slice(0, 8);
  const rightlehrform = lehrform.slice(8);

  const option = [
    { label: "1. Semester", value: "1" },
    { label: "2. Semester", value: "2" },
    { label: "3. Semester", value: "3" },
    { label: "4. Semester", value: "4" },
    { label: "5. Semester", value: "5" },
    { label: "6. Semester", value: "6" },
    { label: "7. Semester", value: "7" },
    { label: "8. Semester", value: "8" },
  ];

  // const [textEditorValue, setTextEditorValue] = useState("");

  // Function to convert HTML to EditorState
  const convertHtmlToEditorState = (html) => {
    if (!html) return EditorState.createEmpty();
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return EditorState.createWithContent(contentState);
  };

  const leftOptions = option.slice(0, 4);
  const rightOptions = option.slice(4);

  const MultiSelect = ({ label, ...props }) => {
    const [field, , helpers] = useField(props);

    const handleChange = () => {
      const currentValue = Array.isArray(field.value) ? field.value : [];
      const newValue = currentValue.includes(props.value)
        ? currentValue.filter((val) => val !== props.value) // Remove value
        : [...currentValue, props.value]; // Add value

      helpers.setValue(newValue);
    };

    const isChecked =
      Array.isArray(field.value) && field.value.includes(props.value);

    return (
      <div className="btn-descr-enc">
        <label className="btn-checkb-checkbox">
          <input
            type="checkbox"
            {...field}
            {...props}
            className="hidden-checkbox"
            checked={isChecked}
            onChange={handleChange}
          />
          <div className="btn-checkb">{label}</div>
        </label>
      </div>
    );
  };

  const initialValues = { 
    
    ger_modultitel: "",
    semester_rec: [],
    kat_nr: "",
    kat: "",
    subkat: "",
    s_subkat: "",

    selecStg: { value: "" },
    selecStgType: { value: "", customValue: "" },
    selecStgSem: "",

    modulnr: "", // Assuming modulnr is a string
    modulverantwortliche: "",

    ects: 0, // Assuming ects is a number
    kontaktzeit_sws: 0, // Assuming kontaktzeit_sws is a number
    kontaktzeit_h: 0, // Assuming kontaktzeit_h is a number
    selbstudium_h: 0, // Assuming selbstudium_h is a number
    gesamtarbeitsaufwand_h: 0, // Assuming gesamtarbeitsaufwand_h is a number
    moduldauer: "", // Assuming moduldauer is a string
    gewichtung: "",

    ger_voraussetzungen_ects: "",
    eng_voraussetzungen_ects: "",
    ger_voraussetzungen: "",
    eng_voraussetzungen: "",

    wahlpflichtfach: false, // Assuming wahlpflichtfach is a boolean
    pflichtfach: false, // Assuming pflichtfach is a boolean
    sose_kurs: false, // Assuming sose_kurs is a boolean
    wise_kurs: false, // Assuming wise_kurs is a boolean
    ger_haeufigkeit: "",

    difficulty: "", // Assuming difficulty is a string
    language: "",
    for_exchange: true, // Assuming for_exchange is a boolean
    notforexch: "",

    lehrveranstaltungen: [
      {
        ger_title: "",
        lehrform: "",
        lehrende: "",
        pruefungsformen: {
          "mod.portfolio": false,
          "mod.projpres": false,
          "mod.orexam": false,
          "mod.labperf": false,
          "mod.docurep": false,
          "mod.kolloq": false,
          "mod.exam": false,
          "mod.seminarandpaper": false,
          "mod.termpaper": false,
          "mod.pracexam": false,
          "mod.internsperf": false,
          "mod.proofcompint": false,
        },
      },
      // ... add more objects for each lehrveranstaltungen if needed ...
    ],
    verwendbarMod: [
      {
        title: "",
        // Add other properties as needed, e.g.:
        // description: "Initial Description",
        // id: 1,
      },
      // Add more objects as needed
    ]
  };

  

  // Step 4: Implement a function to handle file input change and load JSON content
  const handleFileInputChange = async (event, setFieldValue, setValues) => {
    const file = event.target.files[0];
    
    if (file) {
      try {
        const fileContent = await file.text();
        const parsedContent = JSON.parse(fileContent);
         // Set form values based on imported JSON data
         setValues(parsedContent);
         // Set the initial import flag to true after the first import
         setInitialImportDone(true);
         // Update dirty fields after initial import
         setDirtyFields(parsedContent.qm?.changedFields || []);
         // Set imported qm data
         setImportedQmData(parsedContent.qm);
       
  
        // Update the fields based on the JSON data
        Object.keys(parsedContent).forEach(key => {
          if (parsedContent.hasOwnProperty(key)) {
            if (key === 'lehrveranstaltungen' && Array.isArray(parsedContent[key])) {
              parsedContent[key].forEach((item, index) => {
                Object.keys(item).forEach(itemKey => {
                  if (itemKey === 'pruefungsformen') {
                    // Convert pruefungsformen object to array for MultiSelect
                    const pruefungsformenArray = Object.keys(item.pruefungsformen)
                      .filter(pfKey => item.pruefungsformen[pfKey]);
                    setFieldValue(`lehrveranstaltungen[${index}].pruefungsformen`, pruefungsformenArray);
                  } else {
                   
                  }
                });
              });
            } else {
              setFieldValue(key, parsedContent[key]);
            }
          }
        });
      } catch (error) {
        console.error("Error loading content from JSON file:", error);
      }
      
     
    }
  };
  
  const handleSubmit = (values, setValues) => {
    console.log("handleSubmit called with values:", values);
    const timestamp = new Date().toISOString().slice(0, 16).replace('T', ' '); // Get current timestamp to minute
    const qmData = {
      [`change_${timestamp}`]: dirtyFields.map(field => ({ [field]: values[field] }))
    };
    const kat_nr = calcKatNr(values);
    const subkat_nr = calcsubkat_nr(values);
    const filename = calcfilename(values);
    const lehrende = calcLehrende(values);
  
    // Transform pruefungsformen in each lehrveranstaltungen item
    const transformedLehrveranstaltungen = values.lehrveranstaltungen.map(
      (lv) => {
        const pruefungsformenObj = {};
        pruefungsform.forEach((pf) => {
          pruefungsformenObj[pf.value] =
            lv.pruefungsformen && lv.pruefungsformen.includes(pf.value);
        });
        return {
          ...lv,
          pruefungsformen: pruefungsformenObj,
        };
      }
    );
  
    // Construct the final JSON structure
    const jsonData = {
      qm: { ...values.qm, ...qmData },
      changedFields: dirtyFields,
      filename: filename,
      lehrende: lehrende,
      ...values,
      lehrveranstaltungen: transformedLehrveranstaltungen,
      kat_nr: kat_nr,
      subkat_nr: subkat_nr,
    };
  
    // Convert to JSON string and create a Blob for downloading
    const jsonString = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const link = document.createElement("a");
  
    link.href = URL.createObjectURL(blob);
    link.download = filename;
  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
    // Optionally, update Formik's state
    if (setValues) {
      setValues(jsonData);
    }
  };
  

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, setValues, setFieldValue }) =>
        (() => {
          const kat_nr = calcKatNr(values);
          const subkat_nr = calcsubkat_nr(values);
          const filename = calcfilename(values);

          return (
            <Form>
              <FormGroup>
                <div style={{ marginBottom: "50px" }}>
                  <Button
                    style={{
                      padding: "20px",
                      fontWeight: "600",
                      fontSize: "20px",
                    }}
                    variant="primary"
                    onClick={() => fileInputRef.current.click()}
                    className="custom-button-blue"
                    setBase64={(base64String) =>
                      setFieldValue("img_portrait_base64", base64String)
                    }
                    enableBase64={true} // Set this to true to enable Base64 conversion
                  >
                    Datei importieren
                  </Button>

                  <input
                    type="file"
                    accept=".json"
                    onChange={(event) =>
                      handleFileInputChange(event, setFieldValue, setValues)
                    }
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                </div>
              </FormGroup>

              <div className="btn-descr-enc">
                <FormLabel
                  htmlFor="eng_transcr"
                  className="btn-dscr-eng-enab"
                ></FormLabel>
                <label className="btn-checkb-checkbox">
                  <Field
                    type="checkbox"
                    name="eng_transcr"
                    className="hidden-checkbox"
                    id="eng_transcr" // Added id for association with the label
                    onChange={(e) => handleFieldChange(e, handleChange, initialImportDone, dirtyFields, setDirtyFields)}
                  />
                  <div className="btn-checkb">
                    Englische Übersetzung hinterlegen
                  </div>
                </label>
              </div>

              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h2>Studiengang</h2>
                  </Accordion.Header>

                  <Accordion.Body>
                    <Row>
                      <Col>
                        <FormGroup className="formgroup">
                          <FormLabel htmlFor="selecStgType">
                            Studiengangstyp auswählen
                          </FormLabel>
                          {/* selecStgType / Select Studiengangstypus Bachelor / Master */}
                          <Field
                            as="select"
                            id="selecStgType"
                            name="selecStgType"
                            className="form-selec"
                            onChange={(e) => {
                              const selectedType = selecStgType.find(
                                (type) => type.value === e.target.value
                              );
                              
                              console.log(kat_nr);
                              handleChange(e);
                              handleChange({
                                target: {
                                  name: "combinedValue",
                                  value: `${
                                    selectedType ? selectedType.customValue : ""
                                  }`,
                                },
                              });
                              if (initialImportDone) {
                                setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                              }
                            }}
                          >
                            <option value="">Studiengang</option>
                            {selecStgType.map((type) => (
                              <option key={type.value} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </Field>
                        </FormGroup>

                        <FormGroup className="formgroup">
                          <FormLabel htmlFor="selecStgSem">
                            Semesteranzahl
                          </FormLabel>
                          {/* selecStgSemester / Select Semesteranzahl */}
                          <Field
                            as="select"
                            id="selecStgSem"
                            name="selecStgSem"
                            className="form-selec"
                            onChange={(e) => handleFieldChange(e, handleChange, initialImportDone, dirtyFields, setDirtyFields)}
                          >
                            <option value=""></option>
                            {selecStgSem.map((type) => (
                              <option key={type.value} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </Field>
                        </FormGroup>

                        <FormGroup className="formgroup">
                          <FormLabel htmlFor="selecStgType">
                            Studiengang auswählen
                          </FormLabel>
                          {/* selecStgType / Select Studiengang */}
                          <Field
                            as="select"
                            id="selecStg"
                            name="selecStg"
                            className="form-selec"
                            onChange={(e) => {
                              const selectedStg = selecStg.find(
                                (type) => type.value === e.target.value
                              );
                              console.log(kat_nr);
                              handleChange(e);
                              handleChange({
                                target: {
                                  name: "combinedValue2",
                                  value: `${
                                    selecStg ? selecStg.customValue : ""
                                  }`,
                                },
                              });
                            }}
                          >
                            <option value=""></option>
                            {selecStg.map((type) => (
                              <option key={type.value} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </Field>
                        </FormGroup>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <h3>Modulkategorisierung</h3>
                            <FormGroup className="formgroup">
                              <FormLabel className="formgroup" htmlFor="kat">
                                Kat Nr.
                              </FormLabel>
                              <Field
                                type="text"
                                id="small-form"
                                name="kat"
                                className="form-text"
                                onChange={(e) => handleFieldChange(e, handleChange, initialImportDone, dirtyFields, setDirtyFields)}
                              />
                            </FormGroup>

                            <FormGroup className="formgroup">
                              <FormLabel htmlFor="subkat">X.X</FormLabel>
                              <Field
                                type="text"
                                id="name"
                                name="subkat"
                                className="form-text"
                                onChange={(e) => handleFieldChange(e, handleChange, initialImportDone, dirtyFields, setDirtyFields)}
                              />
                            </FormGroup>

                            <FormGroup className="formgroup">
                              <FormLabel htmlFor="s_subkat">X.X.X</FormLabel>
                              <Field
                                type="text"
                                id="name"
                                name="s_subkat"
                                className="form-text"
                                onChange={(e) => handleFieldChange(e, handleChange, initialImportDone, dirtyFields, setDirtyFields)}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <hr></hr>
                            modulnr_gener:
                            <hr></hr>
                            kat_nr: {kat_nr}
                            <hr></hr>
                            subkat_nr: {subkat_nr}
                            <hr></hr>
                            filename: {filename}
                          </Col>
                        </Row>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <h2>Moduldaten</h2>
                  </Accordion.Header>
                  <Accordion.Collapse eventKey="1">
                    <Accordion.Body>
                      <Row>
                        <Col>
                          <FormGroup className="formgroup">
                            <FormLabel htmlFor="ger_modultitel">
                              Modultitel (deutsch)
                            </FormLabel>
                            <Field
                              type="text"
                              id="field_s"
                              name="ger_modultitel"
                              className="form-text"
                              onChange={(e) => {
                                handleChange(e);
                                // Track modified fields after initial import
                                if (initialImportDone) {
                                  setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                }
                              }}
                            />
                          </FormGroup>

                          {values.eng_transcr && (
                            <FormGroup className="formgroup">
                              <FormLabel htmlFor="eng_modultitel">
                                Modultitel (englisch)
                              </FormLabel>
                              <Field
                                type="text"
                                id="field_s"
                                name="eng_modultitel"
                                className="form-text"
                                onChange={(e) => {
                                  handleChange(e);
                                  // Track modified fields after initial import
                                  if (initialImportDone) {
                                    setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                  }
                                }}
                              />
                            </FormGroup>
                          )}

                          <FormGroup className="formgroup">
                            <FormLabel htmlFor="modulnr">
                              Modulnummer (z.B. 1.1.1)
                            </FormLabel>
                            <Field
                              type="text"
                              id="field_s"
                              name="modulnr"
                              className="form-text"
                              onChange={(e) => {
                                handleChange(e);
                                // Track modified fields after initial import
                                if (initialImportDone) {
                                  setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                }
                              }}
                            />
                          </FormGroup>

                          <FormGroup className="formgroup">
                            <FormLabel htmlFor="modulverantwortliche">
                              Modulverantwortliche Person
                            </FormLabel>
                            <Field
                              type="text"
                              id="field_s"
                              name="modulverantwortliche"
                              className="form-text"
                              onChange={(e) => {
                                handleChange(e);
                                // Track modified fields after initial import
                                if (initialImportDone) {
                                  setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                }
                              }}
                            />
                          </FormGroup>

                          <FormGroup className="formgroup">
                            <FormLabel htmlFor="moduldauer">
                              Moduldauer
                            </FormLabel>
                            <Field
                              as="select"
                              id="moduldauer"
                              className="form-selec"
                              name="moduldauer"
                              onChange={(e) => {
                                handleChange(e);
                                // Track modified fields after initial import
                                if (initialImportDone) {
                                  setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                }
                              }}
                            >
                              <option value="" disabled>
                                Moduldauer
                              </option>
                              {moduldauer.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Field>
                          </FormGroup>
                        </Col>

                        <Col>
                          <Row>
                            <Col xs={6}>
                              {" "}
                              {/* Adjust the xs value as needed to control the label width */}
                              <FormLabel htmlFor="ects">ECTS</FormLabel>
                            </Col>
                            <Col xs={4}>
                              <Field
                                type="number"
                                id="field_s"
                                name="ects"
                                className="form-snr"
                                onChange={(e) => {
                                  handleChange(e);
                                  // Track modified fields after initial import
                                  if (initialImportDone) {
                                    setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                  }
                                }}
                              />
                            </Col>

                            <Col xs={6}>
                              {" "}
                              {/* Adjust the xs value as needed to control the label width */}
                              <FormLabel htmlFor="kontaktzeit_sws">
                                Kontaktzeit (SWS){" "}
                              </FormLabel>
                            </Col>
                            <Col xs={4}>
                              <Field
                                type="number"
                                id="field_s"
                                name="kontaktzeit_sws"
                                className="form-snr"
                                onChange={(e) => {
                                  handleChange(e);
                                  // Track modified fields after initial import
                                  if (initialImportDone) {
                                    setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                  }
                                }}
                              />
                            </Col>

                            <Col xs={6}>
                              {" "}
                              {/* Adjust the xs value as needed to control the label width */}
                              <FormLabel htmlFor="kontaktzeit_h">
                                Kontaktzeit (h){" "}
                              </FormLabel>
                            </Col>
                            <Col xs={4}>
                              <Field
                                type="number"
                                id="field_s"
                                className="form-snr"
                                name="kontaktzeit_h"
                                onChange={(e) => {
                                  handleChange(e);
                                  // Track modified fields after initial import
                                  if (initialImportDone) {
                                    setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                  }
                                }}
                              />
                            </Col>

                            <Col xs={6}>
                              {" "}
                              {/* Adjust the xs value as needed to control the label width */}
                              <FormLabel htmlFor="selbstudium_h">
                                Selbststudium (h)
                              </FormLabel>
                            </Col>
                            <Col xs={4}>
                              <Field
                                type="number"
                                id="field_s"
                                className="form-snr"
                                name="selbstudium_h"
                                onChange={(e) => {
                                  handleChange(e);
                                  // Track modified fields after initial import
                                  if (initialImportDone) {
                                    setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                  }
                                }}
                              />
                            </Col>

                            <Col xs={6}>
                              {" "}
                              {/* Adjust the xs value as needed to control the label width */}
                              <FormLabel htmlFor="gesamtarbeitsaufwand_h">
                                Gesamtaufwand (h)
                              </FormLabel>
                            </Col>
                            <Col xs={4}>
                              <Field
                                type="number"
                                id="field_s"
                                name="gesamtarbeitsaufwand_h"
                                className="form-snr"
                                onChange={(e) => {
                                  handleChange(e);
                                  // Track modified fields after initial import
                                  if (initialImportDone) {
                                    setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                  }
                                }}
                              />
                            </Col>
                           
                         <FormGroup className="formgroup">
  <FormLabel htmlFor="gewichtung">Wann sollte das Modul laut Studienverlaufsplan absolviert werden</FormLabel>
  <Field
    as="select"
    id="semester_rec"
    name="semester_rec"
    className="form-selec"
    onChange={(e) => {
      // Access the field's value, convert it to a number, and store it
      const numberValue = Number(e.target.value);
      setFieldValue('semester_rec', numberValue);
      if (initialImportDone) {
        setDirtyFields((prevFields) => [...prevFields, e.target.name]);
      }
    }}
  >
    <option value=""></option>
    {option.map((type) => (
      <option key={type.value} value={type.value}>
        {type.label}
      </option>
    ))}
  </Field>
</FormGroup>

                         

                        
                          </Row>
                        </Col>

                        <Col>
                          <FormGroup className="formgroup">
                            <FormLabel htmlFor="gewichtung">
                              Gewichtung der Note für die Endnote
                            </FormLabel>
                            <Field
                              type="text"
                              id="field_s"
                              name="gewichtung"
                              className="form-text"
                              onChange={(e) => {
                                handleChange(e);
                                // Track modified fields after initial import
                                if (initialImportDone) {
                                  setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                }
                              }}
                            />
                          </FormGroup>

                          <FormGroup className="formgroup">
                            <FormLabel htmlFor="ger_voraussetzungen">
                              Empfohlene Voraussetzungen für die Teilnahme
                            </FormLabel>
                            <Field
                              type="text"
                              id="field_s"
                              name="ger_voraussetzungen"
                              className="form-text"
                              onChange={(e) => {
                                handleChange(e);
                                // Track modified fields after initial import
                                if (initialImportDone) {
                                  setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                }
                              }}
                            />
                          </FormGroup>

                          {values.eng_transcr && (
                            <FormGroup className="formgroup">
                              <FormLabel htmlFor="eng_voraussetzungen">
                                Recommended prerequisites for participation
                              </FormLabel>
                              <Field
                                type="text"
                                id="field_s"
                                name="eng_voraussetzungen"
                                className="form-text"
                                onChange={(e) => {
                                  handleChange(e);
                                  // Track modified fields after initial import
                                  if (initialImportDone) {
                                    setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                  }
                                }}
                              />
                            </FormGroup>
                          )}

                          <FormGroup className="formgroup">
                            <FormLabel htmlFor="ger_voraussetzungen_ects">
                              Voraussetzungen für die Vergabe von ECTS
                            </FormLabel>
                            <Field
                              type="text"
                              id="field_s"
                              name="ger_voraussetzungen_ects"
                              className="form-text"
                              onChange={(e) => {
                                handleChange(e);
                                // Track modified fields after initial import
                                if (initialImportDone) {
                                  setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                }
                              }}
                            />
                          </FormGroup>

                          {values.eng_transcr && (
                            <FormGroup className="formgroup">
                              <FormLabel htmlFor="eng_voraussetzungen_ects">
                                Recommended prerequisites ECTS
                              </FormLabel>
                              <Field
                                type="text"
                                id="field_s"
                                name="eng_voraussetzungen_ects"
                                className="form-text"
                                onChange={(e) => {
                                  handleChange(e);
                                  // Track modified fields after initial import
                                  if (initialImportDone) {
                                    setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                  }
                                }}
                              />
                            </FormGroup>
                          )}

                          <FormGroup className="formgroup">
                            <FormLabel htmlFor="ger_studiengangsempfehlung">
                              Studiengangsempfehlung
                            </FormLabel>
                            <Field
                              type="text"
                              id="field_s"
                              name="ger_studiengangsempfehlung"
                              className="form-text"
                              onChange={(e) => {
                                handleChange(e);
                                // Track modified fields after initial import
                                if (initialImportDone) {
                                  setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                }
                              }}
                            />
                          </FormGroup>

                          {values.eng_transcr && (
                            <FormGroup className="formgroup">
                              <FormLabel htmlFor="eng_studiengangsempfehlung">
                                Study program recommendation
                              </FormLabel>
                              <Field
                                type="text"
                                id="field_s"
                                name="eng_studiengangsempfehlung"
                                className="form-text"
                                onChange={(e) => {
                                  handleChange(e);
                                  // Track modified fields after initial import
                                  if (initialImportDone) {
                                    setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                  }
                                }}
                              />
                            </FormGroup>
                          )}
                        </Col>

                        <Col>
                          <div className="btn-descr-enc">
                            <FormLabel
                              htmlFor="wahlpflichtfach"
                              className="btn-dscr-eng-enab"
                            ></FormLabel>
                            <label className="btn-checkb-checkbox">
                              <Field
                                type="checkbox"
                                name="wahlpflichtfach"
                                className="hidden-checkbox"
                                id="eng_transcr" // Added id for association with the label
                                onChange={(e) => {
                                  handleChange(e);
                                  // Track modified fields after initial import
                                  if (initialImportDone) {
                                    setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                  }
                                }}
                              />
                              <div className="btn-checkb">
                                Wahlpflichtfach
                              </div>
                            </label>
                          </div>

                          <div className="btn-descr-enc">
                            <FormLabel
                              htmlFor="pflichtfach"
                              className="btn-dscr-eng-enab"
                            ></FormLabel>
                            <label className="btn-checkb-checkbox">
                              <Field
                                type="checkbox"
                                name="pflichtfach"
                                className="hidden-checkbox"
                                id="eng_transcr" // Added id for association with the label
                                onChange={(e) => {
                                  handleChange(e);
                                  // Track modified fields after initial import
                                  if (initialImportDone) {
                                    setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                  }
                                }}
                              />
                              <div className="btn-checkb">Pflichtfach</div>
                            </label>
                          </div>

                          <p>Wann findet der Kurs statt</p>

                          <Row>
                            <Col>
                              <div className="btn-descr-enc">
                                <FormLabel
                                  htmlFor="sose_kurs"
                                  className="btn-dscr-eng-enab"
                                ></FormLabel>
                                <label className="btn-checkb-checkbox">
                                  <Field
                                    type="checkbox"
                                    name="sose_kurs"
                                    className="hidden-checkbox"
                                    id="eng_transcr" // Added id for association with the label
                                    onChange={(e) => {
                                      handleChange(e);
                                      // Track modified fields after initial import
                                      if (initialImportDone) {
                                        setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                      }
                                    }}
                                  />
                                  <div className="btn-checkb">SoSe</div>
                                </label>
                              </div>
                            </Col>
                            <Col>
                              <div className="btn-descr-enc">
                                <FormLabel
                                  htmlFor="wise_kurs"
                                  className="btn-dscr-eng-enab"
                                ></FormLabel>
                                <label className="btn-checkb-checkbox">
                                  <Field
                                    type="checkbox"
                                    name="wise_kurs"
                                    className="hidden-checkbox"
                                    id="eng_transcr" // Added id for association with the label
                                    onChange={(e) => {
                                      handleChange(e);
                                      // Track modified fields after initial import
                                      if (initialImportDone) {
                                        setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                      }
                                    }}
                                  />
                                  <div className="btn-checkb">WiSe</div>
                                </label>
                              </div>
                            </Col>
                          </Row>

                         
                          <hr></hr>
                          <FormGroup className="formgroup">
                            <FormLabel htmlFor="ger_haeufigkeit">
                              Häufigkeit
                            </FormLabel>
                            <Field
                              as="select"
                              id="ger_haeufigkeit"
                              name="ger_haeufigkeit"
                              className="form-selec"
                              onChange={(e) => {
                                handleChange(e);
                                // Track modified fields after initial import
                                if (initialImportDone) {
                                  setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                }
                              }}
                            >
                              {haeufigkeit.map((type) => (
                                <option key={type.value} value={type.value}>
                                  {type.label}
                                </option>
                              ))}
                            </Field>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Collapse>
                </Accordion.Item>
              </Accordion>

              <Accordion>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <h2>Verwendbarkeit des Moduls</h2>
                  </Accordion.Header>
                  <Accordion.Body>
                    <FieldArray name="verwendbarMod">
                      {({ push, remove }) => (
                        <div>
                          {values.verwendbarMod &&
                            values.verwendbarMod.map((item, index) => (
                              <Card
                                key={`verwendbarMod${index}`}
                                style={{
                                  margin: "0px",
                                  padding: "0px",
                                  border: "0px",
                                  borderBottom: "2px solid black",
                                }}
                              >
                                <Row
                                  className="d-flex align-items-center"
                                  style={{ margin: "0px", padding: "0px" }}
                                >
                                  <Col xs={2} md={1}>
                                    <div style={{ marginTop: "auto" }}>
                                      <Button
                                        variant="primary"
                                        onClick={() => push({ title: "" })}
                                        style={{ marginRight: "10px" }}
                                        className="btn-add"
                                      >
                                        +
                                      </Button>
                                      <Button
                                        variant="danger"
                                        onClick={() => remove(index)}
                                        className="btn-remove"
                                      >
                                        -
                                      </Button>
                                    </div>
                                  </Col>

                                  <Col xs={2} md={2}>
                                    <Field
                                      as="select"
                                      id="selecStgType"
                                      name={`verwendbarMod.${index}.selecStgType`}
                                      className="form-selec"
                                      onChange={(e) => {
                                        const selectedType = selecStgType.find(
                                          (type) =>
                                            type.value === e.target.value
                                        );
                                        console.log(kat_nr);
                                        handleChange(e);
                                        handleChange({
                                          target: {
                                            name: "combinedValue",
                                            value: `${
                                              selectedType
                                                ? selectedType.customValue
                                                : ""
                                            }`,
                                          },
                                        });
                                        if (initialImportDone) {
                                          setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                        }
                                      }}
                                    >
                                      {selecStgType.map((type) => (
                                        <option
                                          key={type.value}
                                          value={type.value}
                                        >
                                          {type.label}
                                        </option>
                                      ))}
                                    </Field>
                                  </Col>

                                  <Col xs={12} md={4}>
                                    {/* selecStgType / Select Studiengang */}
                                    <Field
                                      as="select"
                                      id="selecStg"
                                      name={`verwendbarMod.${index}.selecStg`}
                                      className="form-selec"
                                      onChange={(e) => {
                                        handleChange(e);
                                        // Track modified fields after initial import
                                        if (initialImportDone) {
                                          setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                        }
                                      }}
                                    >
                                      {selecStg.map((type) => (
                                        <option
                                          key={type.value}
                                          value={type.value}
                                        >
                                          {type.label}
                                        </option>
                                      ))}
                                    </Field>
                                  </Col>

                                  <Col>
                                    <div className="btn-descr-enc">
                                      <FormLabel
                                        htmlFor={`verwendbarMod.${index}.wahlpflichtfach`}
                                        className="btn-dscr-eng-enab"
                                      ></FormLabel>
                                      <label className="btn-checkb-checkbox">
                                        <Field
                                          type="checkbox"
                                          name={`verwendbarMod.${index}.wahlpflichtfach`}
                                          className="hidden-checkbox"
                                          id="eng_transcr" // Added id for association with the label
                                          onChange={(e) => {
                                            handleChange(e);
                                            // Track modified fields after initial import
                                            if (initialImportDone) {
                                              setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                            }
                                          }}
                                        />
                                        <div className="btn-checkb">
                                          Wahlpflichtfach
                                        </div>
                                      </label>
                                    </div>
                                  </Col>

                                  <Col>
                                    <div className="btn-descr-enc">
                                      <FormLabel
                                        htmlFor={`verwendbarMod.${index}.pflichtfach`}
                                        className="btn-dscr-eng-enab"
                                      ></FormLabel>
                                      <label className="btn-checkb-checkbox">
                                        <Field
                                          type="checkbox"
                                          name={`verwendbarMod.${index}.pflichtfach`}
                                          className="hidden-checkbox"
                                          id="eng_transcr" // Added id for association with the label
                                          onChange={(e) => {
                                            handleChange(e);
                                            // Track modified fields after initial import
                                            if (initialImportDone) {
                                              setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                            }
                                          }}
                                        />
                                        <div className="btn-checkb">
                                          Pflichtfach
                                        </div>
                                      </label>
                                    </div>
                                  </Col>
                                </Row>
                              </Card>
                            ))}
                        </div>
                      )}
                    </FieldArray>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <h2>Lehrveranstaltungen</h2>
                  </Accordion.Header>

                  <Accordion.Body>
                    <Row>
                      <Col>
                        <FieldArray name="lehrveranstaltungen">
                          {({ push, remove }) => (
                            <div>
                              {values.lehrveranstaltungen.map(
                                (lehrveranstaltungen, lehrIndex) => (
                                  <Card
                                    key={`lehrveranstaltungen_${lehrIndex}`}
                                    style={{
                                      margin: "10px",
                                      padding: "10px",
                                      border: "0px",
                                      borderBottom: "2px solid black",
                                    }}
                                  >
                                    <Row>
                                      <Col
                                        xs={1}
                                        md={2}
                                        style={{
                                          border: "0px solid black",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <h2>
                                          <h2>
                                            {
                                              values.lehrveranstaltungen[
                                                lehrIndex
                                              ].ger_title
                                            }
                                          </h2>
                                        </h2>
                                        <div style={{ marginTop: "auto" }}>
                                          <Button
                                            className="btn-add"
                                            variant="primary"
                                            onClick={() => push({})}
                                            style={{ marginRight: "10px" }}
                                          >
                                            +
                                          </Button>
                                          <Button
                                            variant="danger"
                                            onClick={() => remove(lehrIndex)}
                                            className="btn-remove"
                                          >
                                            -
                                          </Button>
                                        </div>
                                      </Col>
                                      <Col xs={12} md={10}>
                                        {/* Use the Field component to render each item in the array */}

                                        <Accordion flush>
                                          <Accordion.Item eventKey="0">
                                            <Accordion.Header className="lehrver">
                                              <h3>Allgemeine Angaben</h3>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <Row>
                                                <Col>
                                                  <FormGroup className="formgroup">
                                                    <FormLabel htmlFor="lehrveranstaltungen">
                                                      <h3>
                                                        Titel der
                                                        Lehrveranstaltung
                                                      </h3>
                                                    </FormLabel>
                                                    <Field
                                                      name={`lehrveranstaltungen.${lehrIndex}.ger_title`}
                                                      className="form-text"
                                                      onChange={(e) => {
                                                        handleChange(e);
                                                        // Track modified fields after initial import
                                                        if (initialImportDone) {
                                                          setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                        }
                                                      }}
                                                    />
                                                    {values.eng_transcr && (
                                                      <FormGroup className="formgroup">
                                                        <FormLabel>
                                                          Englischer Titel der
                                                          Lehrveranstaltung
                                                        </FormLabel>
                                                        <Field
                                                          name={`lehrveranstaltungen.${lehrIndex}.eng_title`}
                                                          className="form-text"
                                                          onChange={(e) => {
                                                            handleChange(e);
                                                            // Track modified fields after initial import
                                                            if (initialImportDone) {
                                                              setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                            }
                                                          }}
                                                        />
                                                      </FormGroup>
                                                    )}
                                                  </FormGroup>

                                                  <FormLabel>
                                                    In welchem Semester findet
                                                    der Kurs statt?
                                                  </FormLabel>

                                                  <Row>
                                                    <Col>
                                                      {leftOptions.map(
                                                        (option, index) => (
                                                          <MultiSelect
                                                            key={index} // Ensure a unique key for each element
                                                            name={`lehrveranstaltungen.${lehrIndex}.semester`}
                                                            label={option.label}
                                                            value={option.value}
                                                            onChange={(e) => {
                                                              handleChange(e);
                                                              // Track modified fields after initial import
                                                              if (initialImportDone) {
                                                                setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                              }
                                                            }}
                                                          />
                                                        )
                                                      )}
                                                    </Col>

                                                    <Col>
                                                      {rightOptions.map(
                                                        (option, index) => (
                                                          <MultiSelect
                                                            key={index} // Ensure a unique key for each element
                                                            name={`lehrveranstaltungen.${lehrIndex}.semester`}
                                                            label={option.label}
                                                            value={option.value}
                                                            onChange={(e) => {
                                                              handleChange(e);
                                                              // Track modified fields after initial import
                                                              if (initialImportDone) {
                                                                setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                              }
                                                            }}
                                                          />
                                                        )
                                                      )}
                                                    </Col>
                                                  </Row>
                                                </Col>
                                                <Col>
                                                  <Row className="nrblock">
                                                    <Col xs={6}>
                                                      {" "}
                                                      {/* Adjust the xs value as needed to control the label width */}
                                                      <FormLabel htmlFor="kontaktzeit_sws">
                                                        Kontaktzeit (SWS)
                                                      </FormLabel>
                                                    </Col>
                                                    <Col xs={4}>
                                                      <Field
                                                        type="number"
                                                        id="field_s"
                                                        name={`lehrveranstaltungen.${lehrIndex}.kontaktzeit_sws`}
                                                        className="form-snr"
                                                        onChange={(e) => {
                                                          handleChange(e);
                                                          // Track modified fields after initial import
                                                          if (initialImportDone) {
                                                            setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                          }
                                                        }}
                                                      />
                                                    </Col>

                                                    <Col xs={6}>
                                                      {" "}
                                                      {/* Adjust the xs value as needed to control the label width */}
                                                      <FormLabel htmlFor="kontaktzeit_h">
                                                        Kontaktzeit (h)
                                                      </FormLabel>
                                                    </Col>
                                                    <Col xs={4}>
                                                      <Field
                                                        type="number"
                                                        id="field_s"
                                                        name={`lehrveranstaltungen.${lehrIndex}.kontaktzeit_h`}
                                                        className="form-snr"
                                                        onChange={(e) => {
                                                          handleChange(e);
                                                          // Track modified fields after initial import
                                                          if (initialImportDone) {
                                                            setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                          }
                                                        }}
                                                      />
                                                    </Col>

                                                    <Col xs={6}>
                                                      {" "}
                                                      {/* Adjust the xs value as needed to control the label width */}
                                                      <FormLabel htmlFor="selbstudium_h">
                                                        Selbststudium (h)
                                                      </FormLabel>
                                                    </Col>
                                                    <Col xs={4}>
                                                      <Field
                                                        type="number"
                                                        id="field_s"
                                                        name={`lehrveranstaltungen.${lehrIndex}.selbstudium_h`}
                                                        className="form-snr"
                                                        onChange={(e) => {
                                                          handleChange(e);
                                                          // Track modified fields after initial import
                                                          if (initialImportDone) {
                                                            setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                          }
                                                        }}
                                                      />
                                                    </Col>

                                                    <Col xs={6}>
                                                      {" "}
                                                      {/* Adjust the xs value as needed to control the label width */}
                                                      <FormLabel htmlFor="gesamtaufwand">
                                                        Gesamtaufwand der
                                                        Lehrveranstaltung
                                                      </FormLabel>
                                                    </Col>
                                                    <Col xs={4}>
                                                      <Field
                                                        type="number"
                                                        id="field_s"
                                                        name={`lehrveranstaltungen.${lehrIndex}.gesamtaufwand`}
                                                        className="form-snr"
                                                        onChange={(e) => {
                                                          handleChange(e);
                                                          // Track modified fields after initial import
                                                          if (initialImportDone) {
                                                            setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                          }
                                                        }}
                                                      />
                                                    </Col>

                                                    <Col xs={6}>
                                                      {" "}
                                                      {/* Adjust the xs value as needed to control the label width */}
                                                      <FormLabel htmlFor="maxanzahl_stud">
                                                        Maximale Anzahl
                                                        Studierende
                                                      </FormLabel>
                                                    </Col>
                                                    <Col xs={4}>
                                                      <Field
                                                        type="number"
                                                        id="field_s"
                                                        name={`lehrveranstaltungen.${lehrIndex}.maxanzahl_stud`}
                                                        className="form-snr"
                                                        onChange={(e) => {
                                                          handleChange(e);
                                                          // Track modified fields after initial import
                                                          if (initialImportDone) {
                                                            setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                          }
                                                        }}
                                                      />
                                                    </Col>
                                                    <hr></hr>
                                                    <Row xs={1} md={1}>
                                                      <Col>
                                                        <FormGroup className="formgroup">
                                                          <FormLabel
                                                            htmlFor="lehrende"
                                                            style={{
                                                              textAlign: "left",
                                                              marginLeft: "0px",
                                                              paddingLeft:
                                                                "0px",
                                                            }}
                                                          >
                                                            <h3>Modus:</h3>
                                                            <p>
                                                              z.B. Pflicht- oder
                                                              Studienleistung
                                                            </p>
                                                          </FormLabel>

                                                          <Field
                                                            as="select"
                                                            id="difficulty"
                                                            name={`lehrveranstaltungen.${lehrIndex}.modus`}
                                                            className="form-selec"
                                                            onChange={(e) => {
                                                              handleChange(e);
                                                              // Track modified fields after initial import
                                                              if (initialImportDone) {
                                                                setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                              }
                                                            }}
                                                          >
                                                            <option
                                                              value=""
                                                              disabled
                                                            >
                                                            Modus auswählen
                                                            </option>
                                                            {modus.map(
                                                              (option) => (
                                                                <option
                                                                  key={
                                                                    option.value
                                                                  }
                                                                  value={
                                                                    option.value
                                                                  }
                                                                >
                                                                  {option.label}
                                                                </option>
                                                              )
                                                            )}
                                                          </Field>
                                                        </FormGroup>
                                                      </Col>
                                                    </Row>
                                                  </Row>
                                                </Col>

                                                <Col>
                                                  

                                                  <FormGroup className="formgroup">
                                                    <FormLabel htmlFor="lehrende">
                                                      <h3>Lehrende</h3>
                                                    </FormLabel>
                                                    <Field
                                                      name={`lehrveranstaltungen.${lehrIndex}.lehrende`}
                                                      className="form-text"
                                                      onChange={(e) => {
                                                        handleChange(e);
                                                        // Track modified fields after initial import
                                                        if (initialImportDone) {
                                                          setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                        }
                                                      }}
                                                    />
                                                  </FormGroup>

                                                  <FormGroup>
                                                    {/* <FieldArray name="lehrende">
                                                                                                            {({ push, remove }) => (
                                                                                                                <div>
                                                                                                                    {values.lehrende.map(
                                                                                                                        (lehr, lehrende, lehrindex) => (
                                                                                                                            <Row key={`lehrende_${lehrende}_${lehr}`} className="mb-3" >
                                                                                                                                <Col xs={9}>
                                                                                                                                    <Field name={`lehrende.${lehrende}.title`} className="form-text" />
                                                                                                                                </Col>
                                                                                                                                <Col
                                                                                                                                    xs={1} style={{ marginRight: "10px", }}>
                                                                                                                                    <Button
                                                                                                                                        variant="danger" onClick={() => remove(lehrende)}>
                                                                                                                                        Remove
                                                                                                                                    </Button>
                                                                                                                                </Col>

                                                                                                                                <Col xs={1}>
                                                                                                                                    {lehrende ===
                                                                                                                                        values
                                                                                                                                            .lehrende
                                                                                                                                            .length -
                                                                                                                                        1 && (
                                                                                                                                            <Button
                                                                                                                                                variant="primary"
                                                                                                                                                onClick={() =>
                                                                                                                                                    push({
                                                                                                                                                        title:
                                                                                                                                                            "",
                                                                                                                                                    })
                                                                                                                                                }
                                                                                                                                            >
                                                                                                                                                Add
                                                                                                                                            </Button>
                                                                                                                                        )}
                                                                                                                                </Col>
                                                                                                                            </Row>
                                                                                                                        )
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </FieldArray> */}
                                                  </FormGroup>
                                                </Col>
                                              </Row>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>


                                        <Accordion flush>
                                          <Accordion.Item eventKey="lehrofrm">
                                            <Accordion.Header className="lehrver">
                                              <h3>Lehrformen</h3>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                            <FormGroup className="formgroup">
                                                    <Row>
                                                      <h3>Lehrform</h3>
                                                      <Col>
                                                        {leftlehrform.map(
                                                          (option, index) => (
                                                            <MultiSelect
                                                              key={index} // Ensure a unique key for each element
                                                              name={`lehrveranstaltungen.${lehrIndex}.lehrform`}
                                                              label={
                                                                option.label
                                                              }
                                                              value={
                                                                option.value
                                                              }
                                                              onChange={(e) => {
                                                                handleChange(e);
                                                                // Track modified fields after initial import
                                                                if (initialImportDone) {
                                                                  setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                                }
                                                              }}
                                                            />
                                                          )
                                                        )}
                                                      </Col>

                                                      <Col>
                                                        {rightlehrform.map(
                                                          (option, index) => (
                                                            <MultiSelect
                                                              key={index} // Ensure a unique key for each element
                                                              name={`lehrveranstaltungen.${lehrIndex}.lehrform`}
                                                              label={
                                                                option.label
                                                              }
                                                              value={
                                                                option.value
                                                              }
                                                              onChange={(e) => {
                                                                handleChange(e);
                                                                // Track modified fields after initial import
                                                                if (initialImportDone) {
                                                                  setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                                }
                                                              }}
                                                            />
                                                          )
                                                        )}
                                                      </Col>
                                                    </Row>
                                                  </FormGroup>
                                            </Accordion.Body>
                                          </Accordion.Item>


                                        </Accordion>

                                        <Accordion flush>
                                          <Accordion.Item eventKey="0">
                                            <Accordion.Header className="lehrver">
                                              <h3>Prüfungsformen</h3>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <Row>
                                                <Col>
                                                  <FormLabel>
                                                    Wählen Sie die Prüfungsform
                                                    für die Lehrveranstaltung
                                                  </FormLabel>

                                                  <Row>
                                                    <div
                                                      style={{
                                                        columns: 3,
                                                        columnGap: "20px",
                                                        verticalAlign: "top",
                                                      }}
                                                    >
                                                      {pruefungsform.map(
                                                        (option, index) => (
                                                          <div
                                                            style={{
                                                              marginBottom:
                                                                "10px",
                                                              breakInside:
                                                                "avoid",
                                                            }}
                                                            key={index}
                                                          >
                                                            <MultiSelect
                                                              name={`lehrveranstaltungen.${lehrIndex}.pruefungsformen`}
                                                              label={
                                                                option.label
                                                              }
                                                              value={
                                                                option.value
                                                              }
                                                              onChange={(e) => {
                                                                handleChange(e);
                                                                // Track modified fields after initial import
                                                                if (initialImportDone) {
                                                                  setDirtyFields((prevFields) => [...prevFields, e.target.name]);
                                                                }
                                                              }}
                                                            />
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                        </Accordion>

                                        {/* Add other fields as needed */}
                                        <Tabs
                                          defaultActiveKey="inhalte"
                                          id="uncontrolled-tab-example"
                                          className="mb-3"
                                        >
                                          <Tab
                                            eventKey="inhalte"
                                            title="Inhalte"
                                          >
                                            <QuillEditor
                                              value={
                                                lehrveranstaltungen.ger_inhalte
                                              } // Use ger_inhalte instead of description
                                              onChange={(html) => {
                                                const updatedEntries = [
                                                  ...values.lehrveranstaltungen,
                                                ];
                                                updatedEntries[
                                                  lehrIndex
                                                ].ger_inhalte = html;
                                                setFieldValue(
                                                  "lehrveranstaltungen",
                                                  updatedEntries
                                                );
                                              }}
                                              fieldname={`lehrveranstaltungen[${lehrIndex}].ger_inhalte`} // Use ger_inhalte instead of description
                                            />

                                            {values.eng_transcr && (
                                              <div className="engCont">
                                                <h3>Englische Inhalte</h3>
                                                <QuillEditor
                                                  value={
                                                    lehrveranstaltungen.eng_inhalte
                                                  } // Use ger_inhalte instead of description
                                                  onChange={(html) => {
                                                    const updatedEntries = [
                                                      ...values.lehrveranstaltungen,
                                                    ];
                                                    updatedEntries[
                                                      lehrIndex
                                                    ].eng_inhalte = html;
                                                    setFieldValue(
                                                      "lehrveranstaltungen",
                                                      updatedEntries
                                                    );
                                                  }}
                                                  fieldname={`lehrveranstaltungen[${lehrIndex}].eng_inhalte`} // Use ger_inhalte instead of description
                                                />
                                              </div>
                                            )}
                                          </Tab>

                                          <Tab
                                            eventKey="kompetenzziele"
                                            title="Kompetenzziele"
                                          >
                                            <QuillEditor
                                              value={
                                                lehrveranstaltungen.ger_kompetenzziele
                                              } // Use ger_kompetenzziele instead of description
                                              onChange={(html) => {
                                                const updatedEntries = [
                                                  ...values.lehrveranstaltungen,
                                                ];
                                                updatedEntries[
                                                  lehrIndex
                                                ].ger_kompetenzziele = html;
                                                setFieldValue(
                                                  "lehrveranstaltungen",
                                                  updatedEntries
                                                );
                                              }}
                                              fieldname={`lehrveranstaltungen[${lehrIndex}].ger_kompetenzziele`} // Use ger_kompetenzziele instead of description
                                            />

                                            {values.eng_transcr && (
                                              <div className="engCont">
                                                <h3>
                                                  Englische Kompetenzziele
                                                </h3>
                                                <QuillEditor
                                                  value={
                                                    lehrveranstaltungen.eng_kompetenzziele
                                                  } // Use ger_inhalte instead of description
                                                  onChange={(html) => {
                                                    const updatedEntries = [
                                                      ...values.lehrveranstaltungen,
                                                    ];
                                                    updatedEntries[
                                                      lehrIndex
                                                    ].eng_kompetenzziele = html;
                                                    setFieldValue(
                                                      "lehrveranstaltungen",
                                                      updatedEntries
                                                    );
                                                  }}
                                                  fieldname={`lehrveranstaltungen[${lehrIndex}].eng_kompetenzziele`} // Use ger_inhalte instead of description
                                                />
                                              </div>
                                            )}
                                          </Tab>
                                          <Tab
                                            eventKey="literaturangaben"
                                            title="Literaturangaben"
                                          >
                                            <QuillEditor
                                              value={
                                                lehrveranstaltungen.literaturangaben
                                              } // Use ger_kompetenzziele instead of description
                                              onChange={(html) => {
                                                const updatedEntries = [
                                                  ...values.lehrveranstaltungen,
                                                ];
                                                updatedEntries[
                                                  lehrIndex
                                                ].literaturangaben = html;
                                                setFieldValue(
                                                  "lehrveranstaltungen",
                                                  updatedEntries
                                                );
                                              }}
                                              fieldname={`lehrveranstaltungen[${lehrIndex}].literaturangaben`} // Use ger_kompetenzziele instead of description
                                            />
                                          </Tab>
                                        </Tabs>
                                      </Col>
                                    </Row>
                                  </Card>
                                )
                              )}
                            </div>
                          )}
                        </FieldArray>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    <h2>Angaben Erasmusstudierende</h2>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col>
                        <Field
                          as="select"
                          id="difficulty"
                          name="difficulty"
                          className="form-selec"
                        >
                          <option value="" disabled>
                            Schwierigkeitsgrad auswählen
                          </option>
                          {difficulty.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>

                        <FormLabel htmlFor="language">
                          Unterstützte Sprachen
                        </FormLabel>
                        <Field
                          type="text"
                          id="name"
                          name="language"
                          className="form-text"
                        />
                      </Col>

                      <Col>
                        <div className="btn-descr-enc">
                          <FormLabel
                            htmlFor="for_exchange"
                            className="btn-dscr-eng-enab"
                          ></FormLabel>
                          <label className="btn-checkb-checkbox">
                            <Field
                              defaultValue={true}
                              type="checkbox"
                              name="for_exchange"
                              className="hidden-checkbox"
                              id="eng_transcr" // Added id for association with the label
                            />
                            <div className="btn-checkb">
                              Dieser Kurs ist für Austauschstudierende geeignet
                            </div>
                          </label>
                        </div>

                        {/* Conditionally render the "notforexch" field only if "for_exchange" is false */}
                        {!values.for_exchange && (
                          <div>
                            <FormLabel htmlFor="notforexch">
                              Bitte beschreiben Sie kurz warum das Modul nicht
                              für Austauschstudierende geeignet ist.
                            </FormLabel>
                            <textarea
                              id="notforexch"
                              name="notforexch"
                              rows={4}
                              style={{ width: "100%" }}
                              value={values.notforexch}
                            />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h2>QM</h2>
                  </Accordion.Header>

                  <Accordion.Body>
                    <Row style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px', backgroundColor: '#f5f5f5' }}>
                    <h3>Änderungshistorie:</h3>
                      {importedQmData && (
                        <>
                          <Col >
                            <div >
                              <p>Änderungsdatum: {importedQmData.changedDate}</p>
                            </div>
                          </Col>
                          <Col>
                            <p>Veränderte Felder: {importedQmData.changedFields.join(', ')}</p>
                          </Col>
                          <hr></hr>
                        </>)}

                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Button
                style={{ padding: "20px", fontWeight: "600", fontSize: "20px" }}
                variant="primary"
                type="button"
                className="custom-button"
                onClick={() => handleSubmit(values, setValues)}
              >
                Modul exportieren
              </Button>
            </Form>
          );
        })()
      }
    </Formik>
  );
};

export default MDInput;
