import ReactDOMServer from 'react-dom/server';

export async function fetchAndProcessTemplate(templateKey, templates, values, zip, ZIPfilename, TemplateInsta, TemplateYoutube, formatHtml) {
  const templatePath = templates[templateKey];

  // Determine which React template to use
  let TemplateComponent;
  if (templatePath === "react-insta-template") {
    console.log(`Using React Insta template for: ${templateKey}`);
    TemplateComponent = TemplateInsta;
  } else if (templatePath === "react-youtube-template") {
    console.log(`Using React YouTube template for: ${templateKey}`);
    TemplateComponent = TemplateYoutube;
  }

  // Check if a valid template was found
  if (TemplateComponent) {
    // Render the React component to static HTML
    const htmlContent = ReactDOMServer.renderToStaticMarkup(<TemplateComponent values={values} />);

    // Format to plain text if necessary
    const formattedTemplate = formatHtml ? formatHtml(htmlContent) : htmlContent;

    // Add the processed template to the ZIP file
    zip.file(`${ZIPfilename}_${templateKey}.txt`, String(formattedTemplate));
  } else {
    console.error(`No React template found for ${templateKey}.`);
  }
}
