import React from 'react';
import { FormGroup, Accordion } from 'react-bootstrap';

const AccordionSub = ({ Infotitle, Hx = "h3", Infodescr, children, ...props }) => {
    // Check if there is any content to display in the Accordion.Body
    const hasContent = Infodescr || React.Children.count(children) > 0;
    // Set a class to control the styling and functionality based on content availability
    const accordionClass = hasContent ? '' : 'accordion-no-content';

    // Dynamically render the heading component
    const Heading = Hx;

    return (
        <FormGroup>
            <Accordion flush className={`accsub ${accordionClass}`}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        {/* Dynamically render the heading level based on Hx */}
                        <Heading>{Infotitle}</Heading>
                    </Accordion.Header>
                    <Accordion.Body>
                        {children}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </FormGroup>
    );
};

export default AccordionSub;
