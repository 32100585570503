export const selecModMap = {
  "AR/b6/BAR-EX.json": {
    "filename": "AR/b6/BAR-EX.json",
    "ger_modultitel": "Exkursion",
    "selecStg": "AR",
    "modulnr": "BAR EX",
    "lehrende": [
      ""
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR-PM-1.json": {
    "filename": "AR/b6/BAR-PM-1.json",
    "ger_modultitel": "Fachbereich Gestaltung Interdisziplinäre Projektwoche",
    "selecStg": "AR",
    "modulnr": "BAR PM 1",
    "lehrende": [
      "variabel; Professor*innen und Lehrende des Fachbereichs Gestaltung"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR-PM-2.json": {
    "filename": "AR/b6/BAR-PM-2.json",
    "ger_modultitel": "Fachbereich Gestaltung Interdisziplinäre Projektwoche",
    "selecStg": "AR",
    "modulnr": "BAR PM 2",
    "lehrende": [
      "variabel; Professor*innen und Lehrende des Fachbereichs Gestaltung"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR1-1.json": {
    "filename": "AR/b6/BAR1-1.json",
    "ger_modultitel": "Entwerfen I",
    "selecStg": "AR",
    "modulnr": "BAR 1.1",
    "lehrende": [
      "Prof. Andrea Wandel"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR1-2.json": {
    "filename": "AR/b6/BAR1-2.json",
    "ger_modultitel": "Entwerfen II - Basisprojekt",
    "selecStg": "AR",
    "modulnr": "BAR 1.2",
    "lehrende": [
      "variabel"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR1-3.json": {
    "filename": "AR/b6/BAR1-3.json",
    "ger_modultitel": "Entwerfen III - Kleines gebäude",
    "selecStg": "AR",
    "modulnr": "BAR 1.3",
    "lehrende": [
      "variabel"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR1-4.json": {
    "filename": "AR/b6/BAR1-4.json",
    "ger_modultitel": "Entwerfen im Ensemble",
    "selecStg": "AR",
    "modulnr": "BAR 1.4",
    "lehrende": [
      "Prof. Marion Goerdt",
      "Prof. Robert Thum"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR1-5.json": {
    "filename": "AR/b6/BAR1-5.json",
    "ger_modultitel": "Großes Gebäude",
    "selecStg": "AR",
    "modulnr": "BAR 1.5",
    "lehrende": [
      "variabel"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR1-6-1.json": {
    "filename": "AR/b6/BAR1-6-1.json",
    "ger_modultitel": "Bachelorthesis",
    "selecStg": "AR",
    "modulnr": "BAR 1.6.1",
    "lehrende": [
      "variabel, Lehrende der Architektur"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR1-6-2.json": {
    "filename": "AR/b6/BAR1-6-2.json",
    "ger_modultitel": "Kolloquium",
    "selecStg": "AR",
    "modulnr": "BAR 1.6.2",
    "lehrende": [
      "variabel, Lehrende der Architektur"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR2-1.json": {
    "filename": "AR/b6/BAR2-1.json",
    "ger_modultitel": "Tragswerklehre I- Grundlagen",
    "selecStg": "AR",
    "modulnr": "BAR 2.1",
    "lehrende": [
      "N.N."
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR2-2.json": {
    "filename": "AR/b6/BAR2-2.json",
    "ger_modultitel": "Tragswerklehre II - Materialtechnologie",
    "selecStg": "AR",
    "modulnr": "BAR 2.2",
    "lehrende": [
      "N.N."
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR2-3.json": {
    "filename": "AR/b6/BAR2-3.json",
    "ger_modultitel": "Digitale Darstellungsformen",
    "selecStg": "AR",
    "modulnr": "BAR 2.3",
    "lehrende": [
      "Prof. Dr. Robert Thum"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR2-4.json": {
    "filename": "AR/b6/BAR2-4.json",
    "ger_modultitel": "Geäudelehre I",
    "selecStg": "AR",
    "modulnr": "BAR 2.4",
    "lehrende": [
      "Prof. Jan-Henrik Hafke"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR2-5.json": {
    "filename": "AR/b6/BAR2-5.json",
    "ger_modultitel": "Geäudelehre II",
    "selecStg": "AR",
    "modulnr": "BAR 2.5",
    "lehrende": [
      "Prof. Jan-Henrik Hafke"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR2-6.json": {
    "filename": "AR/b6/BAR2-6.json",
    "ger_modultitel": "Bachelorthesis-Seminar",
    "selecStg": "AR",
    "modulnr": "BAR 2.6",
    "lehrende": [
      "alternierend"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR3-1.json": {
    "filename": "AR/b6/BAR3-1.json",
    "ger_modultitel": "Konstruieren I",
    "selecStg": "AR",
    "modulnr": "BAR 3.1",
    "lehrende": [
      "N.N.",
      "Prof. Andrea Wandel"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR3-2.json": {
    "filename": "AR/b6/BAR3-2.json",
    "ger_modultitel": "Konstruieren II",
    "selecStg": "AR",
    "modulnr": "BAR 3.2",
    "lehrende": [
      "N.N.",
      "Prof. Andrea Wandel"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR3-3.json": {
    "filename": "AR/b6/BAR3-3.json",
    "ger_modultitel": "Konstruieren III",
    "selecStg": "AR",
    "modulnr": "BAR 3.3",
    "lehrende": [
      "Prof. Dr. Becker"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR3-4.json": {
    "filename": "AR/b6/BAR3-4.json",
    "ger_modultitel": "Konstruieren IV - Bauphysik",
    "selecStg": "AR",
    "modulnr": "BAR 3.4",
    "lehrende": [
      "Prof. Dr. Becker"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR3-5.json": {
    "filename": "AR/b6/BAR3-5.json",
    "ger_modultitel": "Konstruieren V",
    "selecStg": "AR",
    "modulnr": "BAR 3.5",
    "lehrende": [
      "Prof. Dr. Matthias Sieveke"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR3-6.json": {
    "filename": "AR/b6/BAR3-6.json",
    "ger_modultitel": "Konstruieren VI",
    "selecStg": "AR",
    "modulnr": "BAR 3.6",
    "lehrende": [
      "Prof. Dr. Matthias Sieveke"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR4-1.json": {
    "filename": "AR/b6/BAR4-1.json",
    "ger_modultitel": "Architekturzeichnung, Bau- und Kunstgeschichte I",
    "selecStg": "AR",
    "modulnr": "BAR 4.1.",
    "lehrende": [
      "Prof. Andrea Wandel",
      "Prof. Oskar Spital-Frenking"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR4-2.json": {
    "filename": "AR/b6/BAR4-2.json",
    "ger_modultitel": "Bau- und Kunstgeschichte II",
    "selecStg": "AR",
    "modulnr": "BAR 4.2",
    "lehrende": [
      "Professor*innen und Lehrbeauftragte des FB Gestaltung"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR4-3.json": {
    "filename": "AR/b6/BAR4-3.json",
    "ger_modultitel": "Architektur im städtischen Kontext",
    "selecStg": "AR",
    "modulnr": "BAR 4.3",
    "lehrende": [
      "Prof. Marion Goerdt"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR4-5.json": {
    "filename": "AR/b6/BAR4-5.json",
    "ger_modultitel": "Planungs- und Baurecht I",
    "selecStg": "AR",
    "modulnr": "BAR 4.5",
    "lehrende": [
      "Prof. Petra Riegler-Floors"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR4-6.json": {
    "filename": "AR/b6/BAR4-6.json",
    "ger_modultitel": "Planungs- und Baurecht II",
    "selecStg": "AR",
    "modulnr": "BAR 4.6",
    "lehrende": [
      "Prof. Petra Riegler-Floors"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR5-1.json": {
    "filename": "AR/b6/BAR5-1.json",
    "ger_modultitel": "Darstellung I",
    "selecStg": "AR",
    "modulnr": "BAR 5.1",
    "lehrende": [
      "Dipl.-Ing. BDA Michael Schwarz"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BAR5-2.json": {
    "filename": "AR/b6/BAR5-2.json",
    "ger_modultitel": "Darstellung II",
    "selecStg": "AR",
    "modulnr": "BAR 5.2",
    "lehrende": [
      "Dipl.-Ing. BDA Michael Schwarz"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-1.json": {
    "filename": "AR/b6/BARWM-1.json",
    "ger_modultitel": "Darstellungsstrategien",
    "selecStg": "AR",
    "modulnr": "BAR WM 1",
    "lehrende": [
      "variabel"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-10.json": {
    "filename": "AR/b6/BARWM-10.json",
    "ger_modultitel": "Sonderthemen der Technologie",
    "selecStg": "AR",
    "modulnr": "BAR WM 10",
    "lehrende": [
      "variabel, Lehrende der Architektur"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-11.json": {
    "filename": "AR/b6/BARWM-11.json",
    "ger_modultitel": "Entwerfen in Holzbauwesen",
    "selecStg": "AR",
    "modulnr": "BAR WM 11",
    "lehrende": [
      "Prof. Dr. Becker"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-12.json": {
    "filename": "AR/b6/BARWM-12.json",
    "ger_modultitel": "Building Information Modelling",
    "selecStg": "AR",
    "modulnr": "BAR WM 12",
    "lehrende": [
      "Prof. Robert Thum"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-13.json": {
    "filename": "AR/b6/BARWM-13.json",
    "ger_modultitel": "Campus Credtis",
    "selecStg": "AR",
    "modulnr": "BAR WM 13",
    "lehrende": [
      "variabel, Lehrende der Architektur"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-14.json": {
    "filename": "AR/b6/BARWM-14.json",
    "ger_modultitel": "Sonderthemen der Architektur",
    "selecStg": "AR",
    "modulnr": "BAR WM 14",
    "lehrende": [
      "variabel, Lehrende der Architektur"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-15.json": {
    "filename": "AR/b6/BARWM-15.json",
    "ger_modultitel": "Dokumentation, Refelxion",
    "selecStg": "AR",
    "modulnr": "BAR WM 15",
    "lehrende": [
      "N.N."
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-16.json": {
    "filename": "AR/b6/BARWM-16.json",
    "ger_modultitel": "Gestalten im Detail",
    "selecStg": "AR",
    "modulnr": "BAR WM 16",
    "lehrende": [
      "Prof. Peter Böhm"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-17.json": {
    "filename": "AR/b6/BARWM-17.json",
    "ger_modultitel": "Workshop und Symposien",
    "selecStg": "AR",
    "modulnr": "BAR WM 17",
    "lehrende": [
      "variabel, Lehrende der Architektur"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-18.json": {
    "filename": "AR/b6/BARWM-18.json",
    "ger_modultitel": "Studentische Wettbewerbe",
    "selecStg": "AR",
    "modulnr": "BAR WM 18",
    "lehrende": [
      "variabel, Lehrende der Architektur"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-19.json": {
    "filename": "AR/b6/BARWM-19.json",
    "ger_modultitel": "Künstlerisches gestalten I",
    "selecStg": "AR",
    "modulnr": "BAR WM 19",
    "lehrende": [
      "Prof. Jörg Obergfell"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-2.json": {
    "filename": "AR/b6/BARWM-2.json",
    "ger_modultitel": "Präsentationssstrategien",
    "selecStg": "AR",
    "modulnr": "BAR WM 2",
    "lehrende": [
      "variabel"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-3.json": {
    "filename": "AR/b6/BARWM-3.json",
    "ger_modultitel": "Tragwerksentwicklung",
    "selecStg": "AR",
    "modulnr": "BAR WM 3",
    "lehrende": [
      "variabel"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-4.json": {
    "filename": "AR/b6/BARWM-4.json",
    "ger_modultitel": "Kontext Architektur und Landschaft",
    "selecStg": "AR",
    "modulnr": "BAR WM 4",
    "lehrende": [
      "variabel"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-5.json": {
    "filename": "AR/b6/BARWM-5.json",
    "ger_modultitel": "Kulturelle Kompetenz",
    "selecStg": "AR",
    "modulnr": "BAR WM 5",
    "lehrende": [
      "variabel"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-6.json": {
    "filename": "AR/b6/BARWM-6.json",
    "ger_modultitel": "Sondergebiet der Gebäudelehre",
    "selecStg": "AR",
    "modulnr": "BAR WM 6",
    "lehrende": [
      "Prof. Jan-Henrik Hafke"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-7.json": {
    "filename": "AR/b6/BARWM-7.json",
    "ger_modultitel": "Zeichen für Architekten",
    "selecStg": "AR",
    "modulnr": "BAR WM 7",
    "lehrende": [
      "Prof. Andrea Wandel",
      "Lehrende der Architektur"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-8.json": {
    "filename": "AR/b6/BARWM-8.json",
    "ger_modultitel": "Entwurfsstrategien",
    "selecStg": "AR",
    "modulnr": "BAR WM 8",
    "lehrende": [
      "Prof. Andrea Wandel"
    ],
    "selecStgType": "bachelor"
  },
  "AR/b6/BARWM-9.json": {
    "filename": "AR/b6/BARWM-9.json",
    "ger_modultitel": "Sonderthemen im historischen Kontext",
    "selecStg": "AR",
    "modulnr": "BAR WM 9",
    "lehrende": [
      "Prof. Oskar Spital-Frenking",
      "Prof. Marion Goerdt"
    ],
    "selecStgType": "bachelor"
  },
  "AR/modules_ar_m4/BIA-1.1.2.json": {
    "filename": "AR/modules_ar_m4/BIA-1.1.2.json",
    "ger_modultitel": "BINA_Proportion, Maßstab, Raum",
    "selecStg": "IA",
    "modulnr": "MAR 1.1.",
    "lehrende": "Prof. Dr. Stefan Dornbusch",
    "selecStgType": "B"
  },
  "AR/modules_ar_m4/mar-1-1-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-1-1-en-fr-uk.json",
    "ger_modultitel": "Entwurfsprojekt Grundlagen",
    "selecStg": "N/A",
    "modulnr": "MAR 1.1.",
    "lehrende": "Prof. Robert Thum",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-1-2-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-1-2-en-fr-uk.json",
    "ger_modultitel": "Entwurfsprojekt",
    "selecStg": "N/A",
    "modulnr": "MAR 1.2.",
    "lehrende": "Prof. Jan Henrik-Hafke",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-1-3b-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-1-3b-en-fr-uk.json",
    "ger_modultitel": "Vertiefungsprojekt",
    "selecStg": "N/A",
    "modulnr": "MAR 1.3.",
    "lehrende": "Prof. Petra Riegler-Floors",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-1-4-1-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-1-4-1-en-fr-uk.json",
    "ger_modultitel": "Masterthesis",
    "selecStg": "N/A",
    "modulnr": "MAR 1.4.1",
    "lehrende": "Prof. Robert Thum",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-1-4-2-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-1-4-2-en-fr-uk.json",
    "ger_modultitel": "Kolloquium",
    "selecStg": "N/A",
    "modulnr": "MAR 1.4.2",
    "lehrende": "Prof. Robert Thum",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-2-1-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-2-1-en-fr-uk.json",
    "ger_modultitel": "Sonderthemen der Konstruktion",
    "selecStg": "N/A",
    "modulnr": "MAR 2.1.",
    "lehrende": "Prof. Daniel Berger",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-2-2-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-2-2-en-fr-uk.json",
    "ger_modultitel": "Konzeptionelles Entwerfen",
    "selecStg": "N/A",
    "modulnr": "MAR 2.2.",
    "lehrende": "Prof. Robert Thum",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-2-3-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-2-3-en-fr-uk.json",
    "ger_modultitel": "Konstruktion und Bauphysik",
    "selecStg": "N/A",
    "modulnr": "MAR 2.3.",
    "lehrende": "Prof. Petra Riegler-Floors",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-3-1-1-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-3-1-1-en-fr-uk.json",
    "ger_modultitel": "Projektieren im historischen Kontext",
    "selecStg": "N/A",
    "modulnr": "MAR 3.1.1",
    "lehrende": "Prof. Michael Schwarz",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-3-1-2-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-3-1-2-en-fr-uk.json",
    "ger_modultitel": "Theorie I",
    "selecStg": "N/A",
    "modulnr": "MAR 3.1.2",
    "lehrende": "Prof. Jan-Henrik Hafke",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-3-2-1-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-3-2-1-en-fr-uk.json",
    "ger_modultitel": "Wissenschaftliche Methoden der historischen Bauaufnahme",
    "selecStg": "N/A",
    "modulnr": "MAR 3.2.1",
    "lehrende": "Prof. Michael Schwarz",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-3-2-2-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-3-2-2-en-fr-uk.json",
    "ger_modultitel": "Theorie II",
    "selecStg": "N/A",
    "modulnr": "MAR 3.2.2",
    "lehrende": "Prof. Jan-Henrik Hafke",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-3-3-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-3-3-en-fr-uk.json",
    "ger_modultitel": "Masterthesis-Seminar",
    "selecStg": "N/A",
    "modulnr": "MAR 3.3",
    "lehrende": "variable Lehrende",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-4-1-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-4-1-en-fr-uk.json",
    "ger_modultitel": "Leitbilder Europäische Stadt",
    "selecStg": "N/A",
    "modulnr": "MAR 4.1.",
    "lehrende": "Prof. Christoph Klanten",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-4-2-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-4-2-en-fr-uk.json",
    "ger_modultitel": "Konstruktion und Bauweisen im internationalen Vergleich",
    "selecStg": "N/A",
    "modulnr": "MAR 4.2",
    "lehrende": "Prof. Dr. Matthias Sieveke",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-4-3-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-4-3-en-fr-uk.json",
    "ger_modultitel": "Internationales Projektmarketing",
    "selecStg": "N/A",
    "modulnr": "MAR 4.3.",
    "lehrende": "Prof. Dr. Matthias Sieveke",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-1-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-1-en-fr-uk.json",
    "ger_modultitel": "Digitale Entwurfsmethodik",
    "selecStg": "N/A",
    "modulnr": "MAR WM 1",
    "lehrende": "Prof. Robert Thum",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-10-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-10-en-fr-uk.json",
    "ger_modultitel": "Digitale Fertigungstechniken Holz",
    "selecStg": "N/A",
    "modulnr": "MAR WM 10",
    "lehrende": "N.N",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-11-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-11-en-fr-uk.json",
    "ger_modultitel": "Sondergebiete der Architektur I",
    "selecStg": "N/A",
    "modulnr": "MAR WM 11",
    "lehrende": "Prof. Robert Thum",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-12-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-12-en-fr-uk.json",
    "ger_modultitel": "Sondergebiete der Architektur II",
    "selecStg": "N/A",
    "modulnr": "MAR WM 12",
    "lehrende": "Prof. Robert Thum",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-13-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-13-en-fr-uk.json",
    "ger_modultitel": "Verwendung massiver Baustoffe und deren Detailierung",
    "selecStg": "N/A",
    "modulnr": "MAR WM 13",
    "lehrende": "Prof. Michael Schwarz",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-14-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-14-en-fr-uk.json",
    "ger_modultitel": "Smart Systems",
    "selecStg": "N/A",
    "modulnr": "MAR WM 14",
    "lehrende": "Prof. Robert Thum",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-15-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-15-en-fr-uk.json",
    "ger_modultitel": "Nationale oder internationale Workshops und Symposien",
    "selecStg": "N/A",
    "modulnr": "MAR WM 15",
    "lehrende": "Prof. Robert Thum",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-16-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-16-en-fr-uk.json",
    "ger_modultitel": "Nationale oder internationale Studentische Wettbewerbe",
    "selecStg": "N/A",
    "modulnr": "MAR WM 16",
    "lehrende": "Prof. Daniel Berger",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-17-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-17-en-fr-uk.json",
    "ger_modultitel": "Einführung in die historische Bauforschung",
    "selecStg": "N/A",
    "modulnr": "MAR WM 17",
    "lehrende": "Prof. Michael Schwarz",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-18-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-18-en-fr-uk.json",
    "ger_modultitel": "Mehrgeschossige Mischbauweisen und Holzbauwerke",
    "selecStg": "N/A",
    "modulnr": "MAR WM 18",
    "lehrende": "N.N",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-19-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-19-en-fr-uk.json",
    "ger_modultitel": "Sonderthemen der Baugeschichte",
    "selecStg": "N/A",
    "modulnr": "MAR WM 19",
    "lehrende": "Prof. Michael Schwarz",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-2-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-2-en-fr-uk.json",
    "ger_modultitel": "Digitale Konstruktionsmethoden",
    "selecStg": "N/A",
    "modulnr": "MAR WM 2",
    "lehrende": "Prof. Robert Thum",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-20-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-20-en-fr-uk.json",
    "ger_modultitel": "Sonderthemen des Projektierens im historischen Kontext",
    "selecStg": "N/A",
    "modulnr": "MAR WM 20",
    "lehrende": "Prof. Michael Schwarz",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-21-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-21-en-fr-uk.json",
    "ger_modultitel": "Exkursionen",
    "selecStg": "N/A",
    "modulnr": "MAR WM 21",
    "lehrende": "variable Lehrende",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-22-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-22-en-fr-uk.json",
    "ger_modultitel": "Künstlerisches Gestalten",
    "selecStg": "N/A",
    "modulnr": "MAR WM 22",
    "lehrende": "Prof. Jörg Obergfell",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-3-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-3-en-fr-uk.json",
    "ger_modultitel": "Kommunikation und Präsentation",
    "selecStg": "N/A",
    "modulnr": "MAR WM 3",
    "lehrende": "Prof. Dr. Susanne Bennewitz",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-4-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-4-en-fr-uk.json",
    "ger_modultitel": "Bauaufnahme Bauforschung",
    "selecStg": "N/A",
    "modulnr": "MAR WM 4",
    "lehrende": "Prof. Michael Schwarz",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-5-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-5-en-fr-uk.json",
    "ger_modultitel": "Gebäudeanalyse",
    "selecStg": "N/A",
    "modulnr": "MAR WM 5",
    "lehrende": "Prof. Jan-Henrik Hafke",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-6-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-6-en-fr-uk.json",
    "ger_modultitel": "Darstellungs- und Präsentationsformen",
    "selecStg": "N/A",
    "modulnr": "MAR WM 6",
    "lehrende": "Prof. Andrea Wandel",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-7-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-7-en-fr-uk.json",
    "ger_modultitel": "Entwurfsmethodik",
    "selecStg": "N/A",
    "modulnr": "MAR WM 7",
    "lehrende": "Prof. Andrea Wandel",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-8-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-8-en-fr-uk.json",
    "ger_modultitel": "Tragwerksoptimierung",
    "selecStg": "N/A",
    "modulnr": "MAR WM 8",
    "lehrende": "Prof. Daniel Berger",
    "selecStgType": "N/A"
  },
  "AR/modules_ar_m4/mar-wm-9-en-fr-uk.json": {
    "filename": "AR/modules_ar_m4/mar-wm-9-en-fr-uk.json",
    "ger_modultitel": "Architektur und Landschaft",
    "selecStg": "N/A",
    "modulnr": "MAR WM 9",
    "lehrende": "Prof. Christoph Klanten",
    "selecStgType": "N/A"
  },
  "es/b6/BESAB-AR-B.json": {
    "filename": "es/b6/BESAB-AR-B.json",
    "ger_modultitel": "Abschlussarbeit",
    "selecStg": "ES",
    "modulnr": "ABARB",
    "lehrende": [
      "Auswahl: Betreuende der Abschlussaarbeit",
      "Auswahl: 2 Profs des Studiengangs"
    ],
    "selecStgType": "bachelor"
  },
  "es/b6/BESE-S-G4.json": {
    "filename": "es/b6/BESE-S-G4.json",
    "ger_modultitel": "Edelstein 4",
    "selecStg": "ES",
    "modulnr": "ESG 4",
    "lehrende": [
      "Prof. Eva-Maria Kollischan",
      "Tanja Falkenhayer",
      "Cornelia Wruck"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-1-2.json": {
    "filename": "ia/b8/BIA1-1-2.json",
    "ger_modultitel": "BINA_Proportion, Maßstab, Raum",
    "selecStg": "IA",
    "modulnr": "BINA 1.1.2.",
    "lehrende": [
      "Prof. Dr. Stefan Dornbusch"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-1-3.json": {
    "filename": "ia/b8/BIA1-1-3.json",
    "ger_modultitel": "BINA1_Technische Raumgestaltung I",
    "selecStg": "IA",
    "modulnr": "BINA 1.1.3.",
    "lehrende": [
      "Prof. Dirk Schluppkotten"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-11-7.json": {
    "filename": "ia/b8/BIA1-11-7.json",
    "ger_modultitel": "BINA1_Großes Projekt II – NR + E | Narrativer Raum und Entwerfen",
    "selecStg": "IA",
    "modulnr": "BINA 1.11.7.",
    "lehrende": [
      "Prof. Sanna Leppäkoski"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-12-7.json": {
    "filename": "ia/b8/BIA1-12-7.json",
    "ger_modultitel": "BINA1_Großes Projekt II – IKBB + E | Innenarchitektonisches Konstruieren, Bauen im Bestand und Entwerfen",
    "selecStg": "IA",
    "modulnr": "BINA 1.12.7.",
    "lehrende": [
      "Prof. Marcus Kaiser"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-13-2.json": {
    "filename": "ia/b8/BIA1-13-2.json",
    "ger_modultitel": "BINA1_Möbel und Objekt",
    "selecStg": "IA",
    "modulnr": "BINA 1.13.2.",
    "lehrende": [
      "Prof. Ingo Krapf"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-13-3.json": {
    "filename": "ia/b8/BIA1-13-3.json",
    "ger_modultitel": "Möbeldesign",
    "selecStg": "IA",
    "modulnr": "BINA 1.13.3",
    "lehrende": [
      "Prof. Ingo Krapf"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-2-4.json": {
    "filename": "ia/b8/BIA1-2-4.json",
    "ger_modultitel": "BINA1_kleines Projekt - PMAD I Produkt-, Möbel- und Ausstellungsdesign",
    "selecStg": "IA",
    "modulnr": "BINA 1.2.4.",
    "lehrende": [
      "Prof. Ingo Krapf"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-3-4.json": {
    "filename": "ia/b8/BIA1-3-4.json",
    "ger_modultitel": "BINA1_Kleines Projekt – NR und E | Narrativer Raum und Entwerfen",
    "selecStg": "IA",
    "modulnr": "BINA 1.3.4.",
    "lehrende": [
      "Prof. Sanna Leppäkoski"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-4-4.json": {
    "filename": "ia/b8/BIA1-4-4.json",
    "ger_modultitel": "BINA1_Kleines Projekt – IKBB und E Innenarchitektonisches Konstruieren, Bauen im Bestand und Entwerfen",
    "selecStg": "IA",
    "modulnr": "BINA 1.4.4.",
    "lehrende": [
      "Prof. Marcus Kaiser"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-5-4.json": {
    "filename": "ia/b8/BIA1-5-4.json",
    "ger_modultitel": "BINA1_Kleines Projekt – DK und E Digitales Konstruieren und Entwerfen",
    "selecStg": "IA",
    "modulnr": "BINA 1.5.4.",
    "lehrende": [
      "Prof. Martin Schroth"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-5-5.json": {
    "filename": "ia/b8/BIA1-5-5.json",
    "ger_modultitel": "BINA1_Großes Projekt I - PMAD | Produkt-, Möbel- und Ausstellungsdesign",
    "selecStg": "IA",
    "modulnr": "BINA 1.5.5",
    "lehrende": [
      "Prof. Ingo Krapf"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-6-4.json": {
    "filename": "ia/b8/BIA1-6-4.json",
    "ger_modultitel": "BINA1_Kleines Projekt - TR und E Technische Raumgestaltung und Entwerfen",
    "selecStg": "IA",
    "modulnr": "BINA 1.6.4.",
    "lehrende": [
      "Prof. Dirk Schluppkotten"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-7-5.json": {
    "filename": "ia/b8/BIA1-7-5.json",
    "ger_modultitel": "BINA1_Großes Projekt I – NR + E | Narrativer Raum und Entwerfen",
    "selecStg": "IA",
    "modulnr": "BINA 1.7.5",
    "lehrende": [
      "Prof. Sanna Leppäkoski"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-8-5.json": {
    "filename": "ia/b8/BIA1-8-5.json",
    "ger_modultitel": "BINA1_Großes Projekt I – IKBB und E | Innenarchitektonisches Konstruieren, Bauen im Bestand und Entwerfen",
    "selecStg": "IA",
    "modulnr": "BINA 1.8.5",
    "lehrende": [
      "Prof. Marcus Kaiser"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA1-9-7.json": {
    "filename": "ia/b8/BIA1-9-7.json",
    "ger_modultitel": "BINA1_Großes Projekt II – PMAD | Produkt-, Möbel- und Ausstellungsdesign",
    "selecStg": "IA",
    "modulnr": "BINA 1.9.7.",
    "lehrende": [
      "Prof. Ingo Krapf"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA2-1-1.json": {
    "filename": "ia/b8/BIA2-1-1.json",
    "ger_modultitel": "BINA2_Darstellen Gestalten I",
    "selecStg": "IA",
    "modulnr": "BINA 2.1.1.",
    "lehrende": [
      "Prof. Stefan Dornbusch"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA2-2-2.json": {
    "filename": "ia/b8/BIA2-2-2.json",
    "ger_modultitel": "BINA2_Darstellen Gestalten II",
    "selecStg": "IA",
    "modulnr": "BINA 2.2.2.",
    "lehrende": [
      "Prof. Stefan Dornbusch"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA2-3-4.json": {
    "filename": "ia/b8/BIA2-3-4.json",
    "ger_modultitel": "BINA2_Präsentation Bild | Text",
    "selecStg": "IA",
    "modulnr": "BINA 2.3.4.",
    "lehrende": [
      "Prof. Stefan Dornbusch"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA2-4-1.json": {
    "filename": "ia/b8/BIA2-4-1.json",
    "ger_modultitel": "Analoge und digitale Darstellung",
    "selecStg": "IA",
    "modulnr": "BINA 2.4.1",
    "lehrende": [
      "Wilfried Schiffeler",
      "Till Neuer"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA2-4-3.json": {
    "filename": "ia/b8/BIA2-4-3.json",
    "ger_modultitel": "Digitales Modellieren",
    "selecStg": "IA",
    "modulnr": "BINA 2.4.3",
    "lehrende": [
      "Oliver Ingeln"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA2-4-4.json": {
    "filename": "ia/b8/BIA2-4-4.json",
    "ger_modultitel": "INA2_Digitales Visualisieren",
    "selecStg": "IA",
    "modulnr": "BINA 2.4.4.",
    "lehrende": [
      "]Oliver Ingeln"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA2-5-1.json": {
    "filename": "ia/b8/BIA2-5-1.json",
    "ger_modultitel": "BINA2_Modellbau",
    "selecStg": "IA",
    "modulnr": "BINA 2.5.1.",
    "lehrende": [
      "Harald Schilz"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA3-1-3.json": {
    "filename": "ia/b8/BIA3-1-3.json",
    "ger_modultitel": "BINA3_Raum, Material und Farbe",
    "selecStg": "IA",
    "modulnr": "BINA 3.1.3.",
    "lehrende": [
      "Prof. Sanna Leppäkoski"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA3-1-4.json": {
    "filename": "ia/b8/BIA3-1-4.json",
    "ger_modultitel": "BINA3_Innenarchitektonisches Konstruieren",
    "selecStg": "IA",
    "modulnr": "BINA 3.1.4.",
    "lehrende": [
      "Prof. Marcus Kaiser"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA3-1-7.json": {
    "filename": "ia/b8/BIA3-1-7.json",
    "ger_modultitel": "BINA3_ Digitales Konstruieren II",
    "selecStg": "IA",
    "modulnr": "BINA 3.1.7",
    "lehrende": [
      "Prof. Martin Schroth"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA3-2-1.json": {
    "filename": "ia/b8/BIA3-2-1.json",
    "ger_modultitel": "BINA3_ Grundlagen Konstruieren",
    "selecStg": "IA",
    "modulnr": "BINA 3.2.1.",
    "lehrende": [
      "Prof. Marcus Kaiser"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA3-2-2.json": {
    "filename": "ia/b8/BIA3-2-2.json",
    "ger_modultitel": "Bauen im Bestand",
    "selecStg": "IA",
    "modulnr": "BINA 3.2.2",
    "lehrende": [
      "Prof. Marcus Kaiser",
      "Alois Palgen"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA3-2-5.json": {
    "filename": "ia/b8/BIA3-2-5.json",
    "ger_modultitel": "Digitales Konstruieren I",
    "selecStg": "IA",
    "modulnr": "BINA 3.2.5",
    "lehrende": [
      "Prof. Martin Schroth"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA3-3-5.json": {
    "filename": "ia/b8/BIA3-3-5.json",
    "ger_modultitel": "Technische Raumgestaltung II",
    "selecStg": "IA",
    "modulnr": "BINA 3.3.5",
    "lehrende": [
      "Prof. Dirk Schluppkotten",
      "Oliver Schwinn"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA3-4-3.json": {
    "filename": "ia/b8/BIA3-4-3.json",
    "ger_modultitel": "BINA3_Tragwerke und Bauphysik",
    "selecStg": "IA",
    "modulnr": "BINA 3.4.3",
    "lehrende": [
      "Dr.-Ing. Iris Ebner"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA3-5-4.json": {
    "filename": "ia/b8/BIA3-5-4.json",
    "ger_modultitel": "BINA3_Gebäudelehre",
    "selecStg": "IA",
    "modulnr": "BINA 3.5.4.",
    "lehrende": [
      "Prof. Sanna Leppäkoski"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA4-1-1.json": {
    "filename": "ia/b8/BIA4-1-1.json",
    "ger_modultitel": "BINA4_Architektur, Design, Kunstwissenschaft",
    "selecStg": "IA",
    "modulnr": "BINA 4.1.1.",
    "lehrende": [
      "Prof. Dr. Gerald Schröder",
      "Prof. Dr. Christina Threuter"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA4-1-2.json": {
    "filename": "ia/b8/BIA4-1-2.json",
    "ger_modultitel": "BINA4_Architekturwissenschaft",
    "selecStg": "IA",
    "modulnr": "BINA 4.1.2",
    "lehrende": [
      "Prof. Dr. Gerald Schröder",
      "Prof. Dr. Christina Threuter"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA4-2-1.json": {
    "filename": "ia/b8/BIA4-2-1.json",
    "ger_modultitel": "BINA4_Innenarchitektur: Geschichte und Theorie I",
    "selecStg": "IA",
    "modulnr": "BINA 4.2.1.",
    "lehrende": [
      "Prof. Dr. Christina Threuter"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA4-2-2.json": {
    "filename": "ia/b8/BIA4-2-2.json",
    "ger_modultitel": "BINA4_Innenarchitektur: Geschichte und Theorie II",
    "selecStg": "IA",
    "modulnr": "BINA 4.2.2",
    "lehrende": [
      "Prof. Dr. Gerald Schröder"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA4-2-7.json": {
    "filename": "ia/b8/BIA4-2-7.json",
    "ger_modultitel": "BINA4_Innenarchitektur und Narrativer Raum",
    "selecStg": "IA",
    "modulnr": "BINA 4.2.7",
    "lehrende": [
      "Prof. Sanna Leppäkoski"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA4-3-5.json": {
    "filename": "ia/b8/BIA4-3-5.json",
    "ger_modultitel": "Baurecht und Baubetrieb",
    "selecStg": "IA",
    "modulnr": "BINA 4.3.5",
    "lehrende": [
      "Rainer Hub"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA4-8-8.json": {
    "filename": "ia/b8/BIA4-8-8.json",
    "ger_modultitel": "BINA4_Bachelorseminar",
    "selecStg": "IA",
    "modulnr": "BINA 4.8.8.",
    "lehrende": [
      "Professor*innen der Innenarchitektur"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA5-1.json": {
    "filename": "ia/b8/BIA5-1.json",
    "ger_modultitel": "BINA5_Interdisziplinäre Projekte",
    "selecStg": "IA",
    "modulnr": "BINA 4.8.8.",
    "lehrende": [
      "Professor*innen Fachbereich"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA6-1-6.json": {
    "filename": "ia/b8/BIA6-1-6.json",
    "ger_modultitel": "BINA6_Praxisseminar",
    "selecStg": "IA",
    "modulnr": "BINA 6.1.6.",
    "lehrende": [
      "Professor*innen der Innenarchitektur"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA6-2-6.json": {
    "filename": "ia/b8/BIA6-2-6.json",
    "ger_modultitel": "BINA6_Praktisches Studiensemester",
    "selecStg": "IA",
    "modulnr": "BINA 6.2.6.",
    "lehrende": [
      "Professor*innen der Innenarchitektur"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA7-1-8.json": {
    "filename": "ia/b8/BIA7-1-8.json",
    "ger_modultitel": "BINA7_Abschlussarbeit",
    "selecStg": "IA",
    "modulnr": "BINA 7.1.8.",
    "lehrende": [
      "Professor*innen der Innenarchitektur"
    ],
    "selecStgType": "bachelor"
  },
  "ia/b8/BIA7-2-8.json": {
    "filename": "ia/b8/BIA7-2-8.json",
    "ger_modultitel": "BINA7_Kolloquium zur Abschlussarbeit",
    "selecStg": "IA",
    "modulnr": "BINA 7.2.8.",
    "lehrende": [
      "Professor*innen der Innenarchitektur"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-1.json": {
    "filename": "kd/b7/BKD1-1-1.json",
    "ger_modultitel": "Typografische Form, Kommunikation und Experimente",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.1",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-10.json": {
    "filename": "kd/b7/BKD1-1-10.json",
    "ger_modultitel": "Angewandte Fotografie",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.10",
    "lehrende": [
      "Prof. Valerie Schmidt"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-11.json": {
    "filename": "kd/b7/BKD1-1-11.json",
    "ger_modultitel": "Experimentelle Gestaltung",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.11",
    "lehrende": [
      "Prof. Tim Romanowsky"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-12.json": {
    "filename": "kd/b7/BKD1-1-12.json",
    "ger_modultitel": "Zeichnen und Medien",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.12",
    "lehrende": [
      "Prof. Christopher Ledwig"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-14.json": {
    "filename": "kd/b7/BKD1-1-14.json",
    "ger_modultitel": "Originalgrafische Techniken",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.14",
    "lehrende": [
      "Prof. Christopher Ledwig"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-15.json": {
    "filename": "kd/b7/BKD1-1-15.json",
    "ger_modultitel": "Externe Projekte",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.15",
    "lehrende": [
      "Prof. Christopher Ledwig"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-2.json": {
    "filename": "kd/b7/BKD1-1-2.json",
    "ger_modultitel": "Typografische Konzeption und Systeme",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.2",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-3.json": {
    "filename": "kd/b7/BKD1-1-3.json",
    "ger_modultitel": "Produkt und Umwelt",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.3",
    "lehrende": [
      "Prof. Harald Steber"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-4.json": {
    "filename": "kd/b7/BKD1-1-4.json",
    "ger_modultitel": "Marke und Umwelt",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.4",
    "lehrende": [
      "Prof. Harald Steber"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-5.json": {
    "filename": "kd/b7/BKD1-1-5.json",
    "ger_modultitel": "Illustration und Buchgestaltung I",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.5",
    "lehrende": [
      "Prof. Henriette Sauvant"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-6.json": {
    "filename": "kd/b7/BKD1-1-6.json",
    "ger_modultitel": "Illustration und Buchgestaltung II",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.6",
    "lehrende": [
      "Prof. Henriette Sauvant"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-7.json": {
    "filename": "kd/b7/BKD1-1-7.json",
    "ger_modultitel": "User Experience / Interaction Design 2 / Aufbaukurs",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.7",
    "lehrende": [
      "Prof. Christopher Ledwig"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-8.json": {
    "filename": "kd/b7/BKD1-1-8.json",
    "ger_modultitel": "Virtual / Mixed Reality",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.7",
    "lehrende": [
      "Prof. Christopher Ledwig"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-1-9.json": {
    "filename": "kd/b7/BKD1-1-9.json",
    "ger_modultitel": "Experimentelle Fotografie",
    "selecStg": "KD",
    "modulnr": "BKD 1.1.9",
    "lehrende": [
      "Prof. Valerie Schmidt"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-2-1.json": {
    "filename": "kd/b7/BKD1-2-1.json",
    "ger_modultitel": "Logotype- und Typedesign",
    "selecStg": "KD",
    "modulnr": "BKD 1.2.1",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-2-2.json": {
    "filename": "kd/b7/BKD1-2-2.json",
    "ger_modultitel": "Entwurf 3D",
    "selecStg": "KD",
    "modulnr": "BKD 1.2.2",
    "lehrende": [
      "Prof. Harald Steber"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-2-3.json": {
    "filename": "kd/b7/BKD1-2-3.json",
    "ger_modultitel": "Einführung in die Illustration",
    "selecStg": "KD",
    "modulnr": "BKD 1.2.3",
    "lehrende": [
      "Prof. Harald Steber"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-2-4.json": {
    "filename": "kd/b7/BKD1-2-4.json",
    "ger_modultitel": "Informationsdesign",
    "selecStg": "KD",
    "modulnr": "BKD 1.2.4",
    "lehrende": [
      "Prof. Harald Steber"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD1-2-5.json": {
    "filename": "kd/b7/BKD1-2-5.json",
    "ger_modultitel": "Transmediale Fotografie",
    "selecStg": "KD",
    "modulnr": "BKD 1.2.5",
    "lehrende": [
      "Prof. Harald Steber"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD2-1-1.json": {
    "filename": "kd/b7/BKD2-1-1.json",
    "ger_modultitel": "Bildnerische Komposition",
    "selecStg": "KD",
    "modulnr": "BKD 2.1.1",
    "lehrende": [
      "Prof. Babak Mossa Asbagholmodjahedin"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD2-2-1.json": {
    "filename": "kd/b7/BKD2-2-1.json",
    "ger_modultitel": "Schriftgestaltung/ Typografie elementar",
    "selecStg": "KD",
    "modulnr": "BKD 2.2.1",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD2-2-2.json": {
    "filename": "kd/b7/BKD2-2-2.json",
    "ger_modultitel": "Gestaltungsgrundlagen 3D",
    "selecStg": "KD",
    "modulnr": "BKD 2.2.2",
    "lehrende": [
      "Prof. Harald Steber"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD3-1.json": {
    "filename": "kd/b7/BKD3-1.json",
    "ger_modultitel": "Grundlagen der Typographie und Technik I",
    "selecStg": "KD",
    "modulnr": "BKD 3.1.",
    "lehrende": [
      "Dipl-Des. Till Neuer"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD3-2.json": {
    "filename": "kd/b7/BKD3-2.json",
    "ger_modultitel": "Grundlagen der Typographie und Technik II",
    "selecStg": "KD",
    "modulnr": "BKD 3.2.",
    "lehrende": [
      "Dipl-Des. Till Neuer"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD3-3.json": {
    "filename": "kd/b7/BKD3-3.json",
    "ger_modultitel": "Grundlagen der Typographie und Technik III",
    "selecStg": "KD",
    "modulnr": "BKD 3.3",
    "lehrende": [
      "Dipl-Des. Till Neuer"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD4-5.json": {
    "filename": "kd/b7/BKD4-5.json",
    "ger_modultitel": "Aktuelle Fragestellungen in den Kunst- und Designwissenschaft",
    "selecStg": "KD",
    "modulnr": "BKD 4.5",
    "lehrende": [
      "Nicholas Maximilian Tänzer"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD4-6.json": {
    "filename": "kd/b7/BKD4-6.json",
    "ger_modultitel": "Marketing/Recht/Wirtschaft",
    "selecStg": "KD",
    "modulnr": "BKD 4.6",
    "lehrende": [
      "Nicholas Maximilian Tänzer"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD4-7.json": {
    "filename": "kd/b7/BKD4-7.json",
    "ger_modultitel": "Werbe-, Konsumenten & Kommunikationspsychologie",
    "selecStg": "KD",
    "modulnr": "BKD 4.7",
    "lehrende": [
      "Nicholas Maximilian Tänzer"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD5-1.json": {
    "filename": "kd/b7/BKD5-1.json",
    "ger_modultitel": "Interdisziplinäres Projekt I",
    "selecStg": "KD",
    "modulnr": "BKD 5.1",
    "lehrende": [
      "Professor/innen der Fachrichtung"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD5-2.json": {
    "filename": "kd/b7/BKD5-2.json",
    "ger_modultitel": "Interdisziplinäres Projekt II",
    "selecStg": "KD",
    "modulnr": "BKD 5.2",
    "lehrende": [
      "Professor/innen der Fachrichtung"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD6-1.json": {
    "filename": "kd/b7/BKD6-1.json",
    "ger_modultitel": "Praxis-/Auslandssemester",
    "selecStg": "KD",
    "modulnr": "BKD 6.1.",
    "lehrende": [
      "Professor/innen der Fachrichtung"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD6-2.json": {
    "filename": "kd/b7/BKD6-2.json",
    "ger_modultitel": "Praxis Seminar",
    "selecStg": "KD",
    "modulnr": "BKD 6.2",
    "lehrende": [
      "Professor/innen der Fachrichtung"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD6-3.json": {
    "filename": "kd/b7/BKD6-3.json",
    "ger_modultitel": "Praxis-Präsentation",
    "selecStg": "KD",
    "modulnr": "BKD 6.3.",
    "lehrende": [
      "Professor/innen der Fachrichtung"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD7-1.json": {
    "filename": "kd/b7/BKD7-1.json",
    "ger_modultitel": "Bachelor-Seminar",
    "selecStg": "KD",
    "modulnr": "BKD 7.1.",
    "lehrende": [
      "Professor/innen der Fachrichtung"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD7-2.json": {
    "filename": "kd/b7/BKD7-2.json",
    "ger_modultitel": "Präsentation der Abschlussarbeit",
    "selecStg": "KD",
    "modulnr": "BKD 7.2.",
    "lehrende": [
      "Professor/innen der Fachrichtung"
    ],
    "selecStgType": "bachelor"
  },
  "kd/b7/BKD7-3.json": {
    "filename": "kd/b7/BKD7-3.json",
    "ger_modultitel": "Abschlussarbeit",
    "selecStg": "KD",
    "modulnr": "BKD 7.3",
    "lehrende": [
      "Professor/innen der Fachrichtung"
    ],
    "selecStgType": "bachelor"
  },
  "kd/m4/MID3-1.json": {
    "filename": "kd/m4/MID3-1.json",
    "ger_modultitel": "Design interaktive Systeme",
    "selecStg": "ID",
    "modulnr": "MID3.1.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MID3-2.json": {
    "filename": "kd/m4/MID3-2.json",
    "ger_modultitel": "Medien im Raum",
    "selecStg": "ID",
    "modulnr": "MID3.2.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MID3-3.json": {
    "filename": "kd/m4/MID3-3.json",
    "ger_modultitel": "Design spielerischer Systeme",
    "selecStg": "ID",
    "modulnr": "MID3.3.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MID3-4.json": {
    "filename": "kd/m4/MID3-4.json",
    "ger_modultitel": "Narrative Formate",
    "selecStg": "ID",
    "modulnr": "MID3.4.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MID3-5.json": {
    "filename": "kd/m4/MID3-5.json",
    "ger_modultitel": "Externes Projekt",
    "selecStg": "ID",
    "modulnr": "MID3.5.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD1-1.json": {
    "filename": "kd/m4/MKD1-1.json",
    "ger_modultitel": "Buchkunst",
    "selecStg": "KD",
    "modulnr": "MKD 1.1.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD1-2.json": {
    "filename": "kd/m4/MKD1-2.json",
    "ger_modultitel": "Design Körper Raum",
    "selecStg": "KD",
    "modulnr": "MKD 1.2.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD1-3.json": {
    "filename": "kd/m4/MKD1-3.json",
    "ger_modultitel": "Interkulturelles und interdisziplinäres Denken und Handeln",
    "selecStg": "KD",
    "modulnr": "MKD 1.3.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD1-4.json": {
    "filename": "kd/m4/MKD1-4.json",
    "ger_modultitel": "Crossmedia digital",
    "selecStg": "KD",
    "modulnr": "MKD 1.4.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD1-5.json": {
    "filename": "kd/m4/MKD1-5.json",
    "ger_modultitel": "Design-Typografie",
    "selecStg": "KD",
    "modulnr": "MKD 1.5.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD1-6.json": {
    "filename": "kd/m4/MKD1-6.json",
    "ger_modultitel": "Theorie u. Praxis von narrativen Filmen  u. TV Spots ",
    "selecStg": "KD",
    "modulnr": "MKD 1.6.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD1-7.json": {
    "filename": "kd/m4/MKD1-7.json",
    "ger_modultitel": "Visualisierung",
    "selecStg": "KD",
    "modulnr": "MKD 1.7.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD1-8.json": {
    "filename": "kd/m4/MKD1-8.json",
    "ger_modultitel": "Externes Projekt",
    "selecStg": "KD",
    "modulnr": "MKD 1.8.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD2-1.json": {
    "filename": "kd/m4/MKD2-1.json",
    "ger_modultitel": "Recherche, Dokumentation und Repräsentation",
    "selecStg": "KD",
    "modulnr": "MKD2.1.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD2-2.json": {
    "filename": "kd/m4/MKD2-2.json",
    "ger_modultitel": "Kunst-, Design- und Kulturwissenschaft ",
    "selecStg": "KD",
    "modulnr": "MKD2.2.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD2-3.json": {
    "filename": "kd/m4/MKD2-3.json",
    "ger_modultitel": "Gesellschafts- und Kulturwissenschaften ",
    "selecStg": "KD",
    "modulnr": "MKD2.3.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD5-1.json": {
    "filename": "kd/m4/MKD5-1.json",
    "ger_modultitel": "Interdisziplinäres Projekt",
    "selecStg": "KD",
    "modulnr": "MKD5.1.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD6-1.json": {
    "filename": "kd/m4/MKD6-1.json",
    "ger_modultitel": "Praxis-/Auslandssemester",
    "selecStg": "KD",
    "modulnr": "MKD6.1.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD6-2.json": {
    "filename": "kd/m4/MKD6-2.json",
    "ger_modultitel": "Praxis-Seminar",
    "selecStg": "KD",
    "modulnr": "MKD6.2.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD6-3.json": {
    "filename": "kd/m4/MKD6-3.json",
    "ger_modultitel": "Praxis-Präsentation",
    "selecStg": "KD",
    "modulnr": "MKD6.3.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD7-1.json": {
    "filename": "kd/m4/MKD7-1.json",
    "ger_modultitel": "Abschlussarbeit",
    "selecStg": "KD",
    "modulnr": "MKD7.1.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "kd/m4/MKD7-2.json": {
    "filename": "kd/m4/MKD7-2.json",
    "ger_modultitel": "Präsentation der Abschlussarbeit",
    "selecStg": "KD",
    "modulnr": "MKD7.2.",
    "lehrende": [
      "Prof. Dirk Wachowiak"
    ],
    "selecStgType": "master"
  },
  "md/b7/BMD1-1-2.json": {
    "filename": "md/b7/BMD1-1-2.json",
    "ger_modultitel": "Entwurfsgestalterische Grundlagen und Kollektionsgestaltung I",
    "selecStg": "MD",
    "modulnr": "BMD 1.1.2",
    "lehrende": [
      "Prof. Bettina Maiburg",
      "Dipl.-Des. (FH) Claudia Hannig-Romann"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD1-1-3.json": {
    "filename": "md/b7/BMD1-1-3.json",
    "ger_modultitel": "Entwurfsgestalterische Grundlagen und Kollektionsgestaltung II",
    "selecStg": "MD",
    "modulnr": "BMO 1.1.3",
    "lehrende": [
      "Prof. Christian Bruns",
      "Simon Kürten"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD1-1-4.json": {
    "filename": "md/b7/BMD1-1-4.json",
    "ger_modultitel": "Entwurfsgestalterische Grundlagen und Kollektionsgestaltung III",
    "selecStg": "MD",
    "modulnr": "BMO 1.1.4",
    "lehrende": [
      "Prof. Jo Meurer",
      "Dipl.-Des. (FH) Dorothe Follmann"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD1-1-6.json": {
    "filename": "md/b7/BMD1-1-6.json",
    "ger_modultitel": "Entwurfsgestalterische Grundlagen und Kollektionsgestaltung IV",
    "selecStg": "MD",
    "modulnr": "BMO 1.1.6",
    "lehrende": [
      "Prof. Dirk Wolfes",
      "Dipl.-Des. (FH) Claudia Hannig-Romann"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD2-1-1.json": {
    "filename": "md/b7/BMD2-1-1.json",
    "ger_modultitel": "Grundlagen gestalterischen Arbeitens",
    "selecStg": "MD",
    "modulnr": "BMO 2.1.1",
    "lehrende": [
      "Dipl.-Des. (FH) Elvira Kempf"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD2-2-1.json": {
    "filename": "md/b7/BMD2-2-1.json",
    "ger_modultitel": "Grundlagen gestalterischen Arbeitens",
    "selecStg": "MD",
    "modulnr": "BMO 2.2.1",
    "lehrende": [
      "Prof. Sarah Weisman"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD2-2-3.json": {
    "filename": "md/b7/BMD2-2-3.json",
    "ger_modultitel": "Zeichnen/ künstlerische Techniken",
    "selecStg": "MD",
    "modulnr": "BMO 2.2.3",
    "lehrende": [
      "Prof. Sarah Weisman",
      "Marlies Emmerich"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD2-2-6.json": {
    "filename": "md/b7/BMD2-2-6.json",
    "ger_modultitel": "Zeichnen/ plastischer Ausdruck",
    "selecStg": "MD",
    "modulnr": "BMO 2.2.6",
    "lehrende": [
      "Prof. Sarah Weisman",
      "Marlies Emmerich"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD2-3-1.json": {
    "filename": "md/b7/BMD2-3-1.json",
    "ger_modultitel": "Fachangewandtes Zeichnen I",
    "selecStg": "MD",
    "modulnr": "BMO 2.3.1",
    "lehrende": [
      "Dipl.-Des.(FH) Marlies Emmerich"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD2-3-2.json": {
    "filename": "md/b7/BMD2-3-2.json",
    "ger_modultitel": "Fachangewandtes Zeichnen II",
    "selecStg": "MD",
    "modulnr": "BMO 2.3.2",
    "lehrende": [
      "Dipl.-Des.(FH) Marlies Emmerich",
      "Dipl.-Des. (FH) Regina Casel",
      "Simon Kürten"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD2-3-4.json": {
    "filename": "md/b7/BMD2-3-4.json",
    "ger_modultitel": "Fachangewandtes Zeichnen III",
    "selecStg": "MD",
    "modulnr": "BMO 2.3.4",
    "lehrende": [
      "Dipl.-Des.(FH) Marlies Emmerich"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD3-4-1.json": {
    "filename": "md/b7/BMD3-4-1.json",
    "ger_modultitel": "Bekleidungskonstruktion I",
    "selecStg": "MD",
    "modulnr": "BMO 3.4.1",
    "lehrende": [
      "Rita Reinert",
      "Rita Reinert"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD3-4-2.json": {
    "filename": "md/b7/BMD3-4-2.json",
    "ger_modultitel": "Bekleidungskonstruktion II",
    "selecStg": "MD",
    "modulnr": "BMO 3.4.2",
    "lehrende": [
      "Rita Reinert"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD3-4-3.json": {
    "filename": "md/b7/BMD3-4-3.json",
    "ger_modultitel": "Bekleidungskonstruktion III",
    "selecStg": "MD",
    "modulnr": "BMO 3.4.3",
    "lehrende": [
      "Rita Reienrt",
      "Claudia Hannig-Romann"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD3-4-4.json": {
    "filename": "md/b7/BMD3-4-4.json",
    "ger_modultitel": "Bekleidungskonstruktion IV",
    "selecStg": "MD",
    "modulnr": "BMO 3.4.4",
    "lehrende": [
      "Rita Reinert",
      "Dorothe Follmann"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD3-4-6.json": {
    "filename": "md/b7/BMD3-4-6.json",
    "ger_modultitel": "Bekleidungskonstruktion V",
    "selecStg": "MD",
    "modulnr": "BMO 3.4.6",
    "lehrende": [
      "Rita Reinert",
      "Claudia Hannig-Romann"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD4-10-3.json": {
    "filename": "md/b7/BMD4-10-3.json",
    "ger_modultitel": "Interdisziplinäres Projekt II",
    "selecStg": "MD",
    "modulnr": "BMO 4.10.3",
    "lehrende": [
      "Lehrkräfte FB Gestaltung"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD4-6-1.json": {
    "filename": "md/b7/BMD4-6-1.json",
    "ger_modultitel": "Textiltechnoloegie",
    "selecStg": "MD",
    "modulnr": "BMO 4.6.1",
    "lehrende": [
      "Regina Casel"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD4-8-2.json": {
    "filename": "md/b7/BMD4-8-2.json",
    "ger_modultitel": "Kunst-, Design- und Kulturwissenschaften I",
    "selecStg": "MD",
    "modulnr": "BMO 4.8.2",
    "lehrende": [
      "Prof. Dr. Christina Threuter"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD4-8-4.json": {
    "filename": "md/b7/BMD4-8-4.json",
    "ger_modultitel": "Kunst-, Design- und Kulturwissenschaften II",
    "selecStg": "MD",
    "modulnr": "BMO 4.8.4",
    "lehrende": [
      "Prof. Dr. Christina Threuter"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD4-8-6.json": {
    "filename": "md/b7/BMD4-8-6.json",
    "ger_modultitel": "Kunst-, Design und Kulturwissenschaften/ Modewissenschaft und Theorie",
    "selecStg": "MD",
    "modulnr": "BMO 4.(.&",
    "lehrende": [
      "Prof. Dr. Christina Threuter",
      "Prof. Dr. Susanne Bennewitz"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD4-9-3.json": {
    "filename": "md/b7/BMD4-9-3.json",
    "ger_modultitel": "Interdisziplinäres Projekt I",
    "selecStg": "MD",
    "modulnr": "BMO 4.9.3",
    "lehrende": [
      "Lehrkräfte FB Gestaltung"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD5-10-5.json": {
    "filename": "md/b7/BMD5-10-5.json",
    "ger_modultitel": "Praxissemester",
    "selecStg": "MD",
    "modulnr": "BMO 5.10.5",
    "lehrende": [
      "Prof. Bettina Maiburg",
      "Prof. Jo Meurer",
      "Prof. Dirk Wolfes",
      "Prof. Christian Bruns"
    ],
    "selecStgType": "bachelor"
  },
  "md/b7/BMD6-11-7.json": {
    "filename": "md/b7/BMD6-11-7.json",
    "ger_modultitel": "Research, Entwurf, Konzeption",
    "selecStg": "MD",
    "modulnr": "BMO 6.11.7",
    "lehrende": [
      "Prof. Bettina Maiburg; Prof. Jo Meurer; Prof. Dr. Christina Threuter; Prof. Dirk Wolfes; Prof. Sarah Weisman, Prof. Christian Bruns",
      "Prof. Bettina Maiburg; Prof. Jo Meurer; Prof. Dr. Christina Threuter; Prof. Dirk Wolfes; Prof. Sarah Weisman, Prof. Christian Bruns"
    ],
    "selecStgType": "bachelor"
  }
};