export const formatHtml = (htmlContent) => {
  if (!htmlContent) return ""; // Safeguard in case content is null or undefined

  return htmlContent
    .replace(/<p>\s*<br\s*\/?>\s*<\/p>/g, "\n")        // Handle <p><br></p> with a single line break
    .replace(/<\/p>/g, "\n")                            // Close paragraph tag with single newline
    .replace(/<p[^>]*>/g, "")                           // Remove opening paragraph tag
    .replace(/<br\s*\/?>/g, "\n")                       // Replace <br> with a single newline
    .replace(/<div[^>]*>/g, "")                       // Replace opening <div> with a single newline
    .replace(/<\/div>/g, "")                          // Replace closing </div> with a single newline
    .replace(/<hr\s*\/?>/g, "---\n")                  // Replace <hr> with a separator and newlines
    .replace(/<span[^>]*>/g, "")                        // Remove opening <span> tag
    .replace(/<\/span>/g, "")                           // Remove closing </span> tag
    .replace(/<strong>/g, "")                           // Remove opening <strong> tag
    .replace(/<\/strong>/g, "")                         // Remove closing </strong> tag
    .replace(/\n{3,}/g, "\n\n")                         // Collapse three or more newlines into a double newline
    .trim();                                            // Remove leading and trailing whitespace and newlines
};
