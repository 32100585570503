import React from 'react';
import { Button } from 'react-bootstrap';

const AddRemoveButtons = ({ onAdd, onRemove, addStyle, removeStyle }) => {
  return (
    <div>
      <Button
        className="btn-add"
        variant="primary"
        onClick={onAdd}
        style={addStyle}
      >
        +
      </Button>
      <Button
        className="btn-remove"
        variant="danger"
        onClick={onRemove}
        style={removeStyle}
      >
        -
      </Button>
    </div>
  );
};

export default AddRemoveButtons;
