import React from 'react';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import { FormGroup, FormLabel } from 'react-bootstrap';

const animatedComponents = makeAnimated();

const FormSelectFlex = ({ name, labelText, options, onChange, showLabel = true, isCreatable = true, isMulti = false, additionalClass = '' }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (option) => {
    setFieldValue(name, option);
    if (onChange) {
      onChange(option);
    }
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  const SelectComponent = isCreatable ? Creatable : Select;

  // Define custom styles
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #9e9e9e',  // Set border color
      backgroundColor: '#d3d3d37a', // Set background color
      color: 'black',
      borderRadius: '4px',
      padding: '8px',
      width: '100%',
      marginTop: '0px',
      marginBottom: '0px',
      boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none',
      '&:hover': {
        borderColor: state.isFocused ? 'darkblue' : '#9e9e9e'
      }
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#d3d3d37a',
      color: 'red',
      borderRadius: '4px',
      marginTop: '0px'
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isFocused ? '#bfbfbf' : 'white',
      padding: '8px 20px'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black'
    })
  };

  return (
    <FormGroup className={`formgroup ${meta.touched && meta.error ? 'formgroup-error' : ''}`}>
      {showLabel && <FormLabel htmlFor={name}>{labelText}</FormLabel>}
      <SelectComponent
        className={`form-select ${additionalClass}`}
        components={animatedComponents}
        options={options}
        name={name}
        value={field.value}
        onChange={handleChange}
        onBlur={handleBlur}
        isClearable
        isSearchable
        isMulti={isMulti}
        styles={customStyles}  // Applying the custom styles
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </FormGroup>
  );
};

export default FormSelectFlex;
