import React from 'react';

const ContDescr = ({ IconComponent, children, titleText }) => (
  <div className="cont-descr" style={{marginTop:'10px'}}>
    <p>
      {children}
    </p>
  </div>
);

export default ContDescr;
