import React from 'react';
import CompTempStudList from './comp-temp/comp-temp-studlist';
import CompTempListLehr from './comp-temp/comp-temp-listlehr';
import CompTempPersondata from './comp-temp/comp-temp-persondata';


const TemplateYoutube = ({ values = {} }) => {
    const {
        projektart = {},
        persondata = [],
        assignedLehrende = [],
        semestertype = {},
        jahr = '',
        selecStgType = '',
        selecStg = {},
        ger_project_tags = [],
        eng_project_tags = [],
        ger_descr_projekt_short = '',
        eng_descr_projekt_short = '',
        ger_descr_projekt_long = '',
        eng_descr_projekt_long = ''
    } = values;

    const formatTags = (tags) =>
        tags
            .filter((tag) => tag.value)
            .map((tag) => `#${tag.value.replace(/\s+/g, '')}`)
            .join(' ');

    return (
        <div>
            <p>
                {projektart?.valuefull} von&nbsp;
                <CompTempStudList persondata={persondata} />&nbsp;
                betreut von&nbsp;
                <CompTempListLehr assignedLehrende={assignedLehrende} /> &nbsp;
            </p>
            <p>
                {semestertype?.value} {jahr} / {typeof selecStgType === 'string' ? selecStgType.charAt(0).toUpperCase() + selecStgType.slice(1) : ''} {selecStg?.valuefull}
            </p>
            <hr />
            {formatTags(ger_project_tags)}
            {eng_project_tags?.length > 0 && <>{formatTags(eng_project_tags)}</>}<br></br>
            <hr />
            <div dangerouslySetInnerHTML={{ __html: ger_descr_projekt_short }} />

            {/* {eng_descr_projekt_short && <em>{eng_descr_projekt_short}</em>} */}
            <hr />
            {ger_descr_projekt_long && (
                <>
                    <strong>Beschreibung:</strong><br></br>
                    <div dangerouslySetInnerHTML={{ __html: ger_descr_projekt_long }} />
                </>
            )}
            {eng_descr_projekt_long && <p><strong>Description:</strong> <em>{eng_descr_projekt_long}</em></p>}
            <hr />

            <CompTempPersondata persondata={persondata} />
        </div>
    );
};


export default TemplateYoutube;
